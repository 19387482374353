import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Drawer, notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const EditUserModal = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const userId = props.userId;
  const { showModal, setShowModal } = props;

  useEffect(() => {
    if (userId) {
      fetchUser(`${SERVER_URL}/users/${userId}`, []);
    }
  }, [userId, fetchUser]);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${userId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const createUser = async (data) => {
    try {
      let isError = false;
      if (data.firstName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid first name!',
          placement: 'bottomRight',
        });
      }
      if (data.lastName.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid last name!',
          placement: 'bottomRight',
        });
      }
      if (data.password.includes(' ')) {
        isError = true;
        notification.error({
          message: 'Invalid password!',
          placement: 'bottomRight',
        });
      }
      if (data.city.trim() === '') {
        isError = true;
        notification.error({
          message: 'Invalid city!',
          placement: 'bottomRight',
        });
      }
      // if (data.country.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid country!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.companyName && data.companyName.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid company name!',
      //     placement: 'bottomRight',
      //   });
      // }
      // if (data.taxId && data.taxId.trim() === '') {
      //   isError = true;
      //   notification.error({
      //     message: 'Invalid tax Id!',
      //     placement: 'bottomRight',
      //   });
      // }
      if (isError === false) {
        await Axios.post(`${SERVER_URL}/register`, { ...data });
        notification.success({
          message: t('createdSuccessfully'),
          placement: 'bottomRight',
        });
        setResult(true);
      }
    } catch (err) {
      notification.error({
        message:
          err.response.data && err.response.data.message
            ? err.response.data.message
            : t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'center', height: '100%' }}>
        {userId && user.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {userId && !user.isLoading && !user.isError && user.data && user.data.email && (
          <Drawer title={t('editUser')} placement="right" onClose={onClose} visible={props.showModal}>
            <UserForm
              data={user.data}
              updateHandler={updateUser}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
              profile={props.profile}
            />
          </Drawer>
        )}
        {userId && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!userId && user && !user.data && (
          <Drawer title={t('newUser')} placement="right" onClose={onClose} visible={props.showModal}>
            <UserForm
              data={null}
              createHandler={createUser}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(EditUserModal);
