import React, { useEffect, useState, useContext } from 'react';
import {
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Button,
  Upload,
  notification
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import i18n from '../../i18n';

const FloorSystemForm = ({
  data,
  files,
  form,
  setFiles,
  t,
  shelfQty,
  setShelfQty,
  numStairs,
  setNumStairs
}) => {
  const currentuser = useContext(UserContext);
  const [oversailingFloor, setOversailingFloor] = useState(data && data.oversailingFloor);
  const [topEdgeAisleFlooringL1, setTopEdgeAisleFlooringL1] = useState(data && data.levelOne);
  const [topEdgeAisleFlooringL2, setTopEdgeAisleFlooringL2] = useState(data && data.levelTwo);
  const [topEdgeAisleFlooringL3, setTopEdgeAisleFlooringL3] = useState(data && data.levelThree);
  const [outerFrame, setOuterFrame] = useState(data && data.outerFrame);
  const [innerFrame, setInnerFrame] = useState(data && data.innerFrame);
  const [base, setBase] = useState(data && data.base);
  const [rearPanel, setRearPanel] = useState(data && data.rearPanel);
  const [steelConstruction, setSteelConstruction] = useState(data && data.construction);
  const [floorLevel, setFloorLevel] = useState(data && data.floor);
  const [levelOne, setLevelOne] = useState(data && data.floorOne);
  const [levelTwo, setLevelTwo] = useState(data && data.floorTwo);
  const [levelThree, setLevelThree] = useState(data && data.floorThree);
  const [levelFour, setLevelFour] = useState(data && data.floorFour);
  const [levelFive, setLevelFive] = useState(data && data.floorFive);
  const [levelSix, setLevelSix] = useState(data && data.floorSix);
  const [levelSeven, setLevelSeven] = useState(data && data.floorSeven);
  const [levelEight, setLevelEight] = useState(data && data.floorEight);
  const [levelNine, setLevelNine] = useState(data && data.floorNine);
  const [meshSizeFloor, setMeshSizeFloor] = useState(data && data.meshSize);
  const [palletTransferFloor, setPalletTransferFloor] = useState(data && data.palletTransferFloor);
  const [frameHeight, setFrameHeight] = useState(data && data.frameHeight);
  const [shelfWidth, setShelfWidth] = useState(data && data.shelfWidth);
  const [compartment, setCompartment] = useState(data && data.compartment);
  const [compartmentDouble, setCompartmentDouble] = useState(data && data.compartmentDouble);
  const [reinforcement, setReinforcement] = useState(data && data.reinforcement);
  const [aisleFlooring, setAisleFlooring] = useState(data && data.aisleFlooring);
  const [palletTransfer, setPalletTransfer] = useState(data && data.palletTransfer);
  const [multiTiersLayout, setMultiTiersLayout] = useState(data && data.floorSystemLayout);

  const [fileList, setFileList] = useState([]);

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  const [numStaircases, setNumStaircases] = useState({
    stepWidthFloorOne: data && data.stepWidthFloorOne ? data.stepWidthFloorOne : '',
    stepWidthFloorTwo: data && data.stepWidthFloorTwo ? data.stepWidthFloorTwo : '',
    stepWidthFloorThree: data && data.stepWidthFloorThree ? data.stepWidthFloorThree : '',
    stepWidthFloorFour: data && data.stepWidthFloorFour ? data.stepWidthFloorFour : '',
    stepWidthFloorFive: data && data.stepWidthFloorFive ? data.stepWidthFloorFive : '',
    stepWidthFloorSix: data && data.stepWidthFloorSix ? data.stepWidthFloorSix : '',
    stepWidthFloorSeven: data && data.stepWidthFloorSeven ? data.stepWidthFloorSeven : '',
    stepWidthFloorEight: data && data.stepWidthFloorEight ? data.stepWidthFloorEight : '',
    stepWidthFloorNine: data && data.stepWidthFloorNine ? data.stepWidthFloorNine : '',
    stepWidthFloorTen: data && data.stepWidthFloorTen ? data.stepWidthFloorTen : ''
  });

  if (data?.pbbFiles && fileList.length === 0) {
    data.pbbFiles.forEach((file, index) => {
      if (file.sketchType && file.sketchType === 'floorSystemFile') {
        setFileList((prevState) => [
          ...prevState,
          {
            uid: file._id.toString(),
            name: file.originalName,
            status: 'done',
            // thumbUrl,
            response: {
              key: file.key
            },
            fileId: file._id
          }
        ]);
      }
    });
  }

  const applyToAll = () => {
    const customFloorLevel = form.getFieldValue('customFloor');
    setLevelOne(floorLevel);
    setLevelTwo(floorLevel);
    setLevelThree(floorLevel);
    setLevelFour(floorLevel);
    setLevelFive(floorLevel);
    setLevelSix(floorLevel);
    setLevelSeven(floorLevel);
    setLevelEight(floorLevel);
    setLevelNine(floorLevel);

    form.setFieldsValue({
      ...form.getFieldsValue(),
      floorOne: floorLevel,
      customFloorOne: customFloorLevel,
      floorTwo: floorLevel,
      customFloorTwo: customFloorLevel,
      floorThree: floorLevel,
      customFloorThree: customFloorLevel,
      floorFour: floorLevel,
      customFloorFour: customFloorLevel,
      floorFive: floorLevel,
      customFloorFive: customFloorLevel,
      floorSix: floorLevel,
      customFloorSix: customFloorLevel,
      floorSeven: floorLevel,
      customFloorSeven: customFloorLevel,
      floorEight: floorLevel,
      customFloorEight: customFloorLevel,
      floorNine: floorLevel,
      customFloorNine: customFloorLevel
    });
  };

  useEffect(() => {
    if (data && data.shelfQty) {
      setShelfQty(data.shelfQty);
    }
    if (data && data.numStairs) {
      setNumStairs(data.numStairs);
    }
  }, []);

  const shelfQuantityPlaceholder = [
    t('floorLevel'),
    t('level1'),
    t('level2'),
    t('level3'),
    t('level4'),
    t('level5'),
    t('level6'),
    t('level7'),
    t('level8'),
    t('level9')
  ];

  const increaseShelfQty = () => {
    setShelfQty((prevState) => prevState + 1);
  };
  const decreaseShelfQty = () => {
    setShelfQty((prevState) => prevState - 1);
  };

  const increaseStaircases = () => {
    setNumStairs((prevState) => prevState + 1);
  };

  const shelfLoad = [];
  const shelfFields = [
    { name: '4', value: levelFour, toCall: setLevelFour },
    { name: '5', value: levelFive, toCall: setLevelFive },
    { name: '6', value: levelSix, toCall: setLevelSix },
    { name: '7', value: levelSeven, toCall: setLevelSeven },
    { name: '8', value: levelEight, toCall: setLevelEight },
    { name: '9', value: levelNine, toCall: setLevelNine }
  ];

  for (let i = 0; i < shelfQty; i++) {
    shelfLoad.push(
      <Col xs={24} md={6}>
        <Card
          style={{ marginBottom: '10px' }}
          className='pbbCard'
          title={t(`level${shelfFields[i].name}`)}>
          <Form.Item
            name={`floor${shelfFields[i].name}`}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox.Group
              defaultValue={shelfFields[i].value}
              onChange={(e) => shelfFields[i].toCall(e)}>
              <Space direction='vertical'>
                {/* <Checkbox value='80kg' className='pbbCheckbox'>
                  80 kg
                </Checkbox> */}
                <Checkbox value='100kg' className='pbbCheckbox'>
                  100 kg
                </Checkbox>
                <Checkbox value='150kg' className='pbbCheckbox'>
                  150 kg
                </Checkbox>
                <Checkbox value='200kg' className='pbbCheckbox'>
                  200 kg
                </Checkbox>
                <Checkbox value='230kg' className='pbbCheckbox'>
                  230 kg
                </Checkbox>
                <Checkbox value='330kg' className='pbbCheckbox'>
                  330 kg
                </Checkbox>
                <Checkbox value='custom' className='pbbCheckbox'>
                  Other
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </Form.Item>
          {shelfFields[i].value?.includes('custom') && (
            <Form.Item
              name={`customFloor${shelfFields[i].name}`}
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' />
            </Form.Item>
          )}
          <Button style={{ marginTop: '10px' }} onClick={decreaseShelfQty}>
            {t('deleteBtn')}
          </Button>
        </Card>
      </Col>
    );
  }

  const stairFields = [
    {
      name: 'Two',
      floor: numStaircases.stepWidthFloorTwo,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorTwo: e.target.value })
    },
    {
      name: 'Three',
      floor: numStaircases.stepWidthFloorThree,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorThree: e.target.value })
    },
    {
      name: 'Four',
      floor: numStaircases.stepWidthFloorFour,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorFour: e.target.value })
    },
    {
      name: 'Five',
      floor: numStaircases.stepWidthFloorFive,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorFive: e.target.value })
    },
    {
      name: 'Six',
      floor: numStaircases.stepWidthFloorSix,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorSix: e.target.value })
    },
    {
      name: 'Seven',
      floor: numStaircases.stepWidthFloorSeven,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorSeven: e.target.value })
    },
    {
      name: 'Eight',
      floor: numStaircases.stepWidthFloorEight,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorEight: e.target.value })
    },
    {
      name: 'Nine',
      floor: numStaircases.stepWidthFloorNine,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorNine: e.target.value })
    },
    {
      name: 'Ten',
      floor: numStaircases.stepWidthFloorTen,
      toCall: (e) => setNumStaircases({ ...numStaircases, stepWidthFloorTen: e.target.value })
    }
  ];

  const stairs = [];
  for (let i = 0; i < numStairs; i++) {
    stairs.push(
      <>
        <div style={{ marginBottom: '10px', textAlign: 'left', fontWeight: '700' }}>
          {t('metaMultiTiers_numberOfStaircases')}
        </div>
        <Row gutter={16}>
          <Col xs={24} md={6}>
            <Form.Item
              style={{ marginBottom: '16px' }}
              name={`numStairs${stairFields[i].name}`}
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' suffix={t('pieces')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={6}>
            <Card className='pbbCard' title={t('stepWidth')}>
              <Form.Item
                name={`stepWidthFloor${stairFields[i].name}`}
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group onChange={stairFields[i].toCall}>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='800 mm'>
                      800 mm
                    </Radio>
                    <Radio className='pbbRadioOption' value='1,000 mm'>
                      {i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}
                    </Radio>
                    <Radio className='pbbRadioOption' value='Custom width'>
                      {t('other')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {stairFields[i].floor === 'Custom width' && (
                <Form.Item
                  style={{ marginTop: '10px' }}
                  name={`otherStepWidth${stairFields[i].name}`}
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Input type='number' suffix='mm' />
                </Form.Item>
              )}
            </Card>
          </Col>
        </Row>
        <div style={{ marginBottom: '10px', textAlign: 'left', fontWeight: '700' }}>
          {t('metaMultiTiers_railingHeight')}
        </div>
        <Row gutter={16}>
          <Col xs={24} md={6}>
            <Form.Item
              style={{ marginBottom: '24px' }}
              name={`metaMultiTiersRailingHeight${stairFields[i].name}`}>
              <Input type='number' suffix='m' />
            </Form.Item>
            <Divider
              className='pbbReportDivider'
              style={{ minWidth: 'unset', marginTop: '15px' }}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div>
      <h3 id='meta-multi-tiers'>{t('metaMultiTiers')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='floorSystemLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoFloorSystem'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_surface')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_outerFrame')}>
            <Form.Item
              name='outerFrame'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setOuterFrame(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('metaMultiTiers_galvanized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('metaMultiTiers_RAL7035LightGrey')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('metaMultiTiers_RAL5010GentianBlue')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {outerFrame === 'Special color' && (
                    <Form.Item
                      name='specialColorOuter'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_innerFrame')}>
            <Form.Item
              name='innerFrame'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setInnerFrame(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('metaMultiTiers_galvanized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('metaMultiTiers_RAL7035LightGrey')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('metaMultiTiers_RAL5010GentianBlue')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {innerFrame === 'Special color' && (
                    <Form.Item
                      name='specialColorInner'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_bases')}>
            <Form.Item
              name='base'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setBase(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('metaMultiTiers_galvanized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('metaMultiTiers_RAL7035LightGrey')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('metaMultiTiers_RAL5010GentianBlue')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {base === 'Special color' && (
                    <Form.Item
                      name='specialColorBase'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_rearPanels')}>
            <Form.Item
              name='rearPanel'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setRearPanel(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('metaMultiTiers_galvanized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('metaMultiTiers_RAL7035LightGrey')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('metaMultiTiers_RAL5010GentianBlue')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {rearPanel === 'Special color' && (
                    <Form.Item
                      name='specialColorRear'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_steelConstructions')}>
            <Form.Item
              name='steelConstruction'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setSteelConstruction(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('metaMultiTiers_RAL7035LightGrey')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('metaMultiTiers_RAL5010GentianBlue')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {steelConstruction === 'Special color' && (
                    <Form.Item
                      name='specialColorConstruction'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_frameHeight')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='frameHeight'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox.Group defaultValue={frameHeight} onChange={(e) => setFrameHeight(e)}>
            <Space direction='horizontal'>
              <Checkbox value='4,500 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '4.500 mm' : '4,500 mm'}
              </Checkbox>
              <Checkbox value='5,000 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '5.000 mm' : '5,000 mm'}
              </Checkbox>
              <Checkbox value='custom' className='pbbCheckbox'>
                {t('specialHeight')}
              </Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        {frameHeight?.includes('custom') && (
          <Form.Item
            name='customFrameHeight'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input type='number' suffix='mm' />
          </Form.Item>
        )}
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_topEdgeAisleFlooring')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>

      <Row gutter={8} className='aisle-flooring-levels'>
        <Col xs={3} md={3}>
          <Form.Item
            name='levelOne'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('ogLevel1')}:`}>
            <Radio.Group onChange={(event) => setTopEdgeAisleFlooringL1(event.target.value)}>
              <Space direction='vertical'>
                <Radio className='pbbRadioOption' value='2344'>
                  2344 mm
                </Radio>
                <Radio className='pbbRadioOption' value='2544'>
                  2544 mm
                </Radio>
                <Radio className='pbbRadioOption' value='specialL1'>
                  {t('specialLevel')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {topEdgeAisleFlooringL1 === 'specialL1' && (
            <Form.Item
              style={{ marginTop: '10px' }}
              name='specialLevel1'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' placeholder={t('special')} suffix='mm' />
            </Form.Item>
          )}
        </Col>
        <Col xs={3} md={3}>
          <Form.Item name='levelTwo' label={`${t('ogLevel2')}:`}>
            <Radio.Group onChange={(event) => setTopEdgeAisleFlooringL2(event.target.value)}>
              <Space direction='vertical'>
                <Radio className='pbbRadioOption' value='2344'>
                  2344 mm
                </Radio>
                <Radio className='pbbRadioOption' value='2544'>
                  2544 mm
                </Radio>
                <Radio className='pbbRadioOption' value='specialL2'>
                  {t('specialLevel')}
                </Radio>
                <Radio className='pbbRadioOption' value='none'>
                  {t('none')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {topEdgeAisleFlooringL2 === 'specialL2' && (
            <Form.Item
              style={{ marginTop: '10px' }}
              name='specialLevel2'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' placeholder={t('special')} suffix='mm' />
            </Form.Item>
          )}
        </Col>
        <Col className='special-three text-center' xs={3} md={3}>
          <Form.Item name='levelThree' label={`${t('ogLevel3')}:`}>
            <Radio.Group onChange={(event) => setTopEdgeAisleFlooringL3(event.target.value)}>
              <Radio className='pbbRadioOption ' value='specialL3'>
                {t('specialLevel')}
              </Radio>
              <Radio className='pbbRadioOption' value='none'>
                {t('none')}
              </Radio>
            </Radio.Group>
          </Form.Item>
          {topEdgeAisleFlooringL3 === 'specialL3' && (
            <Form.Item
              style={{ marginTop: '10px' }}
              name='specialLevel3'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' placeholder={t('special')} suffix='mm' />
            </Form.Item>
          )}
        </Col>
        <Col xs={6} md={6} className='generalInformationField'>
          <Form.Item name='oversailingFloor' valuePropName='checked'>
            <Checkbox
              className='pbbCheckbox'
              onChange={(event) => setOversailingFloor(event.target.checked)}>
              {t('metaMultiTiers_oversailingFloor')}
            </Checkbox>
          </Form.Item>
          {oversailingFloor && (
            <Form.Item name='oversailingFloorValue'>
              <Input
                type='number'
                placeholder={`${t('metaMultiTiers_oversailingFloor')}`}
                suffix='mm'
              />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} md={8} className='generalInformationField'>
          <Form.Item name='layoutRackFrame' valuePropName='checked'>
            <Checkbox className='pbbCheckbox'>{t('metaMultiTiers_rackFrame')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_shelfWidth')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='shelfWidth'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox.Group defaultValue={shelfWidth} onChange={(e) => setShelfWidth(e)}>
            <Space direction='horizontal'>
              <Checkbox value='750 mm' className='pbbCheckbox'>
                750 mm
              </Checkbox>
              <Checkbox value='1,000 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}
              </Checkbox>
              <Checkbox value='1,250 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '1.250 mm' : '1,250 mm'}
              </Checkbox>
              <Checkbox value='1,300 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '1.300 mm' : '1,300 mm'}
              </Checkbox>
              <Checkbox value='1,500 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '1.500 mm' : '1,500 mm'}
              </Checkbox>
              <Checkbox value='1,700 mm' className='pbbCheckbox'>
                {i18n.language === 'German' ? '1.700 mm' : '1,700 mm'}
              </Checkbox>
              <Checkbox value='custom' className='pbbCheckbox'>
                {t('otherWidth')}
              </Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        {shelfWidth?.includes('custom') && (
          <Form.Item
            name='customShelftWidth'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input type='number' suffix='mm' />
          </Form.Item>
        )}
      </Row>

      {multiTiersLayout === 'singleSided' && (
        <div>
          <div className='sectionTitle'>
            <h4 className='pbb-section-title'>{t('metaMultiTiers_compartmentDepthSingleRow')}:</h4>
            <Divider
              className='pbbReportDivider'
              style={{ minWidth: 'unset', marginTop: '15px' }}
            />
          </div>
          <Row gutter={16}>
            <Form.Item
              name='compartment'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group defaultValue={compartment} onChange={(e) => setCompartment(e)}>
                <Space direction='horizontal'>
                  <Checkbox value='300 mm' className='pbbCheckbox'>
                    300 mm
                  </Checkbox>
                  <Checkbox value='400 mm' className='pbbCheckbox'>
                    400 mm
                  </Checkbox>
                  <Checkbox value='500 mm' className='pbbCheckbox'>
                    500 mm
                  </Checkbox>
                  <Checkbox value='600 mm' className='pbbCheckbox'>
                    600 mm
                  </Checkbox>
                  <Checkbox value='800 mm' className='pbbCheckbox'>
                    800 mm
                  </Checkbox>
                  <Checkbox value='custom' className='pbbCheckbox'>
                    {t('otherWidth')}
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            {compartment?.includes('custom') && (
              <Form.Item
                name='customCompartment'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' suffix='mm' />
              </Form.Item>
            )}
          </Row>
        </div>
      )}
      {multiTiersLayout === 'doubleSided' && (
        <div>
          <div className='sectionTitle'>
            <h4 className='pbb-section-title'>{t('metaMultiTiers_compartmentDepthDoubleRow')}:</h4>
            <Divider
              className='pbbReportDivider'
              style={{ minWidth: 'unset', marginTop: '15px' }}
            />
          </div>
          <Row gutter={16}>
            <Form.Item
              name='compartmentDouble'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group
                defaultValue={compartmentDouble}
                onChange={(e) => setCompartmentDouble(e)}>
                <Space direction='horizontal'>
                  <Checkbox value='2 x 300 mm' className='pbbCheckbox'>
                    2 x 300 mm
                  </Checkbox>
                  <Checkbox value='2 x 400 mm' className='pbbCheckbox'>
                    2 x 400 mm
                  </Checkbox>
                  <Checkbox value='2 x 500 mm' className='pbbCheckbox'>
                    2 x 500 mm
                  </Checkbox>
                  <Checkbox value='2 x 600 mm' className='pbbCheckbox'>
                    2 x 600 mm
                  </Checkbox>
                  <Checkbox value='2 x 800 mm' className='pbbCheckbox'>
                    2 x 800 mm
                  </Checkbox>
                  <Checkbox value='custom' className='pbbCheckbox'>
                    {t('otherWidth')}
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            {compartmentDouble?.includes('custom') && (
              <Form.Item
                name='customCompartmentDouble'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' suffix='mm' />
              </Form.Item>
            )}
          </Row>
        </div>
      )}
      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_shelfQuantity')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Form.List
            name='shelfQuantity'
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('At least 1 input'));
                  }
                }
              }
            ]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key}>
                    <Row gutter={16}>
                      <Col xs={24} md={4}>
                        <span>{shelfQuantityPlaceholder[index]}</span>
                      </Col>
                      <Col xs={24} md={6}>
                        <Form.Item
                          {...field}
                          noStyle
                          rules={[
                            {
                              required: true,
                              message: t('validationAlert')
                            }
                          ]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Form.Item>
                  {fields.length < 10 && (
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      style={{
                        width: '60%'
                      }}
                      icon={<PlusOutlined />}>
                      {t('addField')}
                    </Button>
                  )}
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_shelfLoadCapacity')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card style={{ marginBottom: '10px' }} className='pbbCard' title={t('floorLevel')}>
            <Form.Item
              name='floor'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group defaultValue={floorLevel} onChange={(e) => setFloorLevel(e)}>
                <Space direction='vertical'>
                  <Checkbox value='100kg' className='pbbCheckbox'>
                    100 kg
                  </Checkbox>
                  <Checkbox value='150kg' className='pbbCheckbox'>
                    150 kg
                  </Checkbox>
                  <Checkbox value='200kg' className='pbbCheckbox'>
                    200 kg
                  </Checkbox>
                  <Checkbox value='230kg' className='pbbCheckbox'>
                    230 kg
                  </Checkbox>
                  <Checkbox value='330kg' className='pbbCheckbox'>
                    330 kg
                  </Checkbox>
                  <Checkbox value='custom' className='pbbCheckbox'>
                    {t('other')}
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            {floorLevel?.includes('custom') && (
              <Form.Item
                name='customFloor'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' />
              </Form.Item>
            )}
            <Button
              block
              type='primary'
              style={{ width: '100%', marginTop: '.5rem', whiteSpace: 'normal' }}
              onClick={applyToAll}>
              {t('applyToAllLevels')}
            </Button>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card style={{ marginBottom: '10px' }} className='pbbCard' title={t('level1')}>
            <Form.Item
              name='floorOne'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group defaultValue={levelOne} onChange={(e) => setLevelOne(e)}>
                <Space direction='vertical'>
                  <Checkbox value='100kg' className='pbbCheckbox'>
                    100 kg
                  </Checkbox>
                  <Checkbox value='150kg' className='pbbCheckbox'>
                    150 kg
                  </Checkbox>
                  <Checkbox value='200kg' className='pbbCheckbox'>
                    200 kg
                  </Checkbox>
                  <Checkbox value='230kg' className='pbbCheckbox'>
                    230 kg
                  </Checkbox>
                  <Checkbox value='330kg' className='pbbCheckbox'>
                    330 kg
                  </Checkbox>
                  <Checkbox value='custom' className='pbbCheckbox'>
                    {t('other')}
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            {levelOne?.includes('custom') && (
              <Form.Item
                name='customFloorOne'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' />
              </Form.Item>
            )}
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card style={{ marginBottom: '10px' }} className='pbbCard' title={t('level2')}>
            <Form.Item
              name='floorTwo'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group defaultValue={levelTwo} onChange={(e) => setLevelTwo(e)}>
                <Space direction='vertical'>
                  <Checkbox value='100kg' className='pbbCheckbox'>
                    100 kg
                  </Checkbox>
                  <Checkbox value='150kg' className='pbbCheckbox'>
                    150 kg
                  </Checkbox>
                  <Checkbox value='200kg' className='pbbCheckbox'>
                    200 kg
                  </Checkbox>
                  <Checkbox value='230kg' className='pbbCheckbox'>
                    230 kg
                  </Checkbox>
                  <Checkbox value='330kg' className='pbbCheckbox'>
                    330 kg
                  </Checkbox>
                  <Checkbox value='custom' className='pbbCheckbox'>
                    {t('other')}
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            {levelTwo?.includes('custom') && (
              <Form.Item
                name='customFloorTwo'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' />
              </Form.Item>
            )}
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card style={{ marginBottom: '10px' }} className='pbbCard' title={t('level3')}>
            <Form.Item
              name='floorThree'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group defaultValue={levelThree} onChange={(e) => setLevelThree(e)}>
                <Space direction='vertical'>
                  <Checkbox value='100kg' className='pbbCheckbox'>
                    100 kg
                  </Checkbox>
                  <Checkbox value='150kg' className='pbbCheckbox'>
                    150 kg
                  </Checkbox>
                  <Checkbox value='200kg' className='pbbCheckbox'>
                    200 kg
                  </Checkbox>
                  <Checkbox value='230kg' className='pbbCheckbox'>
                    230 kg
                  </Checkbox>
                  <Checkbox value='330kg' className='pbbCheckbox'>
                    330 kg
                  </Checkbox>
                  <Checkbox value='custom' className='pbbCheckbox'>
                    {t('other')}
                  </Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
            {levelThree?.includes('custom') && (
              <Form.Item
                name='customFloorThree'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' />
              </Form.Item>
            )}
          </Card>
        </Col>
        {shelfLoad.map((shelf) => shelf)}
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={24}>
          {shelfQty < 6 && (
            <Row gutter={16}>
              <Col xs={24} md={3}>
                <Button type='primary' style={{ marginBottom: '20px' }} onClick={increaseShelfQty}>
                  {t('addMoreBtn')}
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        {/* <Col xs={24} md={6}>
          <Card className='pbbCard' title={t('metaMultiTiers_reinforcement')}>
            <Form.Item
              name='reinforcementFloor'
              rules={[
                {
                  required: true,
                  message: t('validationAlert'),
                },
              ]}
            >
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='X-Bracing'>
                    {t('metaMultiTiers_xBracing')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Back cladding'>
                    {t('metaMultiTiers_backCladding')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Longitudinal crossbar'>
                    {t('metaMultiTiers_longitudinalCrossbar')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col> */}
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_reinforcement')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Form.Item
        name='reinforcement'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox.Group defaultValue={reinforcement} onChange={(e) => setReinforcement(e)}>
          <Space direction='vertical'>
            {/* <Checkbox value='diagonalStiffener' className='pbbCheckbox'>
              {t('metaMultiTiers_diagonalStiffener')}
            </Checkbox>
            <Checkbox value='rearPanels' className='pbbCheckbox'>
              {t('rearPanels')}
            </Checkbox>
            <Checkbox value='crossbar' className='pbbCheckbox'>
              {t('metaMultiTiers_longitudinalCrossbar')}
            </Checkbox> */}
            <Checkbox value='xBracing' className='pbbCheckbox'>
              {t('metaMultiTiers_xBracing')}
            </Checkbox>
            <Checkbox value='backCladding' className='pbbCheckbox'>
              {t('metaMultiTiers_backCladding')}
            </Checkbox>
            <Checkbox value='bracingBeams' className='pbbCheckbox'>
              {t('metaMultiTiers_bracingBeams')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_aisleFlooring')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Form.Item
        name='aisleFlooring'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox.Group defaultValue={aisleFlooring} onChange={(e) => setAisleFlooring(e)}>
          <Space direction='horizontal'>
            <Checkbox value='chipboard' className='pbbCheckbox'>
              {t('metaMultiTiers_chipboard')}
            </Checkbox>
            <Checkbox value='grating' className='pbbCheckbox'>
              {t('metaMultiTiers_grating')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
      {aisleFlooring?.includes('chipboard') && (
        <Row gutter={16}>
          <Col xs={24} md={6}>
            <Card className='pbbCard' title={t('metaMultiTiers_topSide')}>
              <Form.Item
                name='topSideFloor'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='Raw'>
                      {t('metaMultiTiers_raw')}
                    </Radio>
                    <Radio className='pbbRadioOption' value='Anti-slip'>
                      Anti-slip
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card className='pbbCard' title={t('metaMultiTiers_bottomSide')}>
              <Form.Item
                name='bottomSideFloor'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='Raw'>
                      {t('metaMultiTiers_raw')}
                    </Radio>
                    <Radio className='pbbRadioOption' value='White'>
                      {t('metaMultiTiers_white')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card className='pbbCard' title={`${t('metaMultiTiers_fireProtection')}:`}>
              <Form.Item
                name='fireProtection'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group>
                  <Space direction='horizontal'>
                    <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                      {t('yes')}
                    </Radio>
                    <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                      {t('no')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      )}
      {aisleFlooring?.includes('grating') && (
        <Row gutter={16}>
          <Col xs={24} md={6}>
            <Card className='pbbCard' title={`${t('metaMultiTiers_meshSize')}`}>
              <Form.Item
                name='meshSize'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group onChange={(event) => setMeshSizeFloor(event.target.value)}>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='30/30'>
                      30/30
                    </Radio>
                    <Radio className='pbbRadioOption' value='30/10'>
                      30/10
                    </Radio>
                    <Radio className='pbbRadioOption' value='other'>
                      Other
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {meshSizeFloor === 'other' && (
                <Form.Item
                  style={{ marginTop: '10px' }}
                  name='otherMeshSize'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Input placeholder='Other:' />
                </Form.Item>
              )}
            </Card>
          </Col>
        </Row>
      )}

      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={12} md={6}></Col>
        <Col xs={6} md={6}>
          {t('loadCapacity')}
        </Col>
        <Col xs={6} md={6}>
          {t('width')}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12} md={6}>
          {t('mainAisle')}
        </Col>
        <Col xs={6} md={6}>
          <Form.Item
            name='mainAisleCapacity'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input
              type='number'
              suffix={
                <span>
                  kg/m<sup>2</sup>
                </span>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={6} md={6}>
          <Form.Item
            name='mainAisleMainWidth'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input type='number' suffix='mm' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12} md={6}>
          {t('secondaryAisle')}
        </Col>
        <Col xs={6} md={6}>
          <Form.Item
            name='secondaryAisleCapacity'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input
              type='number'
              suffix={
                <span>
                  kg/m<sup>2</sup>
                </span>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={6} md={6}>
          <Form.Item
            name='secondaryAisleMainWidth'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input type='number' suffix='mm' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12} md={6}>
          {t('palletGate')}
        </Col>
        <Col xs={6} md={6}>
          <Form.Item
            name='palletGateCapacity'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input
              type='number'
              suffix={
                <span>
                  kg/m<sup>2</sup>
                </span>
              }
            />
          </Form.Item>
        </Col>
        <Col xs={6} md={6}>
          <Form.Item
            name='palletGateMainWidth'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input type='number' suffix='mm' />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_palletTransfer')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card className='pbbCard' title={`${t('metaMultiTiers_palletTransfer')}`}>
            <Form.Item
              name='palletTransferFloor'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setPalletTransferFloor(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value={'Pallet transfer with chain'}>
                    {t('metaMultiTiers_palletTransferWithChain')}
                  </Radio>
                  <Radio className='pbbRadioOption' value={'Swivel lock'}>
                    {t('metaMultiTiers_swivelLock')}
                  </Radio>
                  <Radio className='pbbRadioOption' value={'Folding door'}>
                    {t('metaMultiTiers_foldingDoor')}
                  </Radio>
                  <Radio className='pbbRadioOption' value={'None'}>
                    {t('none')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {palletTransferFloor && palletTransferFloor !== 'None' && (
              <Form.Item
                style={{ marginTop: '10px' }}
                name='palletTransferFloorValue'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' suffix={t('pieces')} placeholder={`${palletTransferFloor}:`} />
              </Form.Item>
            )}
          </Card>
        </Col>
        <Form.Item
          name='palletTransfer'
          // rules={[
          //   {
          //     required: true,
          //     message: t('validationAlert'),
          //   },
          // ]}
        >
          <Checkbox.Group defaultValue={palletTransfer} onChange={(e) => setPalletTransfer(e)}>
            <Checkbox value='Lighting' className='pbbCheckbox' style={{ marginLeft: '8px' }}>
              {t('lighting')}
            </Checkbox>
            <Checkbox value='Lift truck' className='pbbCheckbox'>
              {t('metaMultiTiers_liftTruck')}
            </Checkbox>
            <Checkbox value='Sprinkler' className='pbbCheckbox'>
              {t('metaMultiTiers_sprinkler')}
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Row>
      <div style={{ marginBottom: '10px', textAlign: 'left', fontWeight: '700' }}>
        {t('metaMultiTiers_numberOfStaircases')}
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            style={{ marginBottom: '16px' }}
            name='numStairsOne'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input type='number' suffix={t('pieces')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card className='pbbCard' title={`${t('metaMultiTiers_stepWidth')}`}>
            <Form.Item
              name='stepWidthFloorOne'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group
                onChange={(e) =>
                  setNumStaircases({ ...numStaircases, stepWidthFloorOne: e.target.value })
                }>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='800 mm'>
                    800 mm
                  </Radio>
                  <Radio className='pbbRadioOption' value='1,000 mm'>
                    {i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Custom width'>
                    {t('other')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {numStaircases.stepWidthFloorOne === 'Custom width' && (
              <Form.Item
                name='otherStepWidthOne'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' suffix='mm' />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>
      <div style={{ marginBottom: '10px', textAlign: 'left', fontWeight: '700' }}>
        {t('metaMultiTiers_railingHeight')}
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item style={{ marginBottom: '24px' }} name='metaMultiTiersRailingHeightOne'>
            <Input type='number' suffix='m' />
          </Form.Item>
          <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
        </Col>
      </Row>
      {stairs.map((stair) => stair)}
      {numStairs < 9 && (
        <Row gutter={16}>
          <Col xs={24} md={3}>
            <Button type='primary' style={{ marginBottom: '20px' }} onClick={increaseStaircases}>
              {t('addMoreBtn')}
            </Button>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_platform')}>
            <Form.Item
              name='platform'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultiTiers_emergencyDescent')}>
            <Form.Item
              name='emergencyDescent'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card
            style={{ marginBottom: '10px' }}
            className='pbbCard'
            title={t('metaMultiTiers_singleRowWireBackPanels')}>
            <Form.Item
              name='singleRowWire'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Floor level'>
                    {t('metaMultiTiers_floorLevel')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='All'>
                    {t('metaMultiTiers_all')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='From 1st floor'>
                    {t('metaMultiTiers_from1stFloor')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='No'>
                    {t('metaMultiTiers_None')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card
            style={{ marginBottom: '10px' }}
            className='pbbCard'
            title={`${t('metaMultiTiers_singleRowMetalRearPanel')}`}>
            <Form.Item
              name='singleRowMetal'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Floor level'>
                    {t('metaMultiTiers_floorLevel')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='All'>
                    {t('metaMultiTiers_all')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='From 1st floor'>
                    {t('metaMultiTiers_from1stFloor')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='No'>
                    {t('metaMultiTiers_None')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card
            style={{ marginBottom: '10px' }}
            className='pbbCard'
            title={`${t('metaMultiTiers_doubleRowMetalRearPanel')}`}>
            <Form.Item
              name='doubleRowMetal'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Floor level'>
                    {t('metaMultiTiers_floorLevel')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='All'>
                    {t('metaMultiTiers_all')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='No'>
                    {t('metaMultiTiers_None')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={6}>
          <Card
            style={{ marginBottom: '10px' }}
            className='pbbCard'
            title={`${t('metaMultiTiers_fullRearPanelFrame')}`}>
            <Form.Item
              name='fullRearPanel'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='End sides'>
                    {t('metaMultiTiers_endSides')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Aisle sides'>
                    {t('metaMultiTiers_aisleSides')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='All'>
                    {t('metaMultiTiers_all')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='No'>
                    {t('metaMultiTiers_None')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultiTiers_documents')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={5} md={5}>
          <Card className='pbbCard' title={t('metaMultiTiers_CADDrawing')}>
            <Form.Item name='cadDrawing'>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={5} md={5}>
          <Card className='pbbCard' title={t('metaMultiTiers_PDFDrawing')}>
            <Form.Item name='pdfDrawing'>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={5} md={5}>
          <Card className='pbbCard' title={t('metaMultiTiers_sketch')}>
            <Form.Item name='sketch'>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={5} md={5}>
          <Card className='pbbCard' title={t('metaMultiTiers_pictures')}>
            <Form.Item name='pictures'>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={4} md={4}>
          <Card className='pbbCard' title={t('metaMultiTiers_measurement')}>
            <Form.Item name='measurement'>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Form.Item name='floorSystemFiles'>
        <Upload
          fileList={[...fileList]}
          action={`${SERVER_URL}/upload-file`}
          name='file'
          headers={{
            Authorization: `Bearer ${currentuser.data.token}`,
            uri: 'public/',
            timestamp: Date.now().toString()
          }}
          onChange={(info) => {
            if (info.file.status === 'done') {
              setFiles([
                ...files,
                {
                  key: info.file.response.key,
                  location: info.file.response.location,
                  originalName: info.file.name,
                  type: info.file.response.mimetype,
                  sketchType: 'floorSystemFile'
                }
              ]);
              notification.success({
                message: 'Upload successfull',
                placement: 'bottomRight'
              });
            } else if (info.file.status === 'error') {
              notification.error({
                message: 'Upload failed',
                placement: 'bottomRight'
              });
            }
            setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
          }}
          beforeUpload={(file) => {
            const isAllowedFormat =
              [
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/pdf'
              ].includes(file.type) || file.type.includes('image' || 'svg');
            if (!isAllowedFormat) {
              notification.error({
                message: 'You can only upload PDF, XLSX or image file!',
                placement: 'bottomRight'
              });
              return false;
            }
            if (file.size / 1000000 > 10) {
              notification.error({
                message: 'File is larger than 10 MB!',
                placement: 'bottomRight'
              });
              return false;
            }
            return true;
          }}
          onRemove={async (file) => {
            notification.info({
              message: 'Removing file in progress.',
              placement: 'bottomRight'
            });
            let newFileList = [...fileList];
            let newFiles = [...files];
            let foundFile;
            for (let i = 0; i < fileList.length; i++) {
              if (fileList[i].response.key === file.response.key) {
                foundFile = fileList[i];
                newFileList.splice(i, 1);
                if (data && data.pbbFiles && data.pbbFiles.length !== 0) {
                  data.pbbFiles.splice(i, 1);
                  await Axios.put(
                    `${SERVER_URL}/pbb-reports/${data._id}`,
                    { pbbFiles: data.pbbFiles },
                    {
                      withCredentials: false,
                      headers: { Authorization: `Bearer ${currentuser.data.token}` }
                    }
                  );
                }
              }
            }
            for (let i = 0; i < files.length; i++) {
              if (files[i].originalName === file.name) {
                newFiles.splice(i, 1);
              }
            }
            setFileList(newFileList);
            setFiles(newFiles);
            let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
            if (linkParts && linkParts.length !== 0) {
              await Axios.post(
                `${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`,
                {},
                {
                  withCredentials: false,
                  headers: { Authorization: `Bearer ${currentuser.data.token}` }
                }
              );
            }
          }}>
          <Button id='uploadButton' icon={<UploadOutlined />}>
            {t('uploadFile')}
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item name='specialFloorSystem' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={t('positioning/SpecialFeatures')} />
      </Form.Item>
    </div>
  );
};
export default withNamespaces()(FloorSystemForm);
