import { Button, Card, Checkbox, Divider, Form, Input, Radio, Space, Row, Col } from 'antd';
import React, { useState } from 'react';
import EmbedImage from '../base/EmbedImage';
import { withNamespaces } from 'react-i18next';

const MultiblocMobileForm = ({ data, files, setFiles, t }) => {
  const [controlUnit, setControlUnit] = useState(data && data.controlUnit);
  const [isBothLayouts, setIsBothLayouts] = useState(false);

  return (
    <div>
      <h3 id='meta-dynamics'>{t('metaDynamicsTitle')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='multiblocLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoMultiBloc'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsLoad')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <p className='pbbText'>{t('metaDynamicsPleaseConsiderOverhang')}</p>
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name='maxWeightMultibloc'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsMaxWeightPerPallet')}`}>
            <Input type='number' suffix='kg' placeholder={t('metaDynamicsMaxWeightPerPallet')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='bayLoadMultibloc'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsBayLoadInclTrolleyLevel')}`}>
            <Input
              type='number'
              suffix='kg'
              placeholder={t('metaDynamicsBayLoadInclTrolleyLevel')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='compartmentMultibloc'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsCompartmentLoad')}`}>
            <Input type='number' suffix='kg' placeholder={t('metaDynamicsCompartmentLoad')} />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsLoadCarrier')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name={['loadCarrierOneDepth']}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsDepth')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsDepth')} />
          </Form.Item>
          <Form.Item
            name={['loadCarrierOneWidth']}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsWidth')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsWidth')} />
          </Form.Item>
          <Form.Item
            name={['loadCarrierOneHeight']}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsMMWithoutOverhangLabel')}:`}>
            <Input
              type='number'
              suffix={t('metaDynamicsMMWithoutOverhang')}
              placeholder={t('metaDynamicsHeight')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name='loadCarrier'>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={[field.name, 'depth']}
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}
                    label={`${t('metaDynamicsDepth')}`}>
                    <Input type='number' suffix='mm' placeholder={t('metaDynamicsDepth')} />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'width']}
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}
                    label={`${t('metaDynamicsWidth')}`}>
                    <Input type='number' suffix='mm' placeholder={t('metaDynamicsWidth')} />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'height']}
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}
                    label={`${t('metaDynamicsMMWithoutOverhangLabel')}:`}>
                    <Input
                      type='number'
                      suffix={t('metaDynamicsMMWithoutOverhang')}
                      placeholder={t('metaDynamicsHeight')}
                    />
                  </Form.Item>
                  <Button onClick={() => remove(field.name)}>{t('remove')}</Button>
                </Col>
              </React.Fragment>
            ))}
            {fields.length < 9 && (
              <Col xs={24} md={3}>
                <Form.Item>
                  <Button type='primary' onClick={() => add()}>
                    {t('addMoreBtn')}
                  </Button>
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Form.List>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsShelfStructure')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name='mobileRacksQty'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsNumberOfMobileRacks')}`}>
            <Input
              type='number'
              suffix={t('pieces')}
              placeholder={t('metaDynamicsNumberOfMobileRacks')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name='stationaryRacksQty'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsNumberOfStationaryRacks')}`}>
            <Input
              type='number'
              suffix={t('pieces')}
              placeholder={t('metaDynamicsNumberOfStationaryRacks')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name='mobileRowsQty'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsNumberOfMobileRows')}`}>
            <Input
              type='number'
              suffix={t('pieces')}
              placeholder={t('metaDynamicsNumberOfMobileRows')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name='stationaryRowsQty'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsNumberOfStationaryRows')}`}>
            <Input
              type='number'
              suffix={t('pieces')}
              placeholder={t('metaDynamicsNumberOfStationaryRows')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='technicalSide'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsTechnicalSideInLight')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsTechnicalSideInLight')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='palletsQty'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsNumberOfPalletsPerCompartment')}`}>
            <Input
              type='number'
              suffix={t('pieces')}
              placeholder={t('metaDynamicsNumberOfPalletsPerCompartment')}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsFloorHeights')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='upperEdgeLevelOne'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsUpperEdgeLevel')} 1:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 1'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelTwo' label={`${t('metaDynamicsUpperEdgeLevel')} 2:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 2'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelThree' label={`${t('metaDynamicsUpperEdgeLevel')} 3:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 3'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelFour' label={`${t('metaDynamicsUpperEdgeLevel')} 4:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 4'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelFive' label={`${t('metaDynamicsUpperEdgeLevel')} 5:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 5'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelSix' label={`${t('metaDynamicsUpperEdgeLevel')} 6:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 6'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelSeven' label={`${t('metaDynamicsUpperEdgeLevel')} 7:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 7'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelEight' label={`${t('metaDynamicsUpperEdgeLevel')} 8:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 8'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelNine' label={`${t('metaDynamicsUpperEdgeLevel')} 9:`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsUpperEdgeLevel') + ' 9'} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='upperEdgeLevelTen' label={`${t('metaDynamicsUpperEdgeLevel')} 10:`}>
            <Input
              type='number'
              suffix='mm'
              placeholder={t('metaDynamicsUpperEdgeLevel') + ' 10'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '16px' }}>
        <Col xs={24} md={12}>
          <Form.Item
            name='travelPathWidth'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsTravelPathWidth')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsTravelPathWidth')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='aisleWidth'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsAisleWidth')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsAisleWidth')} />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsDistanceBetweenTrolleyWall')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='smoothWall'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsSmoothWall150')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsSmoothWall150')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='widthEdge'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaDynamicsWidthEdgeMin500')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsWidthEdgeMin500')} />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsRailInstallation')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='railInstalation'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Radio.Group>
              <Space direction='horizontal'>
                <Radio className='pbbCheckbox' value='newFloor'>
                  {t('metaDynamicsNewFloorOnStripFoundation')}
                </Radio>
                <Radio className='pbbCheckbox' value='existingFloor'>
                  {t('metaDynamicsExistingFloorGrouting')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsRailInstallationControlUnit')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='controlUnit'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox.Group defaultValue={controlUnit} onChange={(e) => setControlUnit(e)}>
            <Space direction='horizontal'>
              <Checkbox value='frontLoader' className='pbbCheckbox'>
                {t('metaDynamicsFrontLoader')}
              </Checkbox>
              <Checkbox value='thrustMast' className='pbbCheckbox'>
                {t('metaDynamicsThrustMastForkLiftTruck')}
              </Checkbox>
              <Checkbox value='wheelchair' className='pbbCheckbox'>
                {t('metaDynamicsWheelchairAccessible')}
              </Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsFireProtection')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card className='pbbCard' title={t('metaDynamicsSprinkler')}>
            <Form.Item
              name='sprinklerMultibloc'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsHallDimensions')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='clearHallHeight' label={`${t('metaDynamicsClearHallHeight')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsClearHallHeight')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='clearHallLength' label={`${t('metaDynamicsClearHallLength')}`}>
            <Input type='number' suffix='mm' placeholder={t('metaDynamicsClearHallLength')} />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaDynamicsRoomProperties')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaDynamicsNormalTemperature')}>
            <Form.Item
              name='normalTemperature'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaDynamicsFreezingTemp28')}>
            <Form.Item
              name='freezingTemperature'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <EmbedImage data={data} files={files} setFiles={setFiles} name='multiblocImage' />
      <Form.Item name='specialMultibloc' label={`${t('positioning/SpecialFeatures')}`}>
        <Input.TextArea rows={3} placeholder={t('positioning/SpecialFeatures')} />
      </Form.Item>
    </div>
  );
};

export default withNamespaces()(MultiblocMobileForm);
