import React, { useContext, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import EditProductCategory from '../../pages/productCategory/EditProductCategory';

import { withNamespaces } from 'react-i18next';

const ProductCategoryTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, t }) => {
  const user = useContext(UserContext);
  let searchInput;
  const [productCategoryID, setProductCategoryID] = useState();
  const [showModal, setShowModal] = useState(false);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            {t('search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            {t('reset')}
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  const onEditClick = (ID) => {
    setProductCategoryID(ID);
    setShowModal(!showModal);
  };

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <EditOutlined
            className='icon-unlock'
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
          <EditFilled
            className='icon-lock'
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeOutlined
                className='icon-lock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        {/*<div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={t('deleteConfirm')}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <DeleteOutlined className='icon-unlock' title={`Delete ${title.toLowerCase()}`} />
            <DeleteFilled className='icon-lock' title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
          </div>*/}
      </div>
    ),
  });

  // for (let i = 0; i < columns.length; i++) {
  //   if (columns[i].title === 'name') {
  //     columns[i].title = t('eventName');
  //   } else if (columns[i].title === 'location') {
  //     columns[i].title = t('eventLocation');
  //   } else {
  //     columns[i].title = t(`${columns[i].title}`);
  //   }
  // }
  
  if(data) {
    data.map(productCategory => {
      productCategory.name = t(productCategory.name);
    })
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 10,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
      <EditProductCategory id={productCategoryID} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default withNamespaces()(ProductCategoryTable);
