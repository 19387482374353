import React, { useContext, useEffect, useState } from 'react';
import useAxios from '../../hooks/useAxios';
import {
  Modal,
  Row,
  Col,
  Tabs,
  Checkbox,
  Spin,
  Upload,
  Tooltip,
  Input,
  message,
  Button,
  notification
} from 'antd';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import Axios from 'axios';
import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const { Dragger } = Upload;

const GalleryModal = ({
  visible,
  formKey,
  limit,
  SERVER_URL,
  token,
  onInsert,
  onCancel,
  form,
  imageType,
  imageHeight,
  imageSavePath,
  imageId,
  imageIndex
}) => {
  const [images, fetchImages] = useAxios('', null, token, 'get');
  const [gallery, setGallery] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const [activeTab, setActiveTab] = useState('UPLOAD');
  const [search, setSetSearch] = useState('');
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [data, setData] = useState();
  const currentuser = useContext(UserContext);

  /**
   * fetch all images from db
   */
  useEffect(() => {
    fetchImages(`${SERVER_URL}/images`, []);
  }, [fetchImages, SERVER_URL]);

  /**
   * If there is previous images in form -> set them to selected state
   */
  useEffect(() => {
    const prevSelected = form.getFieldValue(formKey);
    if (prevSelected?._id) {
      setSelected([prevSelected._id]);
    }
    if (Array.isArray(prevSelected) && prevSelected?.length > 0) {
      setSelected(prevSelected.map((item) => item._id));
    }
  }, [setSelected, form, formKey, limit]);

  /**
   * Sort images so that selected images are first in gallery
   */
  useEffect(() => {
    if (images.data?.length > 0) {
      if (selected.length > 0) {
        const notSelectedImages = images.data.filter((image) => !selected.includes(image._id));
        const selectedImages = selected.map((id) => {
          const img = images.data.find((image) => image._id === id);
          return img;
        });
        setGallery([...selectedImages, ...notSelectedImages]);
      } else {
        setGallery(images.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const selectHandler = (id) => {
    if (limit > 1) {
      if (selected.includes(id)) {
        const removed = selected.filter((item) => item !== id);
        setSelected(removed);
      }
      if (!selected.includes(id) && selected.length < +limit) {
        setSelected([...selected, id]);
      }
      if (selected.length >= +limit) {
        return notification.error({
          message: `You can select max ${limit} images`
        });
      }
    }
    if (limit === 1) {
      setCurrentSelected(id);
      if (imageIndex >= 0) {
        const newSelected = [...selected];
        if (selected.includes(id)) {
          newSelected.splice(imageIndex, 1);
        } else {
          newSelected.splice(imageIndex, 1, id);
        }
        setSelected(newSelected);
      } else {
        setSelected(!selected.includes(id) ? [id] : []);
      }
    }
  };

  const beforeUpload = (file) => {
    const isAllowedFormat = ['image/jpeg', 'image/png'].includes(file.type);
    if (!isAllowedFormat) {
      notification.error({
        message: 'You can only upload JPG or PNG file!',
        placement: 'bottomRight'
      });
      return Upload.LIST_IGNORE;
    }
    const isAllowedSize = file.size / 1024 / 1024 < 5;
    if (!isAllowedSize) {
      notification.error({
        message: 'Image must smaller than 5MB!',
        placement: 'bottomRight'
      });
      return false;
    }
    return isAllowedFormat && isAllowedSize;
  };

  const onUpload = async ({ file, fileList }) => {
    if (file.status === 'done') {
      if (file.response && file.response.file) {
        message.success(`${file.response.file.name} ${t('uploadedSuccessfully')}.`);
        setUploaded([...uploaded, file.response.image]);
        selectHandler(file.response.image._id);
      }
    } else if (file.status === 'error') {
      message.error(`${file.response.file.name} file upload failed.`);
    }
    if (fileList.length > 1 && fileList.every((f) => f.status === 'done')) {
      setUploaded(fileList.map((f) => f.response.image));
    }
  };

  const onOk = () => {
    const selectedImages = selected.map((selectedImg) => {
      const image = [...gallery, ...uploaded].find((image) => image._id === selectedImg);
      return image;
    });
    onInsert(limit === 1 && !(imageIndex >= 0) ? selectedImages[0] : selectedImages);
    onCancel();
  };

  return (
    <Modal
      className='gallery-modal'
      centered
      width='95%'
      visible={visible}
      title={<strong>{t('imageGallery')}</strong>}
      okText={t('insert')}
      cancelText={t('cancel')}
      maskClosable={false}
      onOk={onOk}
      onCancel={() => {
        // form.resetFields();
        onCancel();
      }}>
      <Tabs
        tabPosition='top'
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        style={{ height: '650px', overflowY: 'auto' }}>
        {(currentuser.data.role.includes('admin') ||
          currentuser.data.role.includes('superAdmin')) && (
          <Tabs.TabPane tab={`${t('gallery')} (${images?.data?.length || 0})`} key='GALLERY'>
            <Spin spinning={images.isLoading} tip='Loading images...'>
              <Input
                addonAfter={<SearchOutlined style={{ marginLeft: '5px' }} />}
                value={search}
                onChange={(e) => setSetSearch(e.target.value)}
                style={{ width: '250px', marginBottom: '12px' }}
                placeholder={t('searchByFilename')}
              />
              <Row gutter={[8, 8]}>
                {gallery?.length > 0 &&
                  gallery
                    .filter((image) =>
                      image.originalname.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((image, i) => {
                      return (
                        <Col xs={12} sm={8} md={6} lg={4} xl={3} xxl={2} key={image._id}>
                          <div
                            style={{
                              height: '150px',
                              position: 'relative',
                              background: '#ccc',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <img
                              title={image.originalname}
                              src={`${image.url}`}
                              alt={image.originalName}
                              style={{ position: 'absolute', maxHeight: '148px', width: 'auto' }}
                            />
                            <Tooltip title={selected.includes(image._id) ? 'Deselect' : 'Select'}>
                              <Checkbox
                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                                checked={selected.includes(image._id)}
                                onChange={() => selectHandler(image._id)}
                                disabled={
                                  imageId
                                    ? image._id !== imageId &&
                                      selected.includes(image._id) &&
                                      currentSelected !== image._id
                                    : false
                                }
                                // disabled={imageIndex ? imageIndex !== i && selected.includes(image._id) : false}
                              />
                            </Tooltip>
                          </div>
                          <p
                            style={{
                              fontSize: '0.725rem',
                              textAlign: 'center'
                            }}>
                            {image.originalname}
                          </p>
                        </Col>
                      );
                    })}
              </Row>
            </Spin>
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab={t('upload')} key='UPLOAD' forceRender>
          <Upload
            fileList={[...fileList]}
            action={`${SERVER_URL}/upload-image`}
            name='file'
            headers={{
              Authorization: `Bearer ${currentuser.data.token}`,
              uri: 'public/',
              timestamp: Date.now().toString()
            }}
            onChange={(info) => {
              if (info.file.status === 'done') {
                setFiles([
                  ...files,
                  {
                    key: info.file.response.key,
                    location: info.file.response.location,
                    originalName: info.file.name,
                    type: info.file.response.mimetype,
                    sketchType: 'floorSystemFile'
                  }
                ]);
                notification.success({
                  message: 'Upload successfull',
                  placement: 'bottomRight'
                });
              } else if (info.file.status === 'error') {
                notification.error({
                  message: 'Upload failed',
                  placement: 'bottomRight'
                });
              }
              setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
            }}
            beforeUpload={(file) => {
              const isAllowedFormat =
                [
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/pdf'
                ].includes(file.type) || file.type.includes('image' || 'svg');
              if (!isAllowedFormat) {
                notification.error({
                  message: 'You can only upload PDF, XLSX or image file!',
                  placement: 'bottomRight'
                });
                return false;
              }
              if (file.size / 1000000 > 10) {
                notification.error({
                  message: 'File is larger than 10 MB!',
                  placement: 'bottomRight'
                });
                return false;
              }
              return true;
            }}
            onRemove={async (file) => {
              notification.info({
                message: 'Removing file in progress.',
                placement: 'bottomRight'
              });
              let newFileList = [...fileList];
              let newFiles = [...files];
              let foundFile;
              for (let i = 0; i < fileList.length; i++) {
                if (fileList[i].response.key === file.response.key) {
                  foundFile = fileList[i];
                  newFileList.splice(i, 1);
                  if (data && data.pbbFiles && data.pbbFiles.length !== 0) {
                    data.pbbFiles.splice(i, 1);
                    await Axios.put(
                      `${SERVER_URL}/pbb-reports/${data._id}`,
                      { pbbFiles: data.pbbFiles },
                      {
                        withCredentials: false,
                        headers: { Authorization: `Bearer ${currentuser.data.token}` }
                      }
                    );
                  }
                }
              }
              for (let i = 0; i < files.length; i++) {
                if (files[i].originalName === file.name) {
                  newFiles.splice(i, 1);
                }
              }
              setFileList(newFileList);
              setFiles(newFiles);
              let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
              if (linkParts && linkParts.length !== 0) {
                await Axios.post(
                  `${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`,
                  {},
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${currentuser.data.token}` }
                  }
                );
              }
            }}>
            <Button id='uploadButton' icon={<UploadOutlined />}>
              {t('uploadFile')}
            </Button>
          </Upload>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default withNamespaces()(GalleryModal);
