import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from './config';
import Layout from './components/base/Layout';
import Login from './pages/Login';
import ForgotPassword from './pages/users/ForgotPassword';
import './App.scss';
import ResetPassword from './pages/users/ResetPassword';
import ChangePassword from './pages/users/ChangePassword';

export const UserContext = createContext(null);

const handleLogin = async (user) => {
  try {
    const userResponse = await Axios.post(`${SERVER_URL}/login`, { email: user.email, password: user.password }, { withCredentials: false });
    if (userResponse.data.message === 'Auth successful' && (userResponse.data.role.includes('admin') || !userResponse.data.role.includes('user'))
      && !userResponse.data.disabled) {
      localStorage.setItem('user', JSON.stringify(userResponse.data));
      // window.location.reload();
      if (sessionStorage.getItem('initialUrl')) {
        window.location.href = sessionStorage.getItem('initialUrl');
      } else {
        (userResponse.data.role.includes('admin') || userResponse.data.role.includes('superAdmin')) ?
          window.location.href = '/admin/events' : window.location.href = 'admin/event-reports';
      }
      sessionStorage.removeItem('initialUrl');
    } else if ((userResponse.data.message === 'Auth successful' && !userResponse.data.role.includes('admin')) || userResponse.data.disabled) {
      return message.info("You don't have access to dashboard", 3);
    }
  } catch (err) {
    console.log('\n Login error = ', err.response.data.message);
    return message.warning(err.response.data.message, 3);
  }
};

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [language, setLanguage] = useState();
  let user = localStorage.getItem('user');
  if (user) user = JSON.parse(user);

  if ((window.location.href.includes('edit-pbb-report') || window.location.href.includes('view-pbb-report')) && !sessionStorage.getItem('initialUrl') && !user) {
    sessionStorage.setItem('initialUrl', window.location.href);
  }

  if (window.location.href.includes('email-verification') || window.location.href.includes('password-reset')) {
    localStorage.removeItem('user');
    localStorage.removeItem('language');
  }

  useEffect(() => {
    if (user && new Date() > new Date(user.expires)) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (user === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, user]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/languages-selected`)
      .then((resp) => {
        const defaultLanguage = resp.data.find((l) => l.default);
        const selectedLanguage = resp.data.find((l) => l.selected);
        setLanguage({ selected: selectedLanguage, list: resp.data, default: defaultLanguage });
      })
      .catch((err) => console.log(err.response ? err.response.data.message : err.message));
  }, [setLanguage]);

  return (
    <UserContext.Provider value={{ isLoggedIn, handleLogin, data: user, language, setLanguage }}>
      <Router>
        {isLoggedIn && <Layout />}
        {!isLoggedIn && <Route exact path='/admin' component={Login} />}
        {!isLoggedIn && <Route exact path='/login' component={Login} />}
        {<Route exact path='/forgot-password' component={ForgotPassword} />}
        {<Route exact path='/password-reset/:passwordToken' component={ResetPassword} />}
        {<Route exact path='/email-verification/:token' component={ChangePassword} />}
      </Router>
    </UserContext.Provider>
  );
};

export default App;
