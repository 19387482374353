import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

import { withNamespaces } from 'react-i18next';

const ExportToExcel = ({ csvData, fileName, propsToRemove, header, format, disabled, title, t }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const removeProperties = (object, ...keys) => {
    // Object.entries(object).reduce(
    //   (prev, [key, value]) => ({
    //     ...prev,
    //     ...(!keys.includes(key) && { [key]: value }),
    //   }),
    //   {},
    Object.keys(object).map(item => {
      for (let i = 0; i < propsToRemove.length; i++) {
        if (item === propsToRemove[i]) {
          delete object[item];
        }
      }
    });
  };

  // const dataForExcel_OLD = async (data) => {
  //   let retData = [];
  //   // Ako zelis da promenis imena polja u xlsx tabeli po imenu format propsa iz glavne komponente
  //   if (format === 'custom_name') {
  //     let promises = data.map(async (item) => {
  //       Object.keys(item).map(prop => {
  //         let newProp = t(prop);
  //         item[newProp] = item[prop];
  //         // delete item[prop];
  //         retData.push(item[newProp]);
  //       })
  //       // item["email"] = item["userName"];  // dodeli vrednost starog polja novom
  //       // delete item['staro_ipepolja'];  // obrisi staro polje
  //       // retData.push(item[i]);  // push to new array
  //     });
  //     await Promise.all(promises);
  //   }
  //   else {
  //     retData = await data ? data.map((item) => removeProperties(item, ...propsToRemove)) : [{data: 'No data selected. Please select at least one column.'}];
  //   }
  //   // retData = await data;
  //   return retData;
  // };

  const dataForExcel = async (data) => {
    let returnData = [];
    data.map(item => {
      returnData.push(JSON.parse(JSON.stringify(item)));
    });

    if (propsToRemove && Array.isArray(propsToRemove) && propsToRemove.length > 0) {
      returnData.map((item) => removeProperties(item, ...propsToRemove));
    }

    if (format === 'custom_name') {
      returnData.map(async (item) => {
        Object.keys(item).map(prop => {
          // if(typeof item[prop] === 'object') {
          //   Object.keys(item[prop]).map(objectProp => {
          //     item[objectProp] = item[prop][objectProp];
          //     console.log('item[objectProp]: ', objectProp + ": " + item[objectProp]);
          //   })
          // } 
          // else {
          let newProp = t(prop);

          item[newProp] = item[prop];
          delete item[prop];
          // }
        });
      });
    }

    return returnData;


    // // Ako zelis da promenis imena polja u xlsx tabeli po imenu format propsa iz glavne komponente
    // if (format === 'custom_name') {
    //   let promises = data.map(async (item) => {
    //     Object.keys(item).map(prop => {
    //       let newProp = t(prop);
    //       item[newProp] = item[prop];
    //       // delete item[prop];
    //       retData.push(item[newProp]);
    //     })
    //     // item["email"] = item["userName"];  // dodeli vrednost starog polja novom
    //     // delete item['staro_ipepolja'];  // obrisi staro polje
    //     // retData.push(item[i]);  // push to new array
    //   });
    //   await Promise.all(promises);
    // }
    // else {
    //   retData = await data ? data.map((item) => removeProperties(item, ...propsToRemove)) : [{data: 'No data selected. Please select at least one column.'}];
    // }
    // // retData = await data;
    // return retData;
  };

  const exportToCSV = async (csvData, fileName) => {

    const filtered = await dataForExcel(csvData);
    const ws = XLSX.utils.json_to_sheet(filtered, { header: header });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      disabled={disabled}
      type='primary'
      size='small'
      style={disabled ? { marginLeft: '15px', cursor: 'not-allowed' } : { marginLeft: '15px' }}
      title={title ? title : 'Export to Excel'}
      onClick={(e) => exportToCSV(csvData, fileName)}>
      <ExportOutlined /> {title ? title : t('exportExcel')}
    </Button>
  );
};

export default withNamespaces()(ExportToExcel);
