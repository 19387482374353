import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Input, Button, DatePicker, notification, Select, Upload, Checkbox, Divider, Row, Col } from 'antd';
import countryList from 'react-select-country-list';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import ProjectDescriptionSheetForm from './ProjectDescriptionSheetForm';
import FloorSystemForm from './FloorSystemForm';

import { withNamespaces } from 'react-i18next';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { CalendarOutlined, UploadOutlined } from '@ant-design/icons';
import GalleryModal from './GalleryModal';
import MultipalPalletForm from './multipalPalletForm';
import { use } from 'i18next';
import ClipForm from './ClipForm';
import ClipCompactForm from './ClipCompactForm';
import MultifloorForm from './MultifloorForm';
import MultistrongForm from './MultistrongForm';
import MezzanineForm from './MezzanineForm';
import MiniRackForm from './MiniRackForm';
import SpeedRackForm from './SpeedRackForm';
import MultiblocMobileForm from './MultiblocMobileForm';
// import CustomerMasterForm from './CustomerMasterForm';
import InfoSheet from './InfoSheetForm';

import moment from 'moment';

const formInit = {
  customer: undefined,
  country: undefined,
  salesManager: undefined,
};

const PbbReportForm = ({ isNew, data, createHandler, updateHandler, t }) => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [salesManagers, fetchSalesManagers] = useAxios('', [], currentuser.data.token, 'get');
  const [projectManagers, fetchProjectManagers] = useAxios('', [], currentuser.data.token, 'get');
  const options = useMemo(() => countryList().getData(), []);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [isError, setIsError] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  // Check if Product Category is selected in Product Selection
  const [metaMultiTiersProductChecked, setMetaMultiTiersProductChecked] = useState(
    data ? data.metaMultiTiersProductChecked : false,
  );
  const [metaMultipalPalletRackProductChecked, setMetaMultipalPalletRackProductChecked] = useState(
    data ? data.metaMultipalPalletRackProductChecked : false,
  );
  const [metaClipProductChecked, setMetaClipProductChecked] = useState(data ? data.metaClipProductChecked : false);
  const [metaClipCompactProductChecked, setMetaClipCompactProductChecked] = useState(
    data ? data.metaClipCompactProductChecked : false,
  );
  const [metaMultifloorProductChecked, setMetaMultifloorProductChecked] = useState(
    data ? data.metaMultifloorProductChecked : false,
  );
  const [metaMultistrongCantileverProductChecked, setMetaMultistrongCanteileverProductChecked] = useState(
    data ? data.metaMultistrongCantileverProductChecked : false,
  );
  const [metaMezzanineSteelConstructionProductChecked, setMetaMezzanineSteelConstructionProductChecked] = useState(
    data ? data.metaMezzanineSteelConstructionProductChecked : false,
  );
  const [metaMiniRackWideSpanRackProductChecked, setMetaMiniRackWideSpanRackProductChecked] = useState(
    data ? data.metaMiniRackWideSpanRackProductChecked : false,
  );
  const [metaMiniSpeedRackWideSpanRackProductChecked, setMetaMiniSpeedRackWideSpanRackProductChecked] = useState(
    data ? data.metaMiniSpeedRackWideSpanRackProductChecked : false,
  );
  const [metaMultiblocMobileRackingSystemProductChecked, setMetaMultiblocMobileRackingSystemProductChecked] = useState(
    data ? data.metaMultiblocMobileRackingSystemProductChecked : false,
  );
  const [selectedProductCategories, setSelectedProductCategories] = useState([]);

  const [shelfQty, setShelfQty] = useState(data && data.shelfsLoad ? data.shelfsLoad : 0);
  const [numStairs, setNumStairs] = useState(data && data.numStairs ? data.numStairs : 0);
  const [heightQty, setHeightQty] = useState(data && data.heightQty ? data.heightQty : 0);

  /* Add/Remove Product Categories from Navigation useEffect */
  useEffect(() => {
    const productCategoriesArray = [];

    //META CLIP
    if (metaClipProductChecked === true) {
      productCategoriesArray.push({ label: 'META CLIP', linkId: 'meta-clip' });
    }
    if (metaClipProductChecked === false && selectedProductCategories.includes({ label: 'META CLIP', linkId: 'meta-clip' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== 'META CLIP');
    }

    //META CLIP COMPACT
    if (metaClipCompactProductChecked === true) {
      productCategoriesArray.push({ label: 'META CLIP COMPACT', linkId: 'meta-clip-compact' });
    }
    if (metaClipCompactProductChecked === false && selectedProductCategories.includes({ label: 'META CLIP COMPACT', linkId: 'meta-clip-compact' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META CLIP COMPACT', linkId: 'meta-clip-compact' });
    }

    //META MINI-RACK
    if (metaMiniRackWideSpanRackProductChecked === true) {
      productCategoriesArray.push({ label: 'META MINI-RACK', linkId: 'meta-mini-rack' });
    }
    if (metaMiniRackWideSpanRackProductChecked === false && selectedProductCategories.includes({ label: 'META MINI-RACK', linkId: 'meta-mini-rack' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META MINI-RACK', linkId: 'meta-mini-rack' });
    }

    //META SPEED-RACK
    if (metaMiniSpeedRackWideSpanRackProductChecked === true) {
      productCategoriesArray.push({ label: 'META SPEED-RACK', linkId: 'meta-speed-rack' });
    }
    if (metaMiniSpeedRackWideSpanRackProductChecked === false && selectedProductCategories.includes({ label: 'META SPEED-RACK', linkId: 'meta-speed-rack' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META SPEED-RACK', linkId: 'meta-speed-rack' });
    }

    //META MULTIPAL Pallet Rack
    if (metaMultipalPalletRackProductChecked === true) {
      productCategoriesArray.push({ label: 'META MULTIPAL', linkId: 'meta-multipal' });
    }
    if (metaMultipalPalletRackProductChecked === false && selectedProductCategories.includes({ label: 'META MULTIPAL', linkId: 'meta-multipal' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META MULTIPAL', linkId: 'meta-multipal' });
    }

    //META MULTIFLOOR
    if (metaMultifloorProductChecked === true) {
      productCategoriesArray.push({ label: 'META MULTIFLOOR', linkId: 'meta-multifloor' });
    }
    if (metaMultifloorProductChecked === false && selectedProductCategories.includes({ label: 'META MULTIFLOOR', linkId: 'meta-multifloor' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META MULTIFLOOR', linkId: 'meta-multifloor' });
    }

    //META MULTISTRONG
    if (metaMultistrongCantileverProductChecked === true) {
      productCategoriesArray.push({ label: 'META MULTISTRONG', linkId: 'meta-multistrong' });
    }
    if (metaMultistrongCantileverProductChecked === false && selectedProductCategories.includes({ label: 'META MULTISTRONG', linkId: 'meta-multistrong' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META MULTISTRONG', linkId: 'meta-multistrong' });
    }

    //META Multi-tiers
    if (metaMultiTiersProductChecked === true) {
      productCategoriesArray.push({ label: t('metaMultiTiers'), linkId: 'meta-multi-tiers' });
    }
    if (metaMultiTiersProductChecked === false && selectedProductCategories.includes({ label: t('metaMultiTiers'), linkId: 'meta-multi-tiers' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: t('metaMultiTiers'), linkId: 'meta-multi-tiers' });
    }

    //META Dynamics
    if (metaMultiblocMobileRackingSystemProductChecked === true) {
      productCategoriesArray.push({ label: 'META Dynamics', linkId: 'meta-dynamics' });
    }
    if (metaMultiblocMobileRackingSystemProductChecked === false && selectedProductCategories.includes({ label: 'META Dynamics', linkId: 'meta-dynamics' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META Dynamics', linkId: 'meta-dynamics' });
    }

    //META Steel Construction
    if (metaMezzanineSteelConstructionProductChecked === true) {
      productCategoriesArray.push({ label: 'META Mezzanine', linkId: 'meta-steel-construction' });
    }
    if (metaMezzanineSteelConstructionProductChecked === false && selectedProductCategories.includes({ label: 'META Mezzanine', linkId: 'meta-steel-construction' })) {
      productCategoriesArray.filter((productCategory) => productCategory !== { label: 'META MEZZANINE', linkId: 'meta-steel-construction' });
    }

    // productCategoriesArray.unshift({ label: 'Product Selection', linkId: 'product-selection'});
    // productCategoriesArray.unshift({ label: 'Project Description Sheet', linkId: 'project-description-sheet'});
    // setSelectedProductCategories([...productCategoriesArray, { label: 'Info sheet: Lifting equipment', linkId: 'info-sheet' }]);
    setSelectedProductCategories(productCategoriesArray);
  }, [
    metaMultiTiersProductChecked,
    metaMultipalPalletRackProductChecked,
    metaClipProductChecked,
    metaClipCompactProductChecked,
    metaMultifloorProductChecked,
    metaMultistrongCantileverProductChecked,
    metaMezzanineSteelConstructionProductChecked,
    metaMiniRackWideSpanRackProductChecked,
    metaMiniSpeedRackWideSpanRackProductChecked,
    metaMultiblocMobileRackingSystemProductChecked
  ]);

  useEffect(() => {
    let filter = { role: ['salesManager'], status: 'ACTIVE' };
    if (data && data.salesManager) {
      filter = { $or: [{ $and: [{ role: ['salesManager'] }, { status: 'ACTIVE' }] }, { _id: data.salesManager }] };
    }
    let filterProjectManagers = { role: { $in: ['pbbAdmin', 'internalUser', 'externalUser'] }, status: 'ACTIVE' };
    fetchSalesManagers(`${SERVER_URL}/users?filter=${JSON.stringify(filter)}`, []);
    fetchProjectManagers(`${SERVER_URL}/users?filter=${JSON.stringify(filterProjectManagers)}`, []);
  }, [data, fetchSalesManagers, fetchProjectManagers]);

  if (data) {
    if (data.dateAppointment) { data.dateAppointment = moment(data.dateAppointment); };
    if (data.requestDate) { data.requestDate = moment(data.requestDate); };
    if (data.offerDeadline) { data.offerDeadline = moment(data.offerDeadline); };
    if (data.realizationDate) { data.realizationDate = moment(data.realizationDate); };
    if (data.deliveryDate) { data.deliveryDate = moment(data.deliveryDate); };
    if (data.assemblyDate) { data.assemblyDate = moment(data.assemblyDate); };
    if (data.goodsReceiptFrom) { data.goodsReceiptFrom = moment(data.goodsReceiptFrom).utcOffset(0); }
    if (data.goodsReceiptTo) { data.goodsReceiptTo = moment(data.goodsReceiptTo).utcOffset(0); }
    if (data.instalationHoursFrom) { data.instalationHoursFrom = moment(data.instalationHoursFrom).utcOffset(0); }
    if (data.instalationHoursTo) { data.instalationHoursTo = moment(data.instalationHoursTo).utcOffset(0); }
    // data.dateAppointment = moment(data.dateAppointment);
    // data.requestDate = moment(data.requestDate);
    // data.offerDeadline = moment(data.offerDeadline);
    // data.realizationDate = moment(data.realizationDate);
    // data.deliveryDate = moment(data.deliveryDate);
    // data.assemblyDate = moment(data.assemblyDate);
  }

  if (data && data.shelfsClip) {
    data.shelfsClip.forEach((shelfClip, index) => {
      data['shelfClip' + index] = shelfClip;
    });
  }
  if (data && data.shelfsClipCompact) {
    data.shelfsClipCompact.forEach((shelfClipCompact, index) => {
      data['shelfClipCompact' + index] = shelfClipCompact;
    });
  }
  if (data && data.shelfsMultifloor) {
    data.shelfsMultifloor.forEach((shelfMultifloor, index) => {
      data['shelfMultifloor' + index] = shelfMultifloor;
    });
  }

  if (data && data.loadCarriers) {
    data.loadCarriers.forEach((loadCarrier, index) => {
      data['loadCarrier' + index] = loadCarrier;
    });
  }

  let initialValues = { ...formInit, ...data, salesManager: data?.salesManager?._id };

  const onFinish = async (values, isNew) => {
    if (shelfQty) values.shelfsLoad = shelfQty;
    if (numStairs) values.numStairs = numStairs;
    if (heightQty) values.heightQty = heightQty;
    values.userId = currentuser.data.id;
    console.log(values);

    try {
      values.shelfsClip = [];
      let shelfsClip = Object.keys(values).filter((key) => key.includes('shelfClip'));
      shelfsClip.forEach((shelfKey) => {
        let shelf = values[shelfKey];
        let existValue = false;
        let keys = Object.keys(shelf);
        keys.forEach((key) => {
          if (shelf[key]) {
            existValue = true;
          }
        });
        if (existValue) {
          values.shelfsClip.push(shelf);
        }
      });

      values.shelfsClipCompact = [];
      let shelfsClipCompact = Object.keys(values).filter((key) => key.includes('shelfClipCompact'));
      shelfsClipCompact.forEach((shelfKey) => {
        let shelf = values[shelfKey];
        let existValue = false;
        let keys = Object.keys(shelf);
        keys.forEach((key) => {
          if (shelf[key]) {
            existValue = true;
          }
        });
        if (existValue) {
          values.shelfsClipCompact.push(shelf);
        }
      });

      values.shelfsMultifloor = [];
      let shelfsMultifloor = Object.keys(values).filter((key) => key.includes('shelfMultifloor'));
      shelfsMultifloor.forEach((shelfKey) => {
        let shelf = values[shelfKey];
        let existValue = false;
        let keys = Object.keys(shelf);
        keys.forEach((key) => {
          if (shelf[key]) {
            existValue = true;
          }
        });
        if (existValue) {
          values.shelfsMultifloor.push(shelf);
        }
      });

      values.loadCarriers = [];
      let loadCarriers = Object.keys(values).filter((key) => key.includes('loadCarrier'));
      loadCarriers.forEach((loadCarrierKey) => {
        let loadCarrier = values[loadCarrierKey];
        let existValue = false;
        let keys = Object.keys(loadCarrier);
        keys.forEach((key) => {
          if (loadCarrier[key]) {
            existValue = true;
          }
        });
        if (existValue) {
          values.loadCarriers.push(loadCarrier);
        }
      });
      values.loadCarriers.pop();

      if (image) {
        values.pbbImage = image._id;
      }

      let attachments = [];
      if (data) {
        attachments = data.pbbFiles;
      }
      for (let i = 0; i < files.length; i++) {
        attachments.push({
          key: files[i].key,
          location: files[i].location,
          originalName: files[i].originalName,
          type: files[i].type,
          sketchType: files[i].sketchType,
        });
      }
      values.pbbFiles = attachments;
      if (isNew) {
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } catch (error) {
      notification.error({
        message: error.message,
        placement: 'bottomRight',
      });
    }
  };

  const leadImageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'pbbImage' });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  if (data?.pbbFiles && fileList.length === 0) {
    data.pbbFiles.forEach((file, index) => {
      if (!file.sketchType) {
        setFileList((prevState) => [
          ...prevState,
          {
            uid: file._id.toString(),
            name: file.originalName,
            status: 'done',
            // thumbUrl,
            response: {
              key: file.key,
            },
            fileId: file._id,
          },
        ]);
      }
    });
  }

  return (
    <div className='panel panel-body pbb-form-panel'>
      <div className='pbb-form-navigation'>
        <h2 className='pbb-form-navigation__title'>{t('formNavigation')}</h2>
        <p className='pbb-form-navigation__item'>
          <a href='#project-description-sheet'>{t('projDescSheet')}</a>
        </p>
        <p className='pbb-form-navigation__item'>
          <a href='#product-selection'>{t('productSelection')}</a>
        </p>
        {selectedProductCategories.map((selectedProductCategory) => {
          return (
            <p className='pbb-form-navigation__item--child'><a href={'#' + selectedProductCategory.linkId}>{selectedProductCategory.label}</a></p>
          );
        }
        )}
        <p className='pbb-form-navigation__item'><a href="#info-sheet">{t('infoSheetNav')}</a></p>
      </div>
      <div className="pbb-select-navigation">
        <Select
          className='pbb-select-navigation-component'
          placeholder="Form Navigation"
          value="Form Navigation"
          getPopupContainer={trigger => trigger.parentNode}
        >
          <Select.Option key="projectDescriptionSheet"><a href='#project-description-sheet'>{t('projDescSheet')}</a></Select.Option>
          <Select.Option key="productSelection"><a href='#product-selection'>{t('productSelection')}</a></Select.Option>
          {selectedProductCategories.map((selectedProductCategory) => (
            <Select.Option key={selectedProductCategory.linkId}>
              <a style={{ paddingLeft: '8px' }} href={'#' + selectedProductCategory.linkId}>{selectedProductCategory.label}</a>
            </Select.Option>
          ))}
          <Select.Option key="infoSheet"><a href="#info-sheet">{t('infoSheetNav')}</a></Select.Option>
        </Select>
      </div>
      <div className='panel-body pbb-form-body'>
        <Form
          className='form-horizontal pbbForm'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
          disabled
        >
          {/*Base Form for Testing*/}
          {/* <Form.Item name='customer' label={t('customer')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='country' label={t('country')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              // placeholder={t('country')}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {
                options.map(countryOption => (
                  <Select.Option
                    key={countryOption.value}
                    value={countryOption.label}
                  >
                    {countryOption.label}
                  </Select.Option>
                )
                )
              }
            </Select>
          </Form.Item>
          <Form.Item
            label='Sales Manager'
            name='salesManager'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Select placeholder={t('salesManager')} getPopupContainer={trigger => trigger.parentNode}>
              {salesManagers.data && salesManagers.data.items && salesManagers.data.items.length !== 0 &&
                salesManagers.data.items.map((item) => (
                  <Select.Option key={item._id} value={item._id}>{item.firstName} {item.lastName}</Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item name='companyName' label={t('companyName')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name='clientNumber' label={t('clientNumber')}>
            <Input />
          </Form.Item>

          <Form.Item name='pbbImage' label={t('pbbImage')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Button onClick={leadImageHandler}><UploadOutlined /> {t('uploadImage')}</Button>
            {image && image.originalname && <div>{image.originalname.length > 40 ? image.originalname.substring(0, 40) + '...' : image.originalname}</div>}
          </Form.Item>
          <Form.Item name='pbbFiles' label='Files' rules={[
            {
              required: true,
              message: t('validationAlert'),
            },
          ]}
          >
            <Upload
              fileList={[...fileList]}
              // listType='picture'
              action={`${SERVER_URL}/upload-file`}
              name='file'
              headers={{
                Authorization: `Bearer ${currentuser.data.token}`,
                uri: 'public/',
                timestamp: Date.now().toString(),
              }}
              onChange={(info) => {
                if (!info.file.status) {
                  setIsError(true);
                }
                if (info.file.status === 'done') {
                  setIsError(false);
                  setFiles([...files, {
                    key: info.file.response.key,
                    location: info.file.response.location,
                    originalName: info.file.name,
                    type: info.file.response.mimetype
                  }]);
                  // for (const file of info.fileList) {
                  //   let nameArray = file.name.split('.');
                  //   switch (nameArray[nameArray.length - 1]) {
                  //     case 'pdf':
                  //       file.thumbUrl = '/pdf.png';
                  //       break;
                  //     case 'docx':
                  //       file.thumbUrl = '/docx.png';
                  //       break;
                  //     case 'png':
                  //       file.thumbUrl = '/png.png';
                  //       break;
                  //     case 'jpg':
                  //       file.thumbUrl = '/jpg.png';
                  //       break;
                  //     case 'xlsx':
                  //       file.thumbUrl = '/xlsx.png';
                  //       break;

                  //     default:
                  //       file.thumbUrl = '/other.png';
                  //       break;
                  //   }
                  // }
                  notification.success({
                    message: 'Upload successfull',
                    placement: 'bottomRight',
                  });
                } else if (info.file.status === 'error') {
                  notification.error({
                    message: 'Upload failed',
                    placement: 'bottomRight',
                  });
                }
                setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
              }}
              beforeUpload={(file) => {
                setIsError(false);
                const isAllowedFormat = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf'].includes(file.type);
                if (!isAllowedFormat) {
                  notification.error({
                    message: 'You can only upload PDF or XLSX file!',
                    placement: 'bottomRight',
                  });
                  return false;
                }
                if (file.size / 1000000 > 5) {
                  notification.error({
                    message: 'File is larger than 5 MB!',
                    placement: 'bottomRight',
                  });
                  return false;
                }
                return true;
              }}
              onRemove={async (file) => {
                notification.info({
                  message: 'Removing file in progress.',
                  placement: 'bottomRight',
                });
                let newFileList = [...fileList];
                let newFiles = [...files];
                let foundFile;
                for (let i = 0; i < fileList.length; i++) {
                  if (fileList[i].response.key === file.response.key) {
                    foundFile = fileList[i];
                    newFileList.splice(i, 1);
                    if (
                      data &&
                      data.pbbFiles &&
                      data.pbbFiles.length !== 0
                    ) {
                      data.pbbFiles.splice(i, 1);
                      await Axios.put(
                        `${SERVER_URL}/pbb-reports/${data._id}`,
                        { pbbFiles: data.pbbFiles },
                        {
                          withCredentials: false,
                          headers: { Authorization: `Bearer ${currentuser.data.token}` },
                        },
                      );
                    }
                  }
                }
                for (let i = 0; i < files.length; i++) {
                  if (files[i].originalName === file.name) {
                    newFiles.splice(i, 1);
                  }
                }
                let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
                if (linkParts && linkParts.length !== 0) {
                  await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {}, {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${currentuser.data.token}` },
                  });
                }
                setFileList(newFileList);
                setFiles(newFiles);
              }}
            >
              <Button id='uploadButton' icon={<UploadOutlined />}>
                Upload File
              </Button>
            </Upload>
          </Form.Item> */}

          {projectManagers && projectManagers.data && projectManagers.data.items && (
            <ProjectDescriptionSheetForm
              data={data}
              files={files}
              setFiles={setFiles}
              form={form}
              options={options}
              projectManagers={projectManagers.data.items}
            />
          )}
          <h3 id='product-selection'>{t('productSelection')}</h3>
          <Divider className='pbbReportDivider' />
          <Row gutter={16} className='pbb-product-selection'>
            <Col xs={24} md={6}>
              <Form.Item name='metaClipProductChecked' valuePropName='checked'>
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaClipProductChecked}
                    onChange={(e) => setMetaClipProductChecked(e.target.checked)}
                  >
                    META CLIP
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaClipCompactProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaClipCompactProductChecked}
                    onChange={(e) => setMetaClipCompactProductChecked(e.target.checked)}
                  >
                    META CLIP COMPACT
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaMiniRackWideSpanRackProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMiniRackWideSpanRackProductChecked}
                    onChange={(e) => setMetaMiniRackWideSpanRackProductChecked(e.target.checked)}
                  >
                    META MINI-RACK
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaMiniSpeedRackWideSpanRackProductChecked' valuePropName='checked'>
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMiniSpeedRackWideSpanRackProductChecked}
                    onChange={(e) => setMetaMiniSpeedRackWideSpanRackProductChecked(e.target.checked)}
                  >
                    META SPEED-RACK
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaMultipalPalletRackProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMultipalPalletRackProductChecked}
                    onChange={(e) => setMetaMultipalPalletRackProductChecked(e.target.checked)}
                  >
                    META MULTIPAL
                  </Checkbox>
                </div>
              </Form.Item>
              {/* <Form.Item name='metaMultifloorProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMultifloorProductChecked}
                    onChange={(e) => setMetaMultifloorProductChecked(e.target.checked)}
                  >
                    META MULTIFLOOR
                  </Checkbox>
                </div>
              </Form.Item> */}
              <Form.Item name='metaMultistrongCantileverProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMultistrongCantileverProductChecked}
                    onChange={(e) => setMetaMultistrongCanteileverProductChecked(e.target.checked)}
                  >
                    META MULTISTRONG
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaMultiTiersProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMultiTiersProductChecked}
                    onChange={(e) => setMetaMultiTiersProductChecked(e.target.checked)}
                  >
                    {t('metaMultiTiers')}
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaMultiblocMobileRackingSystemProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMultiblocMobileRackingSystemProductChecked}
                    onChange={(e) => setMetaMultiblocMobileRackingSystemProductChecked(e.target.checked)}
                  >
                    META Dynamics
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item name='metaMezzanineSteelConstructionProductChecked' valuePropName="checked">
                <div className='pbb-product'>
                  <Checkbox
                    className='pbbCheckbox'
                    checked={metaMezzanineSteelConstructionProductChecked}
                    onChange={(e) => setMetaMezzanineSteelConstructionProductChecked(e.target.checked)}
                  >
                    META Mezzanine
                  </Checkbox>
                </div>
              </Form.Item>
            </Col>
          </Row>
          {metaClipProductChecked &&
            <ClipForm data={data} files={files}
              setFiles={setFiles} form={form} />
          }
          {metaClipCompactProductChecked &&
            <ClipCompactForm data={data} files={files}
              setFiles={setFiles} form={form} />
          }
          {metaMiniRackWideSpanRackProductChecked &&
            <MiniRackForm data={data} files={files}
              setFiles={setFiles} t={t} form={form} />
          }
          {metaMiniSpeedRackWideSpanRackProductChecked &&
            <SpeedRackForm data={data} files={files}
              setFiles={setFiles} t={t} form={form} />
          }
          {metaMultipalPalletRackProductChecked &&
            <MultipalPalletForm data={data} files={files}
              setFiles={setFiles} />
          }
          {metaMultifloorProductChecked &&
            <MultifloorForm data={data} files={files}
              setFiles={setFiles} form={form} />
          }
          {metaMultistrongCantileverProductChecked &&
            <MultistrongForm data={data} files={files}
              setFiles={setFiles} t={t} heightQty={heightQty} setHeightQty={setHeightQty} />
          }
          {metaMultiTiersProductChecked &&
            <FloorSystemForm data={data} files={files} form={form}
              setFiles={setFiles} shelfQty={shelfQty} setShelfQty={setShelfQty} numStairs={numStairs} setNumStairs={setNumStairs} />
          }
          {metaMultiblocMobileRackingSystemProductChecked &&
            <MultiblocMobileForm data={data} files={files}
              setFiles={setFiles} />
          }
          {metaMezzanineSteelConstructionProductChecked &&
            <MezzanineForm data={data} files={files} t={t}
              setFiles={setFiles} />
          }
          {/* <CustomerMasterForm data={data} files={files}
            setFiles={setFiles} countries={options} /> */}
          <InfoSheet />
          {/* <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? t('addPbbReport') : t('save')}
            </Button>
          </div> */}
        </Form>
        {/* {modal.visible && (
          <GalleryModal
            className='gallery-modal'
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/users/'
            imageType='data'
            imageHeight={200}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsert(values, modal.formKey)}
          />
        )} */}
      </div>
    </div>
  );
};

export default withNamespaces()(PbbReportForm);
