import { Button, Card, Checkbox, Divider, Form, Input, Radio, Select, Space, Row, Col } from 'antd';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import i18n from '../../i18n';
import EmbedImage from '../base/EmbedImage';

const ClipForm = ({ data, files, setFiles, form }) => {
  // OVAJ STATE SLUZI SAMO DA BI SE ONCHANGE RE-RENDEROVALA KOMPONENTA JER ZAVISI DA LI JE "specialHeightClip" INPUT REQUIRED
  const [frameHeight, setFrameHeight] = useState(data?.frameHeightClip);
  const [outerFrameClip, setOuterFrameClip] = useState(data && data.outerFrameClip);
  const [innerFrameClip, setInnerFrameClip] = useState(data && data.innerFrameClip);
  const [shelvesSurfaceClip, setShelvesSurfaceClip] = useState(data && data.shelvesSurfaceClip);

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  useEffect(() => {
    if (!form.getFieldValue('shelvesClip')) {
      form.setFieldValue('shelvesClip', [undefined]);
      // form.setFieldsValue({ 'shelvesClip': [undefined] });
    }
  }, []);

  return (
    <div>
      <h3 id='meta-clip'>META Clip</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='clipLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoClip'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('surface')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Card className='pbbCard' title={t('outerFrame')}>
            <Form.Item
              name='outerFrameClip'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Radio.Group onChange={(event) => setOuterFrameClip(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('galvinized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('lightGreyRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('gentianBlueRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {outerFrameClip === 'Special color' && (
                    <Form.Item
                      name='specialColorOuterClip'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card className='pbbCard' title={t('innerFrame')}>
            <Form.Item
              name='innerFrameClip'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Radio.Group onChange={(event) => setInnerFrameClip(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('galvinized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('lightGreyRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('gentianBlueRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {innerFrameClip === 'Special color' && (
                    <Form.Item
                      name='specialColorInnerClip'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card className='pbbCard' title={t('shelves')}>
            <Form.Item
              name='shelvesSurfaceClip'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Radio.Group onChange={(event) => setShelvesSurfaceClip(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('galvinized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('lightGreyRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('gentianBlueRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special color'>
                    {t('metaMultiTiers_specialRALColor')}
                  </Radio>
                  {shelvesSurfaceClip === 'Special color' && (
                    <Form.Item
                      name='specialColorShelvesSurfaceClip'
                      rules={[
                        {
                          required: true,
                          message: t('validationAlert')
                        }
                      ]}>
                      <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                    </Form.Item>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <Form.Item
        name='remarksClip'
        // rules={[{ required: true, message: t('validationAlert') }]}
        label={`${t('remarks')}:`}>
        <Input placeholder={`${t('remarks')}`} />
      </Form.Item>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('frameHeight')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='frameHeightClip'
          // valuePropName='checked'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              { label: i18n.language === 'German' ? '1.850 mm' : '1,850 mm', value: '1850' },
              { label: i18n.language === 'German' ? '2.000 mm' : '2,000 mm', value: '2000' },
              { label: i18n.language === 'German' ? '2.200 mm' : '2,200 mm', value: '2200' },
              { label: i18n.language === 'German' ? '2.500 mm' : '2,500 mm', value: '2500' },
              { label: i18n.language === 'German' ? '3.000 mm' : '3,000 mm', value: '3000' },
              { label: '', value: 'special-height' }
            ]}
            onChange={(value) => {
              const specialHeightInForm = form.getFieldValue('specialHeightClip');
              // AKO SE DESELEKTUJE "special-height" CHECKBOX A PRETHODNO JE UNETA VREDNOST U "special-height" INPUT, BRISE SE specialHeightClip
              // IZ FORM OBJEKTA
              if (specialHeightInForm && !value.includes('special-height')) {
                form.setFieldValue('specialHeightClip', null);
              }
              // AKO POSTOJI VALIDATION ERROR ZA "special-height" INPUT I DESELEKTUJE SE "special-height" CHECKBOX, BRISE SE ERROR
              // JER POLJE VISE NIJE REQUIRED
              if (
                form.getFieldError('specialHeightClip').length > 0 &&
                !value.includes('special-height')
              ) {
                form.setFields([
                  {
                    name: 'specialHeightClip',
                    errors: []
                  }
                ]);
              }
              // SETOVANJE STATE-A DA BI SE IZAZVAO RE-RENDER
              setFrameHeight(value);
            }}
          />
        </Form.Item>
        <Form.Item
          name='specialHeightClip'
          rules={[
            { required: frameHeight?.includes('special-height'), message: t('validationAlert') }
          ]}>
          <Input
            type='number'
            disabled={!frameHeight?.includes('special-height')}
            placeholder={`${t('specialHeight')}:`}
            suffix='mm'
          />
        </Form.Item>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('frameType')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item name='frameTypeClip' rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              { label: t('s3Disassembled'), value: 's3Disassembled' },
              { label: t('openClinched'), value: 'openClinched' },
              { label: t('fullCladFrame'), value: 'fullCladFrame' },
              { label: t('euroHoleWallFrame'), value: 'euroHoleWallFrame' }
            ]}
          />
        </Form.Item>
      </Row>

      <Form.List name='shelvesClip'>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={16}>
              {fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Col xs={24} md={12}>
                    <Divider className='pbbReportDivider' />
                    <Form.Item
                      name={[field.name, 'shelfWidth']}
                      rules={[{ required: true, message: t('validationAlert') }]}
                      label={`${t('shelfWidth')}:`}>
                      <Select placeholder={t('shelfWidth')}>
                        <Select.Option value='750 mm'>750 mm</Select.Option>
                        <Select.Option value='1000 mm'>
                          {i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}
                        </Select.Option>
                        <Select.Option value='1300 mm'>
                          {i18n.language === 'German' ? '1.300 mm' : '1,300 mm'}
                        </Select.Option>
                        <Select.Option value='1500 mm'>
                          {i18n.language === 'German' ? '1.500 mm' : '1,500 mm'}
                        </Select.Option>
                        <Select.Option value='1700 mm'>
                          {i18n.language === 'German' ? '1.700 mm' : '1,700 mm'}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'shelfDepth']}
                      rules={[{ required: true, message: t('validationAlert') }]}
                      label={`${t('shelfDepth')}:`}>
                      <Select placeholder={t('shelfDepth')}>
                        <Select.Option value='300 mm'>300 mm</Select.Option>
                        <Select.Option value='400 mm'>400 mm</Select.Option>
                        <Select.Option value='500 mm'>500 mm</Select.Option>
                        <Select.Option value='600 mm'>600 mm</Select.Option>
                        <Select.Option value='800 mm'>800 mm</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'shelfLoad']}
                      rules={[{ required: true, message: t('validationAlert') }]}
                      label={`${t('shelfLoadBearingCapacity')}:`}>
                      <Select placeholder={t('shelfLoadBearingCapacity')}>
                        <Select.Option value='80 kg'>80 kg</Select.Option>
                        <Select.Option value='100 kg'>100 kg</Select.Option>
                        <Select.Option value='150 kg'>150 kg</Select.Option>
                        <Select.Option value='230 kg'>230 kg</Select.Option>
                        <Select.Option value='330 kg'>330 kg</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'shelfQuantity']}
                      rules={[{ required: true, message: t('validationAlert') }]}
                      label={`${t('quantity')}:`}>
                      <Input type='number' placeholder={t('quantity')} suffix={t('pieces')} />
                    </Form.Item>
                    {fields.length > 1 && (
                      <Row justify='end'>
                        <Col xs={24} md={3}>
                          <Button onClick={() => remove(field.name)}>{t('remove')}</Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={3}>
                <Form.Item>
                  <Button type='primary' style={{ marginBottom: '20px' }} onClick={() => add()}>
                    {t('addMoreBtn')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('reinforcement')}:`}>
            <Form.Item
              name='reinforcementClip'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group>
                <Space direction='vertical'>
                  <Row>
                    <Checkbox value='X-Bracing'>{t('xBracing')}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value='Rear panels'>{t('rearPanels')}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value='Longitudinal bracing beams'>
                      {t('longitudinalBracingBeams')}
                    </Checkbox>
                  </Row>
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('antiTiltSafety')}`}>
            <Form.Item
              name='antiTiltSafetyClip'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group>
                <Space direction='vertical'>
                  <Row>
                    <Checkbox value='Not required'>{t('projDescSheet_notRequired')}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value='Floor fixing'>{t('floorFixing')}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value='Wall fixing'>{t('wallFixing')}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value='X-Bracing'>{t('headBandage')}</Checkbox>
                  </Row>
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <Divider className='pbbReportDivider' />
      <EmbedImage data={data} files={files} setFiles={setFiles} name='clipImage' />
      <Form.Item name='specialClip' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={t('positioning/SpecialFeatures')} />
      </Form.Item>
    </div>
  );
};

export default ClipForm;
