import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, notification, Select } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/EventReportsTable';
import { SERVER_URL } from '../../config';
import EditEventReport from './EditEventReport';
import ExportToExcel from '../../components/csv/ExportExcel';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'date',
  'updatedAt',
  'shelving',
  'palletRacking',
  'cantilever',
  'multibloc',
  'rackInspection',
  'lighting',
  'conveyoring',
  'visitReason',
  'note',
  'leadConsent',
  'leadConsentUpdated',
  'arrangeVisit',
  'newLead',
  'followUp',
  'followUpOther',
  'salesManager',
  'leadImage',
  'jobFunction',
  'name',
  'lastName',
  'street',
  'zipCode',
  'city',
  'phoneNumber',
  'email',
  'dealer',
  'project',
  'metaCustomerCode',
  'createdBy',
  'catalogueType',
  'shelving_interest',
  'shelving_dePrint',
  'shelving_dePrintQty',
  'shelving_dePdf',
  'shelving_dePdfQty',
  'shelving_enPrint',
  'shelving_enPrintQty',
  'shelving_enPdf',
  'shelving_enPdfQty',
  'shelving_frPrint',
  'shelving_frPrintQty',
  'shelving_frPdf',
  'shelving_frPdfQty',
  'shelving_catalogue',
  'palletRacking_interest',
  'palletRacking_dePrint',
  'palletRacking_dePrintQty',
  'palletRacking_dePdf',
  'palletRacking_dePdfQty',
  'palletRacking_enPrint',
  'palletRacking_enPrintQty',
  'palletRacking_enPdf',
  'palletRacking_enPdfQty',
  'palletRacking_frPrint',
  'palletRacking_frPrintQty',
  'palletRacking_frPdf',
  'palletRacking_frPdfQty',
  'palletRacking_catalogue',
  'cantilever_interest',
  'cantilever_dePrint',
  'cantilever_dePrintQty',
  'cantilever_dePdf',
  'cantilever_dePdfQty',
  'cantilever_enPrint',
  'cantilever_enPrintQty',
  'cantilever_enPdf',
  'cantilever_enPdfQty',
  'cantilever_frPrint',
  'cantilever_frPrintQty',
  'cantilever_frPdf',
  'cantilever_frPdfQty',
  'cantilever_catalogue',
  'multibloc_interest',
  'multibloc_dePrint',
  'multibloc_dePrintQty',
  'multibloc_dePdf',
  'multibloc_dePdfQty',
  'multibloc_enPrint',
  'multibloc_enPrintQty',
  'multibloc_enPdf',
  'multibloc_enPdfQty',
  'multibloc_frPrint',
  'multibloc_frPrintQty',
  'multibloc_frPdf',
  'multibloc_frPdfQty',
  'multibloc_catalogue',
  'multibloc_shelving',
  'multibloc_palletRacking',
  'multibloc_cantilever',
  'rackInspection_interest',
  'rackInspection_dePrint',
  'rackInspection_dePrintQty',
  'rackInspection_dePdf',
  'rackInspection_dePdfQty',
  'rackInspection_enPrint',
  'rackInspection_enPrintQty',
  'rackInspection_enPdf',
  'rackInspection_enPdfQty',
  'rackInspection_frPrint',
  'rackInspection_frPrintQty',
  'rackInspection_frPdf',
  'rackInspection_frPdfQty',
  'rackInspection_catalogue',
  'lighting_interest',
  'lighting_dePrint',
  'lighting_dePrintQty',
  'lighting_dePdf',
  'lighting_dePdfQty',
  'lighting_enPrint',
  'lighting_enPrintQty',
  'lighting_enPdf',
  'lighting_enPdfQty',
  'lighting_frPrint',
  'lighting_frPrintQty',
  'lighting_frPdf',
  'lighting_frPdfQty',
  'lighting_catalogue',
  'conveyoring_interest',
  'conveyoring_dePrint',
  'conveyoring_dePrintQty',
  'conveyoring_dePdf',
  'conveyoring_dePdfQty',
  'conveyoring_enPrint',
  'conveyoring_enPrintQty',
  'conveyoring_enPdf',
  'conveyoring_enPdfQty',
  'conveyoring_frPrint',
  'conveyoring_frPrintQty',
  'conveyoring_frPdf',
  'conveyoring_frPdfQty',
  'conveyoring_catalogue',
  'Veranstaltung',
];

const EventReport = ({ t }) => {
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [eventReports, fetchEventReports] = useAxios('', [], currentuser.data.token, 'get');
  const [events, fetchEvents] = useAxios('', [], currentuser.data.token, 'get');
  const [allUsers, fetchAllUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [leadImages, fetchLeadImages] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [dataForExport, setDataForExport] = useState();
  const [exportExcelForEvent, setExportExcelForEvent] = useState();
  const [selectedEvent, setSelectedEvent] = useState('');

  const callbackFunction = (childData) => {
    setDataForExport(childData);
  };

  useEffect(() => {
    fetchEvents(`${SERVER_URL}/events`, []);
    fetchAllUsers(`${SERVER_URL}/users`, []);
    fetchLeadImages(`${SERVER_URL}/images`, []);
  }, [fetchAllUsers, fetchEvents, fetchLeadImages]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (currentuser.data.role.includes('admin') || currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('salesManager') || currentuser.data.role.includes('salesAgent')) {
      fetchEventReports(`${SERVER_URL}/event-reports?filter=${JSON.stringify(params)}`, []);
    }
    // else if (currentuser.data.role.includes('salesManager')) {
    //   let filter = { salesManager: currentuser.data.id };
    //   fetchEventReports(`${SERVER_URL}/event-reports?filter=${JSON.stringify(filter)}`, []);
    // } 
    // else if (currentuser.data.role.includes('salesAgent')) {
    //   let filter = { createdBy: currentuser.data.id };
    //   fetchEventReports(`${SERVER_URL}/event-reports?filter=${JSON.stringify(filter)}`, []);
    // }
  }, [currentuser.data.id, currentuser.data.role, fetchEventReports]);

  const deleteEventReportHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/event-reports/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: t('deleteSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('admin/event-reports');
    } catch (err) {
      notification.error({
        message: t('deleteProblem'),
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (eventReports.data && eventReports.data.items && eventReports.data.items.length > 0) {
    const keys = Object.keys(eventReports.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = eventReports.data.items;
  if (eventReports.data && eventReports.data.items && eventReports.data.items.length > 0) {
    tableData = eventReports.data.items.map((item) => {
      // item.date = new Date(item.date).toLocaleString();
      item.createdAt = moment(item.createdAt).format('MM/DD/YYYY, h:mm:ss A');
      if (item.event && item.event.name) {
        item.event = item.event.name;
      }
      return item;
    });
  }

  const generatePdf = async (eventReportId, userLanguage) => {
    try {
      const response = await Axios.post(`${SERVER_URL}/generate-pdf`, { id: eventReportId, language: userLanguage }, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
          // 'Access-Control-Allow-Origin': ['http://localhost:3000', 'https://dashboard.meta-online.com']
        },
      });
      // const response = await fetch(`${SERVER_URL}/generate-pdf`, {
      //   method: 'POST',
      //   credentials: 'same-origin',
      //   mode: 'cors',
      //   headers: {
      //     'Authorization': `Bearer ${currentuser.data.token}`,
      //     'Content-Type': 'application/json',
      //     // 'Origin': process.env.NODE_ENV === 'production' ? 'https://dashboard.meta-online.com' : 'http://localhost:3000'
      //   },
      //   body: JSON.stringify({
      //     id: eventReportId,
      //     language: userLanguage
      //   })
      // });
      // let responseData = await response.text();
      // responseData = JSON.parse(responseData);
      if (response.data.filename) {
        console.log('file path ', response.data.filename);
        const pdfResponse = await Axios.get(`${SERVER_URL}/get-pdf?filename=${response.data.filename}`, {
          responseType: 'arraybuffer',
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}`, },
        });
        let pathArr = response.data.filename.split('/');
        // if (navigator.userAgent.indexOf("Win") != -1) {
        //   pathArr = response.data.filename.split('\\');
        // }
        // else if (navigator.userAgent.indexOf("Linux") != -1) {
        //   pathArr = response.data.filename.split('/');
        // }
        // else if (navigator.userAgent.indexOf("Mac") != -1) {
        //   pathArr = response.data.filename.split('/');
        // }
        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = pathArr[pathArr.length - 1];
        console.log('file path download ', pathArr[pathArr.length - 1]);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const currentMomentArray = String(moment()._d).split(' ');
  const currentMoment = currentMomentArray[2] + '-' + currentMomentArray[1] + '-' + currentMomentArray[3] + '_' + currentMomentArray[4];

  const handleFilterReset = () => {
    window.history.pushState(null, "", window.location.href.split("?")[0]);
    window.location.reload();
  };

  const emptyTableColumnKeys = ['event', 'company', 'country', 'employee name', 'created at', 'action'];

  const getEventReportsForEvent = async (value) => {
    try {
      setExportExcelForEvent();
      let objectsForExport = [];
      let selectedCatalogues;
      if (value) {
        const filter = { event: value };
        const eventReports = await Axios.get(`${SERVER_URL}/event-reports?filter=${JSON.stringify(filter)}`, {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${currentuser.data.token}`,
          },
        });
        if (eventReports?.data?.items?.length > 0) {
          let eventReportsID = eventReports.data?.items?.map((item) => item._id);
          let filter = { eventReport: { $in: eventReportsID } };
          selectedCatalogues = await Axios.post(`${SERVER_URL}/catalogues-by-event`, { filter: JSON.stringify(filter) },
            {
              withCredentials: false,
              headers: {
                Authorization: `Bearer ${currentuser.data.token}`,
              },
            }
          );
        }
        eventReports.data?.items?.filter(item => {
          let normalizedObject = {
            _id: '',
            leadConsent: '',
            event: '',
            company: '',
            name: '',
            lastName: '',
            jobFunction: '',
            street: '',
            zipCode: '',
            city: '',
            country: '',
            phoneNumber: '',
            email: '',
            date: '',
            newLead: '',
            dealer: '',
            project: '',
            metaCustomerCode: '',
            catalogueType: '',
            visitReason: '',
            followUp: '',
            arrangeVisit: '',
            leadConsentUpdated: '',
            note: '',
            leadImage: '',
            employeeName: '',
            salesManager: '',
            createdBy: '',
            createdAt: '',
            updatedAt: '',
            __v: '',
            shelving: '',
            shelving_dePrintQty: '',
            shelving_dePdfQty: '',
            shelving_enPrintQty: '',
            shelving_enPdfQty: '',
            shelving_frPrintQty: '',
            shelving_frPdfQty: '',
            shelving_nlPrintQty: '',
            shelving_nlPdfQty: '',
            shelving_dEnPrintQty: '',
            shelving_dEnPdfQty: '',
            shelving_esPrintQty: '',
            shelving_esPdfQty: '',
            shelving_plPrintQty: '',
            shelving_plPdfQty: '',
            shelving_sePrintQty: '',
            shelving_sePdfQty: '',
            shelving_dkPrintQty: '',
            shelving_dkPdfQty: '',
            cantilever: '',
            cantilever_dePrintQty: '',
            cantilever_dePdfQty: '',
            cantilever_enPrintQty: '',
            cantilever_enPdfQty: '',
            cantilever_frPrintQty: '',
            cantilever_frPdfQty: '',
            cantilever_nlPrintQty: '',
            cantilever_nlPdfQty: '',
            cantilever_dEnPrintQty: '',
            cantilever_dEnPdfQty: '',
            cantilever_esPrintQty: '',
            cantilever_esPdfQty: '',
            cantilever_plPrintQty: '',
            cantilever_plPdfQty: '',
            cantilever_sePrintQty: '',
            cantilever_sePdfQty: '',
            cantilever_dkPrintQty: '',
            cantilever_dkPdfQty: '',
            palletRacking: '',
            palletRacking_dePrintQty: '',
            palletRacking_dePdfQty: '',
            palletRacking_enPrintQty: '',
            palletRacking_enPdfQty: '',
            palletRacking_frPrintQty: '',
            palletRacking_frPdfQty: '',
            palletRacking_nlPrintQty: '',
            palletRacking_nlPdfQty: '',
            palletRacking_dEnPrintQty: '',
            palletRacking_dEnPdfQty: '',
            palletRacking_esPrintQty: '',
            palletRacking_esPdfQty: '',
            palletRacking_plPrintQty: '',
            palletRacking_plPdfQty: '',
            palletRacking_sePrintQty: '',
            palletRacking_sePdfQty: '',
            palletRacking_dkPrintQty: '',
            palletRacking_dkPdfQty: '',
            multibloc: '',
            multibloc_dePrintQty: '',
            multibloc_dePdfQty: '',
            multibloc_enPrintQty: '',
            multibloc_enPdfQty: '',
            multibloc_frPrintQty: '',
            multibloc_frPdfQty: '',
            multibloc_nlPrintQty: '',
            multibloc_nlPdfQty: '',
            multibloc_dEnPrintQty: '',
            multibloc_dEnPdfQty: '',
            multibloc_esPrintQty: '',
            multibloc_esPdfQty: '',
            multibloc_plPrintQty: '',
            multibloc_plPdfQty: '',
            multibloc_sePrintQty: '',
            multibloc_sePdfQty: '',
            multibloc_dkPrintQty: '',
            multibloc_dkPdfQty: '',
            rackInspection: '',
            rackInspection_dePrintQty: '',
            rackInspection_dePdfQty: '',
            rackInspection_enPrintQty: '',
            rackInspection_enPdfQty: '',
            rackInspection_frPrintQty: '',
            rackInspection_frPdfQty: '',
            rackInspection_nlPrintQty: '',
            rackInspection_nlPdfQty: '',
            rackInspection_dEnPrintQty: '',
            rackInspection_dEnPdfQty: '',
            rackInspection_esPrintQty: '',
            rackInspection_esPdfQty: '',
            rackInspection_plPrintQty: '',
            rackInspection_plPdfQty: '',
            rackInspection_sePrintQty: '',
            rackInspection_sePdfQty: '',
            rackInspection_dkPrintQty: '',
            rackInspection_dkPdfQty: '',
            lighting: '',
            lighting_dePrintQty: '',
            lighting_dePdfQty: '',
            lighting_enPrintQty: '',
            lighting_enPdfQty: '',
            lighting_frPrintQty: '',
            lighting_frPdfQty: '',
            lighting_nlPrintQty: '',
            lighting_nlPdfQty: '',
            lighting_dEnPrintQty: '',
            lighting_dEnPdfQty: '',
            lighting_esPrintQty: '',
            lighting_esPdfQty: '',
            lighting_plPrintQty: '',
            lighting_plPdfQty: '',
            lighting_sePrintQty: '',
            lighting_sePdfQty: '',
            lighting_dkPrintQty: '',
            lighting_dkPdfQty: '',
            conveyoring: '',
            conveyoring_dePrintQty: '',
            conveyoring_dePdfQty: '',
            conveyoring_enPrintQty: '',
            conveyoring_enPdfQty: '',
            conveyoring_frPrintQty: '',
            conveyoring_frPdfQty: '',
            conveyoring_nlPrintQty: '',
            conveyoring_nlPdfQty: '',
            conveyoring_dEnPrintQty: '',
            conveyoring_dEnPdfQty: '',
            conveyoring_esPrintQty: '',
            conveyoring_esPdfQty: '',
            conveyoring_plPrintQty: '',
            conveyoring_plPdfQty: '',
            conveyoring_sePrintQty: '',
            conveyoring_sePdfQty: '',
            conveyoring_dkPrintQty: '',
            conveyoring_dkPdfQty: '',
          };
          Object.keys(item).map(eventReportProp => {
            let followUpOtherValue;
            if (item.followUpOther) {
              followUpOtherValue = item.followUpOther;
            }
            if (eventReportProp === 'leadImage' && leadImages && leadImages.data) {
              for (let i = 0; i < leadImages.data.length; i++) {
                if (leadImages.data[i]._id === item[eventReportProp]) {
                  item[eventReportProp] = SERVER_URL + leadImages.data[i].url;
                }
              }
            }
            if (eventReportProp === 'catalogueType' || eventReportProp === 'visitReason' || eventReportProp === 'followUp') {
              item[eventReportProp] = t(item[eventReportProp]);
            }
            if (eventReportProp === 'leadConsentUpdated' || eventReportProp === 'createdAt') {
              item[eventReportProp] = moment(new Date(item[eventReportProp])).format('MM/DD/YYYY HH:MM:SS');
            }
            if (typeof item[eventReportProp] === 'boolean') {
              if (item[eventReportProp] === false) {
                item[eventReportProp] = t('no');
              }
              else {
                item[eventReportProp] = t('yes');
              }
            }
            if (eventReportProp === 'salesManager' && allUsers && allUsers.data && allUsers.data.items) {
              for (let i = 0; i < allUsers.data.items.length; i++) {
                if (allUsers.data.items[i]._id === item[eventReportProp]) {
                  item[eventReportProp] = allUsers.data.items[i].firstName + ' ' + allUsers.data.items[i].lastName;
                }
              }
            }
            if (eventReportProp === 'createdBy' && allUsers && allUsers.data && allUsers.data.items) {
              for (let i = 0; i < allUsers.data.items.length; i++) {
                if (allUsers.data.items[i]._id === item[eventReportProp]) {
                  item[eventReportProp] = allUsers.data.items[i].firstName + ' ' + allUsers.data.items[i].lastName;
                }
              }
            }
            if (eventReportProp === 'followUp' && (item[eventReportProp] === 'Other' || item[eventReportProp] === 'Sonstige')) {
              if (followUpOtherValue) {
                item[eventReportProp] = item[eventReportProp] + ' (' + followUpOtherValue + ')';
              }
            }
          });
          Object.keys(item).map(prop => {
            if (prop === 'event') {
              normalizedObject[prop] = item[prop].name;
            } else {
              normalizedObject[prop] = item[prop];
            }
          });
          objectsForExport.push(normalizedObject);
        });
        objectsForExport.map(item => {
          if (item._id.length < 1) {
            objectsForExport.splice(objectsForExport.indexOf(item), 1);
          }
        });

        if (selectedCatalogues && selectedCatalogues.data && selectedCatalogues.data.items) {
          let catalogueQty = {};
          const cataloguesByER = {};
          objectsForExport.forEach((item) => {
            selectedCatalogues.data.items.forEach((catalogue) => {
              if (item._id === catalogue.eventReport) {
                if (catalogue.dePrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePrintQty'] += catalogue.dePrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePrintQty'] = catalogue.dePrintQty;
                  }
                }
                if (catalogue.dePdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePdfQty'] += catalogue.dePdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePdfQty'] = catalogue.dePdfQty;
                  }
                }
                if (catalogue.enPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPrintQty'] += catalogue.enPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPrintQty'] = catalogue.enPrintQty;
                  }
                }
                if (catalogue.enPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPdfQty'] += catalogue.enPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPdfQty'] = catalogue.enPdfQty;
                  }
                }
                if (catalogue.frPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPrintQty'] += catalogue.frPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPrintQty'] = catalogue.frPrintQty;
                  }
                }
                if (catalogue.frPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPdfQty'] += catalogue.frPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPdfQty'] = catalogue.frPdfQty;
                  }
                }
                if (catalogue.nlPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPrintQty'] += catalogue.nlPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPrintQty'] = catalogue.nlPrintQty;
                  }
                }
                if (catalogue.nlPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPdfQty'] += catalogue.nlPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPdfQty'] = catalogue.nlPdfQty;
                  }
                }
                if (catalogue.dEnPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPrintQty'] += catalogue.dEnPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPrintQty'] = catalogue.dEnPrintQty;
                  }
                }
                if (catalogue.dEnPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPdfQty'] += catalogue.dEnPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPdfQty'] = catalogue.dEnPdfQty;
                  }
                }
                if (catalogue.esPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPrintQty'] += catalogue.esPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPrintQty'] = catalogue.esPrintQty;
                  }
                }
                if (catalogue.esPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPdfQty'] += catalogue.esPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPdfQty'] = catalogue.esPdfQty;
                  }
                }
                if (catalogue.plPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPrintQty'] += catalogue.plPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPrintQty'] = catalogue.plPrintQty;
                  }
                }
                if (catalogue.plPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPdfQty'] += catalogue.plPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPdfQty'] = catalogue.plPdfQty;
                  }
                }
                if (catalogue.sePrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePrintQty'] += catalogue.sePrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePrintQty'] = catalogue.sePrintQty;
                  }
                }
                if (catalogue.sePdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePdfQty'] += catalogue.sePdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePdfQty'] = catalogue.sePdfQty;
                  }
                }
                if (catalogue.dkPrint) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPrintQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPrintQty'] += catalogue.dkPrintQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPrintQty'] = catalogue.dkPrintQty;
                  }
                }
                if (catalogue.dkPdf) {
                  if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPdfQty']) {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPdfQty'] += catalogue.dkPdfQty;
                  } else {
                    catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPdfQty'] = catalogue.dkPdfQty;
                  }
                }
              }
            });
            cataloguesByER[item._id] = { ...catalogueQty };
            catalogueQty = {};
          });
          objectsForExport.forEach((item) => {
            Object.keys(item).map(prop => {
              if (cataloguesByER[item._id][prop]) {
                item[prop] = cataloguesByER[item._id][prop];
              }
            });
          });
          objectsForExport.map(item => {
            if (item._id.length < 1) {
              objectsForExport.splice(objectsForExport.indexOf(item), 1);
            }
          });
          setExportExcelForEvent(objectsForExport);
          objectsForExport = [];

        } else if (selectedCatalogues && selectedCatalogues.data &&
          selectedCatalogues.data.items && selectedCatalogues.data.items.length === 0 && objectsForExport.length !== 0) {
          setExportExcelForEvent(objectsForExport);
        }
      }
    }
    catch (error) {
      notification.error({
        message: error.message,
        placement: 'bottomRight',
      });
      console.log('error: ', error);
    }
  };

  return (
    <div className='table event-reports-table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        {(currentuser.data.role.includes('admin') || currentuser.data.role.includes('salesManager') || currentuser.data.role.includes('salesAgent') || currentuser.data.role.includes('superAdmin')) &&
          <Button
            onClick={() => setShowModal(!showModal)}
            type='primary'
            disabled={events.data.count < 1}
            style={events.data.count < 1 ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
          >
            {t('addEventReport')}
          </Button>
        }
        {(currentuser.data.role.includes('admin') || currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('salesManager')) &&
          <ExportToExcel
            csvData={dataForExport}
            fileName={'event_report_summary ' + currentMoment}
            propsToRemove={['_id', 'updatedAt', '__v', 'note', 'employeeName', 'date', 'shelving', 'palletRacking', 'cantilever', 'multibloc', 'rackInspection', 'lighting', 'conveyoring']}
            disabled={dataForExport && (Object.keys(dataForExport).length > 0) ? false : true}
            // header
            format='custom_name'
          />
        }
        <Select
          placeholder='Select Event for export'
          getPopupContainer={trigger => trigger.parentNode}
          style={{ marginLeft: '3rem' }}
          onChange={(value) => {
            setSelectedEvent(events?.data?.items?.filter((item) => item._id === value)[0]?.name);
            getEventReportsForEvent(value);
          }}
          allowClear
        >
          {events.data && events.data.items && events.data.items.map((event) =>
            <Select.Option key={event._id} value={event._id}>{event.name}</Select.Option>)}
        </Select>
        {(currentuser.data.role.includes('admin') || currentuser.data.role.includes('superAdmin') || currentuser.data.role.includes('salesManager')) &&
          <ExportToExcel
            csvData={exportExcelForEvent}
            fileName={'event_report_summary_' + selectedEvent + ' ' + currentMoment}
            propsToRemove={['_id', 'updatedAt', '__v', 'note', 'employeeName', 'date', 'shelving', 'palletRacking', 'cantilever', 'multibloc', 'rackInspection', 'lighting', 'conveyoring']}
            disabled={exportExcelForEvent && (Object.keys(exportExcelForEvent).length > 0) ? false : true}
            // header
            format='custom_name'
            title='Create XLSX Report'
          />
        }
        <div className='reset-filter-action'>
          <Button onClick={handleFilterReset}><CloseCircleOutlined /> {t('resetFilters')}</Button>
        </div>
      </div>
      {
        !eventReports.isLoading && eventReports.data && eventReports.data.items && eventReports.data.items.length > 0 && (
          <div style={{ textAlign: 'center' }}>
            {eventReports.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
            {!eventReports.isLoading && eventReports.data && eventReports.data.items && eventReports.data.items.length > 0 && (
              <Table
                data={tableData}
                deleteHandler={deleteEventReportHandler}
                columnKeys={columnKeys}
                title='Event Report'
                editPath='/admin/edit-event-report/'
                viewPath='/admin/view-event-report/'
                callbackCSV={callbackFunction}
                generatePdf={generatePdf}
              />
            )}
          </div>
        )
      }
      {
        !eventReports.isLoading && eventReports.data && eventReports.data.items && eventReports.data.items.length < 1 && (
          <Table
            data={null}
            deleteHandler={deleteEventReportHandler}
            columnKeys={emptyTableColumnKeys}
            title='Event Report'
            editPath='/admin/edit-event-report/'
            viewPath='/admin/view-event-report/'
            callbackCSV={callbackFunction}
            generatePdf={generatePdf}
          />
        )
      }
      <EditEventReport showModal={showModal} setShowModal={setShowModal} />
    </div >
  );
};

export default withNamespaces()(EventReport);