import React, { useState, useEffect, useContext, useCallback } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Button, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import PbbReportForm from '../../components/forms/PbbReportForm';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const EditPbbReport = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [logs, setLogs] = useState([]);
  const [pbbReport, setPbbReport] = useState({});
  const history = useHistory();
  let pbbReportId = props.match.params.id;
  const draftId = history.location.search.split('=')[1];
  if (draftId) {
    pbbReportId = draftId;
  }

  const getPbbReport = useCallback(async () => {
    const url = draftId ? `draft/${draftId}` : `pbb-reports/${pbbReportId}`;
    try {
      const data = await Axios.get(`${SERVER_URL}/${url}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setPbbReport(data);
    } catch (error) {
      console.log(error.message);
    }
  }, [currentuser.data.token, pbbReportId, draftId]);

  useEffect(() => {
    if (pbbReportId) {
      getPbbReport();
    }
  }, [pbbReportId, getPbbReport]);

  const updatePbbReport = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/pbb-reports/${pbbReportId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      if (currentuser.data.role.includes('internalUser') || currentuser.data.role.includes('externalUser')) {
        await Axios.post(`${SERVER_URL}/email-pbb`, { customerCountry: data.customerCountry, isNew: false, id: pbbReportId },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      }
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/pbb-reports');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const createPbbReport = async (data) => {
    const values = { ...data, draftId };
    try {
      const res = await Axios.post(
        `${SERVER_URL}/pbb-reports`,
        values,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      if (currentuser.data.role.includes('internalUser') || currentuser.data.role.includes('externalUser')) {
        await Axios.post(`${SERVER_URL}/email-pbb`, { customerCountry: data.customerCountry, isNew: true, id: res.data.data._id },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      }
      notification.success({
        message: t('createdSuccessfully'),
        placement: 'bottomRight',
      });
      history.push('/admin/pbb-reports');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  // const getActivityLog = async () => {
  //   try {
  //     const { data } = await Axios.get(`${SERVER_URL}/activity/${pbbReportId}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
  //     setLogs(data);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  // useEffect(() => {
  //   if (open) {
  //     getActivityLog();
  //   }
  // }, [open]);

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <div className='dashboard'>
      {pbbReportId ?
        <div className='actions-block' style={{ marginBottom: '8px', justifyContent: "space-between" }}>
          <Button onClick={() => window.location.href = '/admin/pbb-reports'} type='primary'>
            {t('pbbReports')}
          </Button>
          {/* <Button onClick={() => setOpen(true)} type='primary'>
            Activity Log
          </Button> */}
        </div>
        :
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Button onClick={() => window.location.href = '/admin/pbb-reports'} type='primary'>
            {t('pbbReports')}
          </Button>
        </div>
      }
      <div style={{ textAlign: 'center' }}>
        {pbbReportId && pbbReport.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {pbbReportId && !pbbReport.isLoading && !pbbReport.isError && pbbReport.data && (
          <PbbReportForm
            isNew={false}
            draftId={draftId}
            data={pbbReport.data}
            createHandler={createPbbReport}
            updateHandler={updatePbbReport}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {pbbReportId && !pbbReport.isLoading && pbbReport.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!pbbReportId && pbbReport && !pbbReport.data && (
          <PbbReportForm
            isNew={true}
            data={null}
            createHandler={createPbbReport}
            result={result}
            setResult={setResult}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
      {/* {open &&
        <Modal
          title="Activity Log"
          centered
          visible={open}
          footer={null}
          onCancel={() => setOpen(false)}
        >
          <div style={{ padding: '1rem 0', height: '20rem', overflowY: 'auto' }}>
            {!logs.length ?
              <p style={{ textAlign: 'center', color: 'gray' }}>No Activity Logs</p>
              :
              <>
                {logs?.map(log => (
                  <p>{log.user.firstName} {log.user.lastName} {log.action === 'CREATE' ? 'created' : 'edited'} ({new Date(log.createdAt).toLocaleString().replace(',', '').substring(0, 15)})</p>
                ))}
              </>
            }
          </div>
        </Modal>
      } */}
    </div>
  );
};

export default withNamespaces()(EditPbbReport);
