import React, { useContext, useEffect, useState } from 'react';
import { Table, Input, Button, Popconfirm } from 'antd';
import { DeleteFilled, DeleteOutlined, EditFilled, EditOutlined, EyeFilled, EyeOutlined, SearchOutlined, FilePdfOutlined, FilePdfFilled, ConsoleSqlOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import EditEventReport from '../../pages/eventReport/EditEventReport';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';
import EventReport from '../../pages/eventReport/EventReport';
import i18n from '../../i18n';

const EventReportsTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, t, callbackCSV, generatePdf }) => {
  const user = useContext(UserContext);
  let searchInput;
  const [eventReportID, setEventReportID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  let [exportArray, setExportArray] = useState([]);

  //For Excel Export
  const [allUsers, fetchAllUsers] = useAxios('', [], user.data.token, 'get');
  const [leadImages, fetchLeadImages] = useAxios('', [], user.data.token, 'get');
  const [selectedCatalogues, fetchSelectedCatalogues] = useAxios('', [], user.data.token, 'get');


  useEffect(() => {
    fetchAllUsers(`${SERVER_URL}/users`, []);
  }, [fetchAllUsers]);

  useEffect(() => {
    fetchLeadImages(`${SERVER_URL}/images`, []);
  }, [fetchLeadImages]);

  useEffect(() => {
    if (selectedRowKeys) {
      let filter = { eventReport: { $in: selectedRowKeys } };
      fetchSelectedCatalogues(`${SERVER_URL}/selected-catalogue?filter=${JSON.stringify(filter)}`, []);
    }
  }, [selectedRowKeys, fetchSelectedCatalogues]);
  //END For Excel Export

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex.replace(/([a-z])([A-Z])/g, '$1 $2')}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            {t('search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            {t('reset')}
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? item === 'title'
            ? [item, user.language ? user.language.selected.code : '']
            : [item, 'name', user.language ? user.language.selected.code : '']
          : item,
    ),
    // filters: themes.data?.items && themes.data.items.length > 0 && themes.data.items.map(theme => {
    //   return {
    //     text: theme.name,
    //     value: theme._id
    //   };
    // }),
    // onFilter: (value, record) => record.theme && record.theme._id === value,
  }));

  const onEditClick = (ID) => {
    setEventReportID(ID);
    setShowModal(!showModal);
  };

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {(user.data.role.includes('admin') || user.data.role.includes('superAdmin') || user.data.role.includes('salesManager') || user.data.role.includes('salesAgent')) && <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <EditOutlined
            className='icon-unlock'
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
          <EditFilled
            className='icon-lock'
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
        </div>}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeFilled
                className='icon-lock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <FilePdfOutlined
              className='icon-unlock'
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => generatePdf(record._id, localStorage.language || i18n.language)}
            />
            <FilePdfFilled
              className='icon-lock'
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => generatePdf(record._id, localStorage.language || i18n.language)}
            />
            {/* <EditOutlined
              className='icon-unlock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => onEditClick(record._id)}
            />
            <EditFilled
              className='icon-lock'
              title={`Edit ${title.toLowerCase()}`}
              style={{ textDecoration: 'none', color: 'black' }}
              onClick={() => onEditClick(record._id)}
            /> */}
          </div>
        </div>

        {(user.data.role.includes('admin') || user.data.role.includes('superAdmin') || user.data.role.includes('salesManager') || user.data.role.includes('salesAgent')) && <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <Popconfirm
            disabled={record._id === user.data.id}
            placement='left'
            title={t('deleteConfirm')}
            onConfirm={() => {
              deleteHandler(record._id);
            }}
            okText={t('yes')}
            cancelText={t('no')}
          >
            <DeleteOutlined className='icon-unlock' title={`Delete ${title.toLowerCase()}`} />
            <DeleteFilled className='icon-lock' title={`Delete ${title.toLowerCase()}`} />
          </Popconfirm>
        </div>}
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  let objectsForExport = [];

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
    data.filter(item => {
      let normalizedObject = {
        _id: '',
        leadConsent: '',
        event: '',
        company: '',
        name: '',
        lastName: '',
        jobFunction: '',
        street: '',
        zipCode: '',
        city: '',
        country: '',
        phoneNumber: '',
        email: '',
        date: '',
        newLead: '',
        dealer: '',
        project: '',
        metaCustomerCode: '',
        catalogueType: '',
        visitReason: '',
        followUp: '',
        arrangeVisit: '',
        leadConsentUpdated: '',
        note: '',
        leadImage: '',
        employeeName: '',
        salesManager: '',
        createdBy: '',
        createdAt: '',
        updatedAt: '',
        __v: '',
        shelving: '',
        // shelving_dePrint: '',
        shelving_dePrintQty: '',
        // shelving_dePdf: '',
        shelving_dePdfQty: '',
        // shelving_enPrint: '',
        shelving_enPrintQty: '',
        // shelving_enPdf: '',
        shelving_enPdfQty: '',
        // shelving_frPrint: '',
        shelving_frPrintQty: '',
        // shelving_frPdf: '',
        shelving_frPdfQty: '',
        shelving_nlPrintQty: '',
        shelving_nlPdfQty: '',
        shelving_dEnPrintQty: '',
        shelving_dEnPdfQty: '',
        shelving_esPrintQty: '',
        shelving_esPdfQty: '',
        shelving_plPrintQty: '',
        shelving_plPdfQty: '',
        shelving_sePrintQty: '',
        shelving_sePdfQty: '',
        shelving_dkPrintQty: '',
        shelving_dkPdfQty: '',
        cantilever: '',
        // cantilever_dePrint: '',
        cantilever_dePrintQty: '',
        // cantilever_dePdf: '',
        cantilever_dePdfQty: '',
        // cantilever_enPrint: '',
        cantilever_enPrintQty: '',
        // cantilever_enPdf: '',
        cantilever_enPdfQty: '',
        // cantilever_frPrint: '',
        cantilever_frPrintQty: '',
        // cantilever_frPdf: '',
        cantilever_frPdfQty: '',
        cantilever_nlPrintQty: '',
        cantilever_nlPdfQty: '',
        cantilever_dEnPrintQty: '',
        cantilever_dEnPdfQty: '',
        cantilever_esPrintQty: '',
        cantilever_esPdfQty: '',
        cantilever_plPrintQty: '',
        cantilever_plPdfQty: '',
        cantilever_sePrintQty: '',
        cantilever_sePdfQty: '',
        cantilever_dkPrintQty: '',
        cantilever_dkPdfQty: '',
        palletRacking: '',
        // palletRacking_dePrint: '',
        palletRacking_dePrintQty: '',
        // palletRacking_dePdf: '',
        palletRacking_dePdfQty: '',
        // palletRacking_enPrint: '',
        palletRacking_enPrintQty: '',
        // palletRacking_enPdf: '',
        palletRacking_enPdfQty: '',
        // palletRacking_frPrint: '',
        palletRacking_frPrintQty: '',
        // palletRacking_frPdf: '',
        palletRacking_frPdfQty: '',
        palletRacking_nlPrintQty: '',
        palletRacking_nlPdfQty: '',
        palletRacking_dEnPrintQty: '',
        palletRacking_dEnPdfQty: '',
        palletRacking_esPrintQty: '',
        palletRacking_esPdfQty: '',
        palletRacking_plPrintQty: '',
        palletRacking_plPdfQty: '',
        palletRacking_sePrintQty: '',
        palletRacking_sePdfQty: '',
        palletRacking_dkPrintQty: '',
        palletRacking_dkPdfQty: '',
        multibloc: '',
        // multibloc_dePrint: '',
        multibloc_dePrintQty: '',
        // multibloc_dePdf: '',
        multibloc_dePdfQty: '',
        // multibloc_enPrint: '',
        multibloc_enPrintQty: '',
        // multibloc_enPdf: '',
        multibloc_enPdfQty: '',
        // multibloc_frPrint: '',
        multibloc_frPrintQty: '',
        multibloc_frPdfQty: '',
        multibloc_nlPrintQty: '',
        multibloc_nlPdfQty: '',
        multibloc_dEnPrintQty: '',
        multibloc_dEnPdfQty: '',
        multibloc_esPrintQty: '',
        multibloc_esPdfQty: '',
        multibloc_plPrintQty: '',
        multibloc_plPdfQty: '',
        multibloc_sePrintQty: '',
        multibloc_sePdfQty: '',
        multibloc_dkPrintQty: '',
        multibloc_dkPdfQty: '',
        rackInspection: '',
        // rackInspection_dePrint: '',
        rackInspection_dePrintQty: '',
        // rackInspection_dePdf: '',
        rackInspection_dePdfQty: '',
        // rackInspection_enPrint: '',
        rackInspection_enPrintQty: '',
        // rackInspection_enPdf: '',
        rackInspection_enPdfQty: '',
        // rackInspection_frPrint: '',
        rackInspection_frPrintQty: '',
        rackInspection_frPdfQty: '',
        rackInspection_nlPrintQty: '',
        rackInspection_nlPdfQty: '',
        rackInspection_dEnPrintQty: '',
        rackInspection_dEnPdfQty: '',
        rackInspection_esPrintQty: '',
        rackInspection_esPdfQty: '',
        rackInspection_plPrintQty: '',
        rackInspection_plPdfQty: '',
        rackInspection_sePrintQty: '',
        rackInspection_sePdfQty: '',
        rackInspection_dkPrintQty: '',
        rackInspection_dkPdfQty: '',
        lighting: '',
        // lighting_dePrint: '',
        lighting_dePrintQty: '',
        // lighting_dePdf: '',
        lighting_dePdfQty: '',
        // lighting_enPrint: '',
        lighting_enPrintQty: '',
        // lighting_enPdf: '',
        lighting_enPdfQty: '',
        // lighting_frPrint: '',
        lighting_frPrintQty: '',
        lighting_frPdfQty: '',
        lighting_nlPrintQty: '',
        lighting_nlPdfQty: '',
        lighting_dEnPrintQty: '',
        lighting_dEnPdfQty: '',
        lighting_esPrintQty: '',
        lighting_esPdfQty: '',
        lighting_plPrintQty: '',
        lighting_plPdfQty: '',
        lighting_sePrintQty: '',
        lighting_sePdfQty: '',
        lighting_dkPrintQty: '',
        lighting_dkPdfQty: '',
        conveyoring: '',
        // conveyoring_dePrint: '',
        conveyoring_dePrintQty: '',
        // conveyoring_dePdf: '',
        conveyoring_dePdfQty: '',
        // conveyoring_enPrint: '',
        conveyoring_enPrintQty: '',
        // conveyoring_enPdf: '',
        conveyoring_enPdfQty: '',
        // conveyoring_frPrint: '',
        conveyoring_frPrintQty: '',
        conveyoring_frPdfQty: '',
        conveyoring_nlPrintQty: '',
        conveyoring_nlPdfQty: '',
        conveyoring_dEnPrintQty: '',
        conveyoring_dEnPdfQty: '',
        conveyoring_esPrintQty: '',
        conveyoring_esPdfQty: '',
        conveyoring_plPrintQty: '',
        conveyoring_plPdfQty: '',
        conveyoring_sePrintQty: '',
        conveyoring_sePdfQty: '',
        conveyoring_dkPrintQty: '',
        conveyoring_dkPdfQty: '',
      };
      for (let i = 0; i < selectedRowKeys.length; i++) {
        if (item._id === selectedRowKeys[i]) {
          //Map through Event Report props
          Object.keys(item).map(eventReportProp => {
            let followUpOtherValue;
            if (item.followUpOther) {
              followUpOtherValue = item.followUpOther;
            }
            // Map its children if the prop is an object
            // if (typeof item[eventReportProp] === 'object') {
            //   Object.keys(item[eventReportProp]).map(propNameOfEventReportProp => {
            //     let propNameOfEventReportPropChanged = propNameOfEventReportProp;
            //     // Prevent prefix from adding more than once
            //     if (!propNameOfEventReportProp.includes('_')) {
            //       propNameOfEventReportPropChanged = eventReportProp + '_' + propNameOfEventReportProp;
            //     }
            //     // Write true/false in cell IF the data is a boolean value, ELSE write it as it is
            //     if (typeof item[eventReportProp][propNameOfEventReportProp] === 'boolean') {
            //       if (item[eventReportProp][propNameOfEventReportProp] === false) {
            //         item[eventReportProp][[propNameOfEventReportPropChanged]] = '';
            //       }
            //       else {
            //         item[eventReportProp][[propNameOfEventReportPropChanged]] = t('yes');
            //       }
            //       // item[eventReportProp][propNameOfEventReportPropChanged] = t(String(item[eventReportProp][propNameOfEventReportProp]));
            //     }
            //     else {
            //       item[eventReportProp][propNameOfEventReportPropChanged] = item[eventReportProp][propNameOfEventReportProp];
            //     }
            //     // Remove the old column name without prefix
            //     delete item[eventReportProp][propNameOfEventReportProp];
            //     // Add the properties of the interest child object to the Event Report object
            //     item[propNameOfEventReportPropChanged] = item[eventReportProp][propNameOfEventReportPropChanged];
            //   });
            // }
            if (eventReportProp === 'leadImage' && leadImages && leadImages.data) {
              for (let i = 0; i < leadImages.data.length; i++) {
                if (leadImages.data[i]._id === item[eventReportProp]) {
                  item[eventReportProp] = SERVER_URL + leadImages.data[i].url;
                }
              }
            }
            if (eventReportProp === 'catalogueType' || eventReportProp === 'visitReason' || eventReportProp === 'followUp') {
              item[eventReportProp] = t(item[eventReportProp]);
            }
            if (eventReportProp === 'leadConsentUpdated' || eventReportProp === 'createdAt') {
              item[eventReportProp] = moment(new Date(item[eventReportProp])).format('MM/DD/YYYY HH:MM:SS');
            }
            if (typeof item[eventReportProp] === 'boolean') {
              if (item[eventReportProp] === false) {
                item[eventReportProp] = t('no');
              }
              else {
                item[eventReportProp] = t('yes');
              }
            }
            if (eventReportProp === 'salesManager' && allUsers && allUsers.data && allUsers.data.items) {
              for (let i = 0; i < allUsers.data.items.length; i++) {
                if (allUsers.data.items[i]._id === item[eventReportProp]) {
                  item[eventReportProp] = allUsers.data.items[i].firstName + ' ' + allUsers.data.items[i].lastName;
                }
              }
            }
            if (eventReportProp === 'createdBy' && allUsers && allUsers.data && allUsers.data.items) {
              for (let i = 0; i < allUsers.data.items.length; i++) {
                if (allUsers.data.items[i]._id === item[eventReportProp]) {
                  item[eventReportProp] = allUsers.data.items[i].firstName + ' ' + allUsers.data.items[i].lastName;
                }
              }
            }
            if (eventReportProp === 'followUp' && item[eventReportProp] === 'Other' || item[eventReportProp] === 'Sonstige') {
              if (followUpOtherValue) {
                item[eventReportProp] = item[eventReportProp] + ' (' + followUpOtherValue + ')';
              }
            }
          });
          Object.keys(item).map(prop => {
            normalizedObject[prop] = item[prop];
          });
        }
      }
      objectsForExport.push(normalizedObject);
    });
    objectsForExport.map(item => {
      if (item._id.length < 1) {
        objectsForExport.splice(objectsForExport.indexOf(item), 1);
      }
    });
    setExportArray(objectsForExport);

  };

  if (selectedCatalogues && selectedCatalogues.data && selectedCatalogues.data.items && selectedCatalogues.data.items.length !== 0) {
    objectsForExport = exportArray;
    let catalogueQty = {};
    const cataloguesByER = {};
    objectsForExport.forEach((item) => {
      selectedCatalogues.data.items.forEach((catalogue) => {
        if (item._id === catalogue.eventReport) {
          if (catalogue.dePrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePrintQty'] += catalogue.dePrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePrintQty'] = catalogue.dePrintQty;
            }
          }
          if (catalogue.dePdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePdfQty'] += catalogue.dePdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dePdfQty'] = catalogue.dePdfQty;
            }
          }
          if (catalogue.enPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPrintQty'] += catalogue.enPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPrintQty'] = catalogue.enPrintQty;
            }
          }
          if (catalogue.enPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPdfQty'] += catalogue.enPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_enPdfQty'] = catalogue.enPdfQty;
            }
          }
          if (catalogue.frPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPrintQty'] += catalogue.frPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPrintQty'] = catalogue.frPrintQty;
            }
          }
          if (catalogue.frPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPdfQty'] += catalogue.frPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_frPdfQty'] = catalogue.frPdfQty;
            }
          }
          if (catalogue.nlPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPrintQty'] += catalogue.nlPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPrintQty'] = catalogue.nlPrintQty;
            }
          }
          if (catalogue.nlPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPdfQty'] += catalogue.nlPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_nlPdfQty'] = catalogue.nlPdfQty;
            }
          }
          if (catalogue.dEnPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPrintQty'] += catalogue.dEnPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPrintQty'] = catalogue.dEnPrintQty;
            }
          }
          if (catalogue.dEnPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPdfQty'] += catalogue.dEnPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dEnPdfQty'] = catalogue.dEnPdfQty;
            }
          }
          if (catalogue.esPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPrintQty'] += catalogue.esPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPrintQty'] = catalogue.esPrintQty;
            }
          }
          if (catalogue.esPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPdfQty'] += catalogue.esPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_esPdfQty'] = catalogue.esPdfQty;
            }
          }
          if (catalogue.plPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPrintQty'] += catalogue.plPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPrintQty'] = catalogue.plPrintQty;
            }
          }
          if (catalogue.plPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPdfQty'] += catalogue.plPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_plPdfQty'] = catalogue.plPdfQty;
            }
          }
          if (catalogue.sePrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePrintQty'] += catalogue.sePrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePrintQty'] = catalogue.sePrintQty;
            }
          }
          if (catalogue.sePdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePdfQty'] += catalogue.sePdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_sePdfQty'] = catalogue.sePdfQty;
            }
          }
          if (catalogue.dkPrint) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPrintQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPrintQty'] += catalogue.dkPrintQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPrintQty'] = catalogue.dkPrintQty;
            }
          }
          if (catalogue.dkPdf) {
            if (catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPdfQty']) {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPdfQty'] += catalogue.dkPdfQty;
            } else {
              catalogueQty[catalogue.catalogue.catalogueCategory.name + '_dkPdfQty'] = catalogue.dkPdfQty;
            }
          }
        }
      });
      cataloguesByER[item._id] = { ...catalogueQty };
      catalogueQty = {};
    });
    objectsForExport.forEach((item) => {
      Object.keys(item).map(prop => {
        if (cataloguesByER[item._id][prop]) {
          item[prop] = cataloguesByER[item._id][prop];
        }
      });
    });
    objectsForExport.map(item => {
      if (item._id.length < 1) {
        objectsForExport.splice(objectsForExport.indexOf(item), 1);
      }
    });
    if (callbackCSV) {
      callbackCSV(objectsForExport);
      objectsForExport = [];
    }
  } else if (selectedCatalogues && selectedCatalogues.data &&
    selectedCatalogues.data.items && selectedCatalogues.data.items.length === 0 && exportArray.length !== 0) {
    if (callbackCSV) {
      callbackCSV(exportArray);
    }
  }

  if (selectedRowKeys && selectedRowKeys.length === 0) {
    if (callbackCSV) {
      callbackCSV(undefined);
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Table
        rowSelection={(user.data.role.includes('admin') || user.data.role.includes('superAdmin') || user.data.role.includes('salesManager')) && rowSelection}
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 10,
          position: 'bottom',
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
      <EditEventReport eventReportId={eventReportID} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default withNamespaces()(EventReportsTable);