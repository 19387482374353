import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PbbReportTable';
import { SERVER_URL } from '../../config';
import EditPbbReport from './EditPbbReport';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'updatedAt',
  'disabled',
  'status',
  'pbbImage',
  'pbbFiles',
  'customerNumber',
  'customerRequestNo',
  'quotationNo',
  'dealerOrderNo',
  'endCustomerOrderNo',
  'orderNo',
  'billingCompany',
  'billingStreet',
  'billingPostalCode',
  'billingCity',
  'billingContactPerson',
  'billingDepartment',
  'billingPhone',
  'billingEmail',
  'billingAppointment',
  'shippingCompany',
  'shippingStreet',
  'shippingPostalCode',
  'shippingCity',
  'shippingContactPerson',
  'shippingDepartment',
  'shippingPhone',
  'shippingEmail',
  'shippingAppointment',
  'teritoryManager',
  'dateAppointment',
  'requestDate',
  'offerDeadline',
  'realizationDate',
  'penalty',
  'generalGuarantees',
  'insurance',
  'tipComission',
  'competition',
  'orderChance',
  'budgetPlanning',
  'seismicZone',
  'undergroundClass',
  'floorCondition',
  'unhinderedDrilling',
  'drillingDepth',
  'magnesiteFlooring',
  'floorHeating',
  'floorFlatness',
  'leveling',
  'levelingExecutor',
  'surcharge',
  'additions',
  'assembly',
  'assemblyAsOption',
  'assemblyTeam',
  'deliveryDate',
  'assemblyDate',
  'goodsReceiptFrom',
  'goodsReceiptTo',
  'instalationHours',
  'constructionRoad',
  'constructionRoadText',
  'productionLine',
  'productionLineText',
  'gate',
  'gateText',
  'obstacles',
  'obstaclesText',
  'unloading',
  'transport',
  'rampWidth',
  'rampHeight',
  'rampPlatform',
  'gateWidth',
  'gateHeight',
  'doorWidth',
  'doorHeight',
  'hallUnloading',
  'yardUnloadingWidth',
  'yardUnloadingHeight',
  'auxiliaryStuff',
  'auxiliaryStuffQty',
  'liftTruck',
  'forkliftTruck',
  'elevator',
  'bearingCapacity',
  'forkLength',
  'loadCapacity',
  'liftingHeight',
  'driver',
  'elevatorType',
  'bearingCapacityElevator',
  'truckWithPlatform',
  'truckWithForklift',
  'scissorLift',
  'bearingCapacityScissor',
  'scissorLength',
  'scissorWorkingHeight',
  'scissorWidth',
  'building',
  'lockableRoom',
  'lighting',
  'separationAndWelding',
  'powerOnSite',
  'hall',
  'temperature',
  'safetyInstruction',
  'duration',
  'miscellaneous',
  'cabinLength',
  'cabinWidth',
  'cabinHeigth',
  'assignedEmployee',
  'createdBy'
];

const PbbReport = ({ t }) => {
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [pbbReports, fetchPbbReports] = useAxios('', [], currentuser.data.token, 'get');
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showProjectManagerModal, setShowProjectManagerModal] = useState(false);
  const history = useHistory();
  const [projectManagers, fetchProjectManagers] = useAxios('', [], currentuser.data.token, 'get');

  const METACountries =
    i18n.language === 'German'
      ? [
          { name: 'Deutschland', value: 'Germany' },
          { name: 'Schweiz', value: 'Switzerland' },
          { name: 'Italien', value: 'Italy' },
          { name: 'Frankreich', value: 'France' },
          { name: 'Spanien', value: 'Spain' },
          { name: 'Portugal', value: 'Portugal' },
          { name: 'Vereinigtes Königreich', value: 'United Kingdom' },
          { name: 'Tschechien', value: 'Czech Republic' },
          { name: 'Polen', value: 'Poland' },
          { name: 'Rumänien', value: 'Romania' },
          { name: 'Niederlande', value: 'Netherlands' },
          { name: 'Belgien', value: 'Belgium' },
          { name: 'Luxemburg', value: 'Luxembourg' },
          { name: 'Österreich', value: 'Austria' },
          { name: 'USA', value: 'USA' },
          { name: 'Serbien', value: 'Serbia' },
          { name: 'Kroatien', value: 'Croatia' },
          { name: 'Bosnien', value: 'Bosnia' },
          { name: 'Mazedonien', value: 'Macedonia' },
          { name: 'Litauen', value: 'Lithuania' },
          { name: 'Estland', value: 'Estonia' },
          { name: 'Lettland', value: 'Latvia' },
          { name: 'Ukraine', value: 'Ukraine' },
          { name: 'Ungarn', value: 'Hungary' },
          { name: 'Slowenien', value: 'Slovenia' },
          { name: 'Slowakei', value: 'Slovakia' },
          { name: 'Irland', value: 'Ireland' }
        ]
      : [
          { name: 'Germany', value: 'Germany' },
          { name: 'Switzerland', value: 'Switzerland' },
          { name: 'Italy', value: 'Italy' },
          { name: 'France', value: 'France' },
          { name: 'Spain', value: 'Spain' },
          { name: 'Portugal', value: 'Portugal' },
          { name: 'United Kingdom', value: 'United Kingdom' },
          { name: 'Czech Republic', value: 'Czech Republic' },
          { name: 'Poland', value: 'Poland' },
          { name: 'Romania', value: 'Romania' },
          { name: 'Netherlands', value: 'Netherlands' },
          { name: 'Belgium', value: 'Belgium' },
          { name: 'Luxembourg', value: 'Luxembourg' },
          { name: 'Austria', value: 'Austria' },
          { name: 'USA', value: 'USA' },
          { name: 'Serbia', value: 'Serbia' },
          { name: 'Croatia', value: 'Croatia' },
          { name: 'Bosnia', value: 'Bosnia' },
          { name: 'Macedonia', value: 'Macedonia' },
          { name: 'Lithuania', value: 'Lithuania' },
          { name: 'Estonia', value: 'Estonia' },
          { name: 'Latvia', value: 'Latvia' },
          { name: 'Ukraine', value: 'Ukraine' },
          { name: 'Hungary', value: 'Hungary' },
          { name: 'Slovenia', value: 'Slovenia' },
          { name: 'Slovakia', value: 'Slovakia' },
          { name: 'Ireland', value: 'Ireland' }
        ];

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (
      currentuser.data.role.includes('pbbAdmin') ||
      currentuser.data.role.includes('superAdmin') ||
      currentuser.data.role.includes('internalUser')
    ) {
      fetchPbbReports(`${SERVER_URL}/pbb-reports?filter=${JSON.stringify(params)}`, []);
    }
    if (currentuser.data.role.includes('externalUser')) {
      let filter = { assignedEmployee: currentuser.data.id };
      fetchPbbReports(`${SERVER_URL}/pbb-reports?filter=${JSON.stringify(filter)}`, []);
    }
    // fetchPbbReports(`${SERVER_URL}/pbb-reports`, []);
    let filterProjectManagers = {
      role: { $in: ['pbbAdmin', 'internalUser', 'salesAgent'] },
      status: 'ACTIVE'
    };
    fetchProjectManagers(`${SERVER_URL}/users?filter=${JSON.stringify(filterProjectManagers)}`, []);
  }, [currentuser.data.id, currentuser.data.role, fetchPbbReports, fetchProjectManagers]);

  const deleteEventHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/pbb-reports/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: t('deleteSuccessful'),
        placement: 'bottomRight'
      });
      history.push('/admin');
      history.push('/admin/pbb-reports');
    } catch (err) {
      notification.error({
        message: t('deleteProblem'),
        placement: 'bottomRight'
      });
    }
  };

  let columnKeys;
  if (pbbReports.data && pbbReports.data.items && pbbReports.data.items.length > 0) {
    const keys = Object.keys(pbbReports.data.items[0]);
    //  columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    // columnKeys = ['customer', 'country', 'companyName', 'clientNumber', 'createdAt', 'lastEditedBy'];
    columnKeys = [
      'customerName',
      'customerCountry',
      'shippingCompany',
      'customerNumber',
      'projectManager',
      'createdAt',
      'lastEditedBy'
    ];
  }

  let tableData = pbbReports.data.items;
  if (pbbReports.data && pbbReports.data.items && pbbReports.data.items.length > 0) {
    tableData = pbbReports.data.items.map((item) => {
      if (item.createdBy && item.createdBy.firstName) {
        item.createdBy = item.createdBy.firstName + ' ' + item.createdBy.lastName;
      }
      if (item.salesManager && item.salesManager.firstName) {
        item.salesManager = item.salesManager.firstName + ' ' + item.salesManager.lastName;
      }
      if (item.lastEditedBy && item.lastEditedBy.firstName) {
        item.lastEditedBy = item.lastEditedBy.firstName + ' ' + item.lastEditedBy.lastName;
      }
      // console.log('item: ', item);
      // if (item.projectManager && item.projectManager.firstName && item.projectManager.lastName) {
      //   item.projectManager = item.projectManager.firstName + ' ' + item.projectManager.lastName;
      // }
      // item.startDate = new Date(item.startDate).toLocaleString().split(',')[0];
      // item.endDate = new Date(item.endDate).toLocaleString().split(',')[0];
      if (moment(item.createdAt).isValid()) {
        item.createdAt = moment(item.createdAt).format('DD/MM/YYYY');
      }
      if (moment(item.updatedAt).isValid()) {
        item.updatedAt = moment(item.updatedAt).format('DD/MM/YYYY');
      }
      const filteredCountry = METACountries.filter(
        (country) => country.value === item.customerCountry
      );
      if (filteredCountry.length !== 0) {
        item.customerCountry = filteredCountry[0].name;
      }
      return item;
    });
  }

  const sendMail = async (id, recipientEmail) => {
    try {
      notification.info({
        message: 'Sending email in progress',
        placement: 'bottomRight',
        duration: 2
      });
      const pbbReport = await Axios.get(`${SERVER_URL}/pbb-reports/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      let keys = [];
      if (pbbReport.data.pbbFiles.length !== 0) {
        pbbReport.data.pbbFiles.forEach((file) => {
          keys.push(file.key);
        });
        const zipData = {
          keys: keys
        };
        await Axios.post(`${SERVER_URL}/make-zip`, zipData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
      }
      const response = await Axios.post(
        `${SERVER_URL}/generate-pbb-pdf`,
        { id, language: localStorage.language },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${currentuser.data.token}`
          }
        }
      );
      const data = {
        pbbReport: pbbReport.data,
        recipientEmail: recipientEmail,
        pdfPath: response?.data?.filename
      };

      await Axios.post(`${SERVER_URL}/pbb-report-email`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      setShowEmailModal(false);
      notification.success({
        message: 'Email is sent',
        placement: 'bottomRight',
        duration: 1
      });
    } catch {
      notification.error({
        message: 'Problem with sending email.',
        placement: 'bottomRight'
      });
    }
  };

  const generatePdf = async (pbbReportId, userLanguage) => {
    try {
      notification.info({
        message: 'Preparing report for download',
        placement: 'bottomRight'
      });
      const response = await Axios.post(
        `${SERVER_URL}/generate-pbb-pdf`,
        { id: pbbReportId, language: userLanguage },
        {
          withCredentials: false,
          headers: {
            Authorization: `Bearer ${currentuser.data.token}`
          }
        }
      );
      if (response.data.filename) {
        const pdfResponse = await Axios.get(
          `${SERVER_URL}/get-pbb-pdf?filename=${response.data.filename}`,
          {
            responseType: 'arraybuffer',
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          }
        );
        let pathArr = response.data.filename.split('/');
        // if (navigator.userAgent.indexOf("Win") != -1) {
        //   pathArr = response.data.filename.split('\\');
        // }
        // else if (navigator.userAgent.indexOf("Linux") != -1) {
        //   pathArr = response.data.filename.split('/');
        // }
        // else if (navigator.userAgent.indexOf("Mac") != -1) {
        //   pathArr = response.data.filename.split('/');
        // }
        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = pathArr[pathArr.length - 1];
        notification.success({
          message: 'Report ready',
          placement: 'bottomRight'
        });
        a.click();
        window.URL.revokeObjectURL(url);
        // removing file from file system
        // await Axios.post(`${SERVER_URL}/remove-file-local`, { filename: pathArr[pathArr.length - 1] }, { withCredentials: false });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight'
      });
    }
  };

  const updatePbbReport = async (data, id) => {
    try {
      await Axios.put(
        `${SERVER_URL}/pbb-reports/${id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      if (
        currentuser.data.role.includes('internalUser') ||
        currentuser.data.role.includes('externalUser')
      ) {
        await Axios.post(
          `${SERVER_URL}/email-pbb`,
          { customerCountry: data.customerCountry, isNew: false, id: id },
          { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
        );
      }
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight'
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight'
      });
    }
  };

  return (
    <div className='table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button
          // onClick={() => setShowModal(!showModal)}
          onClick={() => (window.location.href = '/admin/new-pbb-report')}
          type='primary'>
          {t('addPbbReport')}
        </Button>
      </div>
      {!pbbReports.isLoading &&
        pbbReports.data &&
        pbbReports.data.items &&
        pbbReports.data.items.length > 0 && (
          <div style={{ textAlign: 'center' }}>
            {pbbReports.isLoading && (
              <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
            )}
            {!pbbReports.isLoading &&
              pbbReports.data &&
              pbbReports.data.items &&
              pbbReports.data.items.length > 0 &&
              projectManagers &&
              projectManagers.data &&
              projectManagers.data.items && (
                <Table
                  data={tableData}
                  deleteHandler={deleteEventHandler}
                  columnKeys={columnKeys}
                  title='PBB Report'
                  editPath='/admin/edit-pbb-report/'
                  viewPath='/admin/view-pbb-report/'
                  sendMail={sendMail}
                  setShowEmailModal={setShowEmailModal}
                  showEmailModal={showEmailModal}
                  setShowProjectManagerModal={setShowProjectManagerModal}
                  showProjectManagerModal={showProjectManagerModal}
                  generatePdf={generatePdf}
                  projectManagers={projectManagers.data.items}
                  updatePbbReport={updatePbbReport}
                />
              )}
          </div>
        )}
    </div>
  );
};

export default withNamespaces()(PbbReport);
