import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Drawer, notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import CatalogueForm from '../../components/forms/CatalogueForm';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const EditCatalogue = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [catalogue, fetchCatalogue] = useAxios('', null, currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const id = props.catalogueId;

  useEffect(() => {
    if (id) {
      fetchCatalogue(`${SERVER_URL}/catalogue/${id}`, []);
    }
    fetchCategories(`${SERVER_URL}/product-category`, []);
  }, [id, fetchCatalogue, fetchCategories]);

  const updateCatalogue = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/catalogue/${id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/catalogues');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const createCatalogue = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/catalogue`,
        data,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: t('createdSuccessfully'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/catalogues');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {id && catalogue.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {id && !catalogue.isLoading && !catalogue.isError && catalogue.data && (
          <Drawer title={t('updateCatalogue')} placement="right" onClose={onClose} visible={props.showModal}>
            <CatalogueForm
              isNew={false}
              data={catalogue.data}
              updateHandler={updateCatalogue}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              categories={categories && categories.data && categories.data.items}
            />
          </Drawer>
        )}
        {id && !catalogue.isLoading && catalogue.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!id && catalogue && !catalogue.data && (
          <Drawer title={t('addCatalogue')} placement="right" onClose={onClose} visible={props.showModal}>
            <CatalogueForm
              isNew={true}
              data={null}
              createHandler={createCatalogue}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              categories={categories && categories.data && categories.data.items}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(EditCatalogue);
