import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, notification } from 'antd';
import { GalleryModal } from './';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 }
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 }
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
  salesManager: undefined
};

const UserForm = ({
  data,
  updateHandler,
  createHandler,
  result,
  setResult,
  SERVER_URL,
  token,
  showModal,
  setShowModal,
  profile
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  // const [legalType, setLegalType] = useState(data ? data.legalType : 'PRIVATE');
  // const [legalType, setLegalType] = useState('PRIVATE');
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;
  const [promptName, setPromptName] = useState();
  const [deletePop, setDeletePop] = useState();
  const [selectedRole, setSelectedRole] = useState(data && data.role);
  const [salesManagers, fetchSalesManagers] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    if (data && data.avatar) setImage(data.avatar.url);
  }, [data]);

  useEffect(() => {
    let filter = { role: 'salesManager', status: 'ACTIVE' };
    if (data && data.salesManager) {
      filter = {
        $or: [
          { $and: [{ role: 'salesManager' }, { status: 'ACTIVE' }] },
          { _id: data.salesManager }
        ]
      };
    }
    fetchSalesManagers(`${SERVER_URL}/users?filter=${JSON.stringify(filter)}`, []);
  }, [SERVER_URL, data, fetchSalesManagers]);

  const onFinish = (values) => {
    if (values.email === values.confirmEmail || (data && !values.confirmEmail)) {
      if (isNew) {
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } else {
      notification.error({
        message: 'Confirm email does not match.',
        placement: 'bottomRight'
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // const handleLegalType = (e) => {
  //   setLegalType(e.target.value);
  //   form.setFieldsValue({ legalType: e.target.value });
  // };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const deleteAvatarHandler = () => {
    setImage(null);
    form.setFieldsValue({ avatar: null });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight'
      });
      setTimeout(() => {
        history.push('/admin/users');
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight'
      });
    }
  };

  // const onClickHandler = () => {
  //   setShowModal(!showModal);
  //   // setTimeout(() => {
  //   //   window.location.reload();
  //   // }, 1000);
  // };

  return (
    <div className='panel panel-body'>
      <div>
        {/* eslint-disable-next-line no-useless-concat */}
        {/* {currentuser.data.id !== user._id && profile && (
          <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
            {t('deleteUser')} <DeleteOutlined />
          </div>
        )} */}
        {!result && (
          <Form
            {...layout}
            name='basic'
            initialValues={user}
            onFinish={(values) => onFinish(values, isNew)}
            onFinishFailed={onFinishFailed}
            layout='horizontal'
            form={form}>
            {/* <Form.Item className='upload-wrapper avatar' name='avatar'>
              <UploadBox
                editHandler={avatarHandler}
                deleteHandler={deleteAvatarHandler}
                image={image}
                index={0}
                name='avatar'
              />
            </Form.Item> */}
            {/* <Form.Item name='legalType'>
                  <Radio.Group onChange={handleLegalType}>
                    <Radio value='PRIVATE'>Private</Radio>
                    <Radio value='COMPANY'>Company</Radio>
                  </Radio.Group>
                </Form.Item> */}

            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input placeholder='Email' />
            </Form.Item>

            {!data && (
              <Form.Item
                name='confirmEmail'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={t('confirmEmail')} />
              </Form.Item>
            )}

            <Form.Item
              name='firstName'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input placeholder={t('firstName')} />
            </Form.Item>

            <Form.Item
              name='lastName'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input placeholder={t('lastName')} />
            </Form.Item>

            <Form.Item name='phone'>
              <Input placeholder={t('phone')} />
            </Form.Item>

            {/* <Form.Item name='address'>
                  <Input placeholder='Address' />
                </Form.Item> */}

            <Form.Item
              name='city'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input placeholder={t('city')} />
            </Form.Item>

            {/* <Form.Item name='zip'>
                  <Input placeholder='Zip' />
                </Form.Item> */}

            {/* <Form.Item
                  name='country'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter country!',
                    },
                  ]}
                >
                  <Input placeholder='Country' />
                </Form.Item> */}

            {/* {legalType === 'COMPANY' && (
                  <>
                    <Form.Item
                      name='companyName'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter company name!',
                        },
                      ]}
                    >
                      <Input placeholder='Company Name' />
                    </Form.Item>

                    <Form.Item
                      name='taxId'
                      rules={[
                        {
                          required: true,
                          message: 'Please enter tax ID!',
                        },
                      ]}
                    >
                      <Input placeholder='Tax ID' />
                    </Form.Item>
                  </>
                )} */}

            {isNew && (
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input.Password placeholder='Password' />
              </Form.Item>
            )}
            {(currentuser.data.role.includes('admin') ||
              currentuser.data.role.includes('superAdmin')) && (
              <Form.Item
                name='role'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Select
                  placeholder={t('role')}
                  onChange={(value) => setSelectedRole(value)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  mode='multiple'>
                  {/* <Select.Option value='superAdmin'>Super Admin</Select.Option> */}
                  <Select.Option value='admin'>Admin</Select.Option>
                  <Select.Option value='salesManager'>{t('salesManager')}</Select.Option>
                  <Select.Option value='salesAgent'>{t('salesAgent')}</Select.Option>
                  <Select.Option value='pbbAdmin'>PBB Admin</Select.Option>
                  <Select.Option value='internalUser'>Internal user</Select.Option>
                  <Select.Option value='externalUser'>External user</Select.Option>
                </Select>
              </Form.Item>
            )}

            {selectedRole && selectedRole.includes('internalUser') && (
              <Form.Item name='notificationEmail'>
                <Input type='email' placeholder={t('Send PBB notifications to:')} />
              </Form.Item>
            )}

            {(currentuser.data.role.includes('admin') ||
              currentuser.data.role.includes('superAdmin')) &&
              selectedRole &&
              selectedRole.includes('salesAgent') && (
                <Form.Item
                  name='salesManager'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Select
                    placeholder={t('salesManager')}
                    getPopupContainer={(trigger) => trigger.parentNode}>
                    {salesManagers.data &&
                      salesManagers.data.items &&
                      salesManagers.data.items.length !== 0 &&
                      salesManagers.data.items.map((item) => (
                        <Select.Option key={item._id} value={item._id}>
                          {item.firstName} {item.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}

            {(currentuser.data.role.includes('admin') ||
              currentuser.data.role.includes('superAdmin')) &&
              !isNew && (
                <Form.Item
                  name='status'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Select getPopupContainer={(trigger) => trigger.parentNode}>
                    <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                    <Select.Option value='WAITING_FOR_ACTIVATION'>
                      WAITING_FOR_ACTIVATION
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}
            <Form.Item {...tailLayout}>
              <div>
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{ float: 'right', marginLeft: '10px' }}>
                  {data ? t('editUser') : t('addUser')}
                </Button>
                <Button
                  type='secondary'
                  style={{ float: 'right' }}
                  onClick={() => setShowModal(!showModal)}>
                  {t('cancel')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
        {result && (
          <Result
            title={t('userCreated')}
            extra={
              <Button
                type='primary'
                key='console'
                onClick={() => {
                  setResult(false);
                  window.location.reload();
                }}>
                Ok
              </Button>
            }
          />
        )}

        {modal.visible && (
          <GalleryModal
            className='gallery-modal'
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/users/'
            imageType='data'
            imageHeight={200}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ ...modal, visible: false })}
            onInsert={(values) => onInsert(values, modal.formKey)}
          />
        )}
        {/* <Modal
          className='deleteModal'
          centered={true}
          visible={deletePop}
          onCancel={() => setDeletePop(!deletePop)}
          onOk={() => deleteUserHandler(user._id)}
          okText='Yes'
          cancelText='No'
        >
          <p>Are you sure you want to delete</p>
          {promptName && <p>{promptName.toUpperCase()}?</p>}
        </Modal> */}
      </div>
    </div>
  );
};

export default withNamespaces()(UserForm);
