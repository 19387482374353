import React from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  background: '#e3e3e3',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: '#fff'
};

const companyLogoStyle = {
  maxWidth: '100px',
  marginBottom: '50px'
};

const ForgotPassword = () => {

    const onFinish = async values => {
        try {
          const sendPasswordLink = await Axios.post(`${SERVER_URL}/forgot-password`, { email: values.email }, { withCredentials: false });
          if (sendPasswordLink.data.status) {
            notification.success({
              message: 'Password link was successfully sent.',
              placement: 'bottomRight'
            });
          }
        } catch (err) {
          notification.warn({
            message: err.response.data.message,
            placement: 'bottomRight'
          });
        }
      };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
        <img style={companyLogoStyle} className="login-logo" src="/META_Logo_DE.svg" alt="Meta Logo" />
        <Meta
            title="Enter your Email"
          />
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{'width':'100%'}} htmlType='submit'>
                               Send</Button>
            </Form.Item>
          
            <Form.Item {...tailLayout} style={{paddingTop: '30px'}}>
              <Link to="/login" className="link-forgot-password">Login</Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;