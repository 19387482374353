import { Card, Checkbox, Divider, Form, Input, Radio, Space, Row, Col } from 'antd';
import React, { useState } from 'react';
import i18n from '../../i18n';
import EmbedImage from '../base/EmbedImage';

const MiniRackForm = ({ data, files, setFiles, t, form }) => {
  const [frameSurface, setFrameSurface] = useState(data?.frameSurfaceMiniRack);
  const [steppedBeamsSurface, setSteppedBeamsSurface] = useState(data?.steppedBeamsSurfaceMiniRack);
  const [backCladdingSurface, setBackCladdingSurface] = useState(data?.backCladdingSurfaceMiniRack);
  const [frameHeight, setFrameHeight] = useState(data?.frameHeightMiniRack);
  const [shelfRacks, setShelfRacks] = useState(data && data.shelfRacksDistanceNeeded);
  const [shelfDepth, setShelfDepth] = useState(data && data.shelfDepthMiniRack);

  const shelfDepthsMiniRack =
    i18n.language === 'German'
      ? [
          { label: '650 mm', value: '650' },
          { label: '800 mm', value: '800' },
          { label: '1.050 mm', value: '1050' },
          { label: '', value: 'special-depth-mini-rack' }
        ]
      : [
          { label: '650 mm', value: '650' },
          { label: '800 mm', value: '800' },
          { label: '1,050 mm', value: '1050' }
        ];

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  return (
    <div>
      <h3 id='meta-mini-rack'>{t('metaMiniRack_title')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='miniRackLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoMiniRack'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      {/* <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMiniSpeedRack_singleRow')}>
            <Form.Item
              name='singleRowQtyMiniRack'
              rules={[{ required: true, message: t('validationAlert') }]}
            >
              <Input type='number' suffix={t('pieces')} />
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMiniSpeedRack_doubleRow')}>
            <Form.Item
              name='doubleRowQtyMiniRack'
              rules={[{ required: true, message: t('validationAlert') }]}
            >
              <Input type='number' suffix={t('pieces')} />
            </Form.Item>
          </Card>
        </Col>
  </Row>*/}

      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card className='pbbCard' title={t('metaMiniRack_distanceForDoubleShelfRacks')}>
            <Form.Item
              name='shelfRacksDistanceNeeded'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Radio.Group
                onChange={(event) => {
                  if (shelfRacks === 'yes') {
                    form.setFieldValue('shelfRacksDistanceValue', null);
                  }
                  setShelfRacks(event.target.value);
                }}>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {shelfRacks === 'yes' && (
              <Form.Item
                name='shelfRacksDistanceValue'
                rules={[{ required: true, message: t('validationAlert') }]}>
                <Input type='number' suffix='mm' />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>

      {/* <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_surface')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div> */}
      {/* <Row gutter={16}>
        <Col xs={24} md={8}>
          <div style={{ marginBottom: '10px' }}>{t('metaMiniSpeedRack_frame')}</div>
          <Form.Item
            name='frameSurfaceMiniRack'
            rules={[{ required: true, message: t('validationAlert') }]}
          >
            <Radio.Group
              onChange={(e) => {
                setFrameSurface(e.target.value);
              }}
              style={{ width: '100%' }}
            >
              <Space direction='vertical' style={{ width: '100%' }} size='large'>
                <Radio className='pbbCheckbox' value='Galvanized'>
                  {t('metaMiniSpeedRack_galvanized')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 7035 light grey'>
                  {t('RAL7035lightgrey')}{' '}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <div style={{ marginBottom: '10px' }}>{t('metaMiniSpeedRack_steppedBeams')}</div>
          <Form.Item
            name='steppedBeamsSurfaceMiniRack'
            rules={[{ required: true, message: t('validationAlert') }]}
          >
            <Radio.Group
              onChange={(e) => {
                setSteppedBeamsSurface(e.target.value);
              }}
              style={{ width: '100%' }}
            >
              <Space direction='vertical' style={{ width: '100%' }} size='large'>
                <Radio className='pbbCheckbox' value='Galvanized'>
                  {t('metaMiniSpeedRack_galvanized')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 7035 light grey'>
                  {t('RAL7035lightgrey')}{' '}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <div style={{ marginBottom: '10px' }}>{t('metaMiniSpeedRack_backCladding')}</div>
          <Form.Item
            name='backCladdingSurfaceMiniRack'
            rules={[{ required: true, message: t('validationAlert') }]}
          >
            <Radio.Group
              onChange={(e) => {
                setBackCladdingSurface(e.target.value);
              }}
              style={{ width: '100%' }}
            >
              <Space direction='vertical' style={{ width: '100%' }} size='large'>
                <Radio className='pbbCheckbox' value='Galvanized'>
                  {t('metaMiniSpeedRack_galvanized')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 7035 light grey'>
                  {t('RAL7035lightgrey')}{' '}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row> */}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_frameHeight')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='frameHeightMiniRack'
          // valuePropName='checked'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              { label: i18n.language === 'German' ? '2.200 mm' : '2,200 mm', value: '2200' },
              { label: i18n.language === 'German' ? '2.500 mm' : '2,500 mm', value: '2500' },
              { label: i18n.language === 'German' ? '3.000 mm' : '3,000 mm', value: '3000' },
              { label: '', value: 'special-height' }
            ]}
            onChange={(value) => {
              const specialHeightInForm = form.getFieldValue('specialFrameHeightMiniRack');
              // AKO SE DESELEKTUJE "special-height" CHECKBOX A PRETHODNO JE UNETA VREDNOST U "special-height" INPUT, BRISE SE specialHeightClip
              // IZ FORM OBJEKTA
              if (specialHeightInForm && !value.includes('special-height')) {
                form.setFieldValue('specialFrameHeightMiniRack', null);
              }
              // AKO POSTOJI VALIDATION ERROR ZA "special-height" INPUT I DESELEKTUJE SE "special-height" CHECKBOX, BRISE SE ERROR
              // JER POLJE VISE NIJE REQUIRED
              if (
                form.getFieldError('specialFrameHeightMiniRack').length > 0 &&
                !value.includes('special-height')
              ) {
                form.setFields([
                  {
                    name: 'specialFrameHeightMiniRack',
                    errors: []
                  }
                ]);
              }
              // SETOVANJE STATE-A DA BI SE IZAZVAO RE-RENDER
              setFrameHeight(value);
            }}
          />
        </Form.Item>
        <Form.Item
          name='specialFrameHeightMiniRack'
          rules={[
            { required: frameHeight?.includes('special-height'), message: t('validationAlert') }
          ]}>
          <Input
            type='number'
            disabled={!frameHeight?.includes('special-height')}
            placeholder={`${t('specialHeight')}:`}
            suffix='mm'
          />
        </Form.Item>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_shelfWidth')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='shelfWidthMiniRack'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              {
                label: i18n.language === 'German' ? '1.400 mm (600 kg)' : '1,400 mm (600 kg)',
                value: '1400'
              },
              {
                label: i18n.language === 'German' ? '1.800 mm (600 kg)' : '1,800 mm (600 kg)',
                value: '1800'
              },
              {
                label: i18n.language === 'German' ? '2.200 mm (600 kg)' : '2,200 mm (600 kg)',
                value: '2200'
              },
              {
                label: i18n.language === 'German' ? '2.600 mm (490 kg)' : '2,600 mm (490 kg)',
                value: '2600'
              }
            ]}
          />
        </Form.Item>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_shelfDepth')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='shelfDepthMiniRack'
          // valuePropName='checked'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={shelfDepthsMiniRack}
            onChange={(value) => {
              const specialDepthInForm = form.getFieldValue('shelfDepthSpecialFeaturesMiniRack');
              if (specialDepthInForm && !value.includes('special-depth-mini-rack')) {
                form.setFieldValue('shelfDepthSpecialFeaturesMiniRack', null);
              }
              if (
                form.getFieldError('shelfDepthSpecialFeaturesMiniRack').length > 0 &&
                !value.includes('special-depth-mini-rack')
              ) {
                form.setFields([
                  {
                    name: 'shelfDepthSpecialFeaturesMiniRack',
                    errors: []
                  }
                ]);
              }
              setShelfDepth(value);
            }}
          />
        </Form.Item>
        {i18n.language === 'German' && (
          <Form.Item
            name='shelfDepthSpecialFeaturesMiniRack'
            rules={[
              {
                required: shelfDepth?.includes('special-depth-mini-rack'),
                message: t('validationAlert')
              }
            ]}>
            <Input
              type='number'
              disabled={!shelfDepth?.includes('special-depth-mini-rack')}
              placeholder={`${t('metaMiniRack_specialFeatures')}`}
            />
          </Form.Item>
        )}
      </Row>
      {i18n.language === 'English' && (
        <Row>
          <Form.Item name='shelfDepthSpecialFeaturesMiniRack'>
            <Input type='number' placeholder={`${t('metaMiniRack_specialFeatures')}`} />
          </Form.Item>
        </Row>
      )}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_floors')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='floorsMiniRack'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              { label: `${t('metaMiniSpeedRack_steelPanels')}`, value: 'steel-panels' },
              { label: `${t('metaMiniSpeedRack_chipboard')}`, value: 'chipboard' }
            ]}
          />
        </Form.Item>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name='shelvesPerBayMiniRack'
            rules={[{ required: true, message: t('validationAlert') }]}
            label={`${t('metaMiniRack_shelvesPerBay')}:`}>
            <Input
              type='number'
              placeholder={`${t('metaMiniRack_shelvesPerBay')}`}
              suffix={t('pieces')}
            />
          </Form.Item>
        </Col>
      </Row>

      <EmbedImage data={data} files={files} setFiles={setFiles} name='miniRackImage' />
      <Form.Item name='moreInformationMiniRack' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={`${t('positioning/SpecialFeatures')}`} />
      </Form.Item>
    </div>
  );
};

export default MiniRackForm;
