import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Popconfirm, Input, Button, Modal, Select } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  MailOutlined,
  MailFilled,
  FilePdfOutlined,
  FilePdfFilled,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

const PbbReportTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath, sendMail, showEmailModal, setShowEmailModal, showProjectManagerModal, setShowProjectManagerModal, generatePdf, t, projectManagers, updatePbbReport }) => {
  const user = useContext(UserContext);
  let searchInput;
  const [recipientEmail, setRecipientEmail] = useState('');
  const [idOfClickedReport, setIdOfClickedReport] = useState('');
  const [clickedReport, setClickedReport] = useState();
  const [chosenManager, setChosenManager] = useState('');

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    render: (text, record) => {
      if (item === 'projectManager') {
        return (
          <Select
            style={{ width: "100%" }}
            value={record?.projectManager?._id}
            onChange={(value) => { setShowProjectManagerModal(true); setClickedReport(record); setChosenManager(value); }}
            filterSort={(optionA, optionB) =>
              optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
            }
          >
            {projectManagers.filter((item) => !item.role.includes('salesAgent')).map((manager) => (

              <Select.Option value={manager._id}>
                {manager.lastName} {manager.firstName}
              </Select.Option>
            ))}
          </Select>
        );
      } else {
        return record[item];
      }
    },
    dataIndex: item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
          ? [item, user.language ? user.language.selected.code : '']
          : item,
    ),
  }));

  const onEditClick = (ID) => {
    window.location.href = `/admin/edit-pbb-report/${ID}`;
  };

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <EditOutlined
            className='icon-unlock'
            title={t('editReport')}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
          <EditFilled
            className='icon-lock'
            title={t('editReport')}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
        </div> */}

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + `?draft=${record._id}`}>
              <EyeOutlined
                className='icon-unlock'
                title={t('viewDraft')}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeFilled
                className='icon-lock'
                title={t('viewDraft')}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}
        {!user.data.role.includes('internalUser') && !user.data.role.includes('externalUser') &&
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={t('areYouSureDelete')}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <DeleteOutlined className='icon-unlock' title={t('deleteDraft')} />
              <DeleteFilled className='icon-lock' title={t('deleteDraft')} />
            </Popconfirm>
          </div>
        }

        {/* <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <FilePdfOutlined
            className='icon-unlock'
            title={t('downladReport')}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => generatePdf(record._id, localStorage.language || i18n.language)}
          />
          <FilePdfFilled
            className='icon-lock'
            title={t('downladReport')}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => generatePdf(record._id, localStorage.language || i18n.language)}
          />
        </div>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <MailOutlined
            className='icon-unlock'
            title={t('sendEmail')}
            onClick={() => { setShowEmailModal(true); setIdOfClickedReport(record._id); }}
          />
          <MailFilled
            className='icon-lock'
            title={t('sendEmail')}
            onClick={() => { setShowEmailModal(true); setIdOfClickedReport(record._id); }}
          />
        </div> */}
      </div>
    ),
  });

  for (let i = 0; i < columns.length; i++) {
    columns[i].title = t(`${columns[i].title}`);
  }

  return (
    <div>
      <Table
        style={{ paddingTop: '1.5rem' }}
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
      {/* <Modal
        className="email-modal"
        visible={showEmailModal}
        title={t('sendEmail')}
        onOk={() => sendMail(idOfClickedReport, recipientEmail)}
        onCancel={() => setShowEmailModal(false)}
        centered={true}
        cancelText={t('cancel')}
      >
        <input
          type='email'
          onChange={(e) => setRecipientEmail(e.target.value)}
          placeholder={t('recipientEmail')}
        />
      </Modal> */}

      {/* <Modal
        className="email-modal"
        visible={showProjectManagerModal}
        centered={true}
        title={`${t('confirm')}: ${projectManagers.find((item) => item._id === chosenManager)?.firstName} ${projectManagers.find((item) => item._id === chosenManager)?.lastName} ${t('projectManagerModal')}`}
        onOk={() => { updatePbbReport({ ...clickedReport, projectManager: chosenManager }, clickedReport._id); }}
        onCancel={() => setShowProjectManagerModal(false)}
        cancelText={t('cancel')}
      >
      </Modal> */}
    </div>
  );
};

export default withNamespaces()(PbbReportTable);
