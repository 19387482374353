import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, Input, Modal, notification, Upload } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import Axios from 'axios';
// import PbbReportForm from '../../components/forms/PbbReportForm';
import PbbReportFormView from '../../components/forms/PbbReportFormView';
import * as FileSaver from 'file-saver';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';
import i18n from '../../i18n';

// const formInit = {
//   customer: undefined,
//   country: undefined,
//   salesManager: undefined,
//   createdBy: undefined,
//   lastEditedBy: undefined,
// };

const ViewPbbReport = (props) => {
  // const [form] = Form.useForm();
  // const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [pbbReport, fetchPbbReport] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const pbbReportId = props.match.params.id;
  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState([]);
  const [openFilesModal, setOpenFilesModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  useEffect(() => {
    if (pbbReportId) {
      fetchPbbReport(`${SERVER_URL}/pbb-reports/${pbbReportId}`, []);
    }
  }, [pbbReportId, fetchPbbReport]);

  const getActivityLog = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/activity/${pbbReportId}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      setLogs(data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (open) {
      getActivityLog();
    }
  }, [open]);

  // if (pbbReport && pbbReport.data) {
  //   form.setFieldsValue({
  //     customer: pbbReport.data.customer,
  //     country: pbbReport.data.country,
  //     salesManager: pbbReport.data.salesManager && pbbReport.data.salesManager.firstName + ' ' + pbbReport.data.salesManager.lastName,
  //     createdBy: pbbReport.data.createdBy && pbbReport.data.createdBy.firstName + ' ' + pbbReport.data.createdBy.lastName,
  //     lastEditedBy: pbbReport.data.lastEditedBy && pbbReport.data.lastEditedBy.firstName + ' ' + pbbReport.data.lastEditedBy.lastName,
  //   });
  // }

  // let initialValues = { ...formInit };

  // return (
  //   <div className='dashboard'>
  //     <div className='actions-block' style={{ marginBottom: '8px' }}>
  //       <Button onClick={() => window.location.href = '/admin/pbb-reports'} type='primary'>
  //         {t('pbbReports')}
  //       </Button>
  //     </div>
  //     <div className='card-wrapper'>
  //       <Form
  //         className='form-horizontal'
  //         initialValues={initialValues}
  //         layout='vertical'
  //         form={form}
  //       >
  //         <Form.Item name='customer' label={t('customer')}>
  //           <Input disabled className='eventField' />
  //         </Form.Item>
  //         <Form.Item name='country' label={t('country')}>
  //           <Input disabled className='eventField' />
  //         </Form.Item>
  //         <Form.Item name='salesManager' label={t('salesManager')}>
  //           <Input disabled className='eventField' />
  //         </Form.Item>
  //         <Form.Item name='createdBy' label={t('createdBy')}>
  //           <Input disabled className='eventField' />
  //         </Form.Item>
  //         <Form.Item name='lastEditedBy' label={t('lastEditedBy')}>
  //           <Input disabled className='eventField' />
  //         </Form.Item>
  //       </Form>

  //     </div>
  //   </div>
  // );
  const downloadFiles = async () => {
    try {
      notification.info({
        message: 'Downloading in progress.',
        placement: 'bottomRight'
      });
      const response = await Axios.post(`${SERVER_URL}/generate-pbb-pdf`, { id: pbbReportId, language: localStorage.language || i18n.language }, {
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${currentuser.data.token}`,
        },
      });
      if (response.data.filename) {
        console.log('file path ', response.data.filename);
        const pdfResponse = await Axios.get(`${SERVER_URL}/get-pbb-pdf?filename=${response.data.filename}`, {
          responseType: 'arraybuffer',
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}`, },
        });
        let pathArr = response.data.filename.split('/');
        // if (navigator.userAgent.indexOf("Win") != -1) {
        //   pathArr = response.data.filename.split('\\');
        // }
        // else if (navigator.userAgent.indexOf("Linux") != -1) {
        //   pathArr = response.data.filename.split('/');
        // }
        // else if (navigator.userAgent.indexOf("Mac") != -1) {
        //   pathArr = response.data.filename.split('/');
        // }
        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = pathArr[pathArr.length - 1];
        console.log('file path download ', pathArr[pathArr.length - 1]);
        a.click();
        window.URL.revokeObjectURL(url);
        // await Axios.post(`${SERVER_URL}/remove-file-local`, { filename: pathArr[pathArr.length - 1] }, { withCredentials: false });
      };

      let keys = [];
      if (pbbReport.data.pbbFiles.length !== 0) {
        pbbReport.data.pbbFiles.forEach((file) => {
          keys.push(file.key);
        });
        const zipData = {
          keys: keys,
        };
        await Axios.post(`${SERVER_URL}/make-zip`, zipData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        const res = await Axios.get(`${SERVER_URL}/read-zip`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        const zipContent = [];
        res.data.chunks.forEach((chunk) => {
          zipContent.push(...chunk.data);
        });
        const blob = new Blob([Buffer.from(zipContent)], { type: 'application/zip' });
        FileSaver.saveAs(blob, 'PBB report files.zip');
      }
      notification.success({
        message: 'Download complete.',
        placement: 'bottomRight'
      });
    } catch (err) {
      console.log('error: ', err);
      notification.info({
        message: 'Problem with downloading.',
        placement: 'bottomRight'
      });
    }
  };

  // console.log('navigator.userAgent: ', navigator.userAgent);

  if (pbbReport.data && pbbReport.data.pbbFiles && fileList.length === 0) {
    pbbReport.data.pbbFiles.forEach((attachment, index) => {
      setFileList((prevState) => [
        ...prevState,
        {
          uid: (index + 1).toString(),
          name: attachment.originalName,
          status: 'done',
          response: {
            key: attachment.key,
          },
        },
      ]);
    });
  }

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
    showRemoveIcon: false,
  };

  window.addEventListener('resize', () => {
    setScreenWidth(window.screen.width);
  });

  return (
    <div className='dashboard'>
      {pbbReportId ?
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Button onClick={() => window.location.href = '/admin/pbb-reports'} type='primary'>
            {t('pbbReports')}
          </Button>
          {screenWidth < 550
            ?
            <>
              <Button onClick={downloadFiles} type='primary'>
                {t('downladReport')}
              </Button>
              <Button onClick={() => setOpen(true)} type='primary'>
                {t('activityLog')}
              </Button>
              <Button onClick={() => setOpenFilesModal(true)} type='primary'>
                {t('previewFiles')}
              </Button>
            </>
            :
            <div>
              <Button style={{ marginRight: '10px' }} onClick={downloadFiles} type='primary'>
                {t('downladReport')}
              </Button>
              <Button onClick={() => setOpen(true)} type='primary'>
                {t('activityLog')}
              </Button>
              <Button style={{ marginLeft: '10px' }} onClick={() => setOpenFilesModal(true)} type='primary'>
                {t('previewFiles')}
              </Button>
            </div>
          }
        </div>
        :
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Button onClick={() => window.location.href = '/admin/pbb-reports'} type='primary'>
            {console.log(pbbReportId)}
            {t('pbbReports')}
          </Button>
        </div>
      }
      <div style={{ textAlign: 'center' }}>
        {pbbReportId && pbbReport.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {pbbReportId && !pbbReport.isLoading && !pbbReport.isError && pbbReport.data && (
          <PbbReportFormView
            // isNew={false}
            data={pbbReport.data}
            // updateHandler={updatePbbReport}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
        {pbbReportId && !pbbReport.isLoading && pbbReport.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!pbbReportId && pbbReport && !pbbReport.data && (
          <PbbReportFormView
            // isNew={true}
            data={null}
            // createHandler={createPbbReport}
            // result={result}
            // setResult={setResult}
            SERVER_URL={SERVER_URL}
            token={currentuser.data.token}
          />
        )}
      </div>
      {open &&
        <Modal
          title="Activity Log"
          centered
          visible={open}
          footer={null}
          onCancel={() => setOpen(false)}
        >
          <div style={{ padding: '1rem 0', height: '20rem', overflowY: 'auto' }}>
            {!logs.length ?
              <p style={{ textAlign: 'center', color: 'gray' }}>No Activity Logs</p>
              :
              <>
                {logs && logs.filter((log) => log.user).map(log => (
                  <p>{log.user.firstName} {log.user.lastName} {log.action === 'CREATE' ? 'created' : 'edited'} ({new Date(log.createdAt).toLocaleString().replace(',', '')})</p>
                ))}
              </>
            }
          </div>
        </Modal>
      }
      {openFilesModal &&
        <Modal
          title="Preview Files"
          centered
          visible={openFilesModal}
          footer={null}
          onCancel={() => setOpenFilesModal(false)}
          className='preview-files-modal'
        >
          {pbbReport?.data?.pbbFiles?.length !== 0 ?
            <Upload
              style={{ padding: '10px' }}
              fileList={fileList.length === 0 ? [] : [...fileList]}
              showUploadList={showUploadList}
              name='file'
              onDownload={async (file) => {
                notification.info({
                  message: 'Downloading in progress.',
                  placement: 'bottomRight',
                });
                const urlParts = file.response.key.split('/');
                const response = await Axios.get(`${SERVER_URL}/file/${urlParts[0]}/${urlParts[1]}`, {
                  withCredentials: false,
                });
                FileSaver.saveAs(response.config.url, file.name);
              }}
            >
            </Upload>
            : <span className='noFilesMessage'>{t('noFiles')}</span>}
        </Modal>
      }
    </div>
  );
};

export default withNamespaces()(ViewPbbReport);