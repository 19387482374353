import { UploadOutlined, DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Form, notification, Upload } from "antd";
import Axios from "axios";
import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import { SERVER_URL } from "../../config";
import { withNamespaces } from 'react-i18next';
import * as FileSaver from 'file-saver';

const UploadSketch = ({ data, name, files, setFiles, t }) => {
  const currentuser = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [isError, setIsError] = useState(false);

  if (data?.pbbFiles && fileList.length === 0) {
    data.pbbFiles.forEach((file, index) => {
      if (file.sketchType && file.sketchType === name) {
        setFileList((prevState) => [
          ...prevState,
          {
            uid: file._id.toString(),
            name: file.originalName,
            status: 'done',
            // thumbUrl,
            response: {
              key: file.key,
            },
            fileId: file._id,
            sketchType: file.sketchType,
          },
        ]);
      }
    });
  }

  const showUploadList = {
    showDownloadIcon: true,
    downloadIcon: <DownloadOutlined />,
    showRemoveIcon: true,
    removeIcon: <DeleteOutlined />
  };

  return (
    <Form.Item name='file'>
      <Upload
        fileList={[...fileList]}
        showUploadList={showUploadList}
        onDownload={async (file) => {
          notification.info({
            message: 'Downloading in progress.',
            placement: 'bottomRight',
          });
          const urlParts = file.response.key.split('/');
          const response = await Axios.get(`${SERVER_URL}/file/${urlParts[0]}/${urlParts[1]}`, {
            withCredentials: false,
          });
          FileSaver.saveAs(response.config.url, file.name);
        }}
        action={`${SERVER_URL}/upload-file`}
        name='file'
        headers={{
          Authorization: `Bearer ${currentuser.data.token}`,
          uri: 'public/',
          timestamp: Date.now().toString(),
        }}
        onChange={async (info) => {
          setFileList(info.fileList.filter((item) => item.status && item.status !== 'error'));
          if (!info.file.status) {
            setIsError(true);
          }
          if (info.file.status === 'done') {
            setIsError(false);
            setFiles([...files, {
            key: info.file.response.key,
            location: info.file.response.location,
            originalName: info.file.name,
            type: info.file.response.mimetype,
            sketchType: name,
            }]);
            notification.success({
              message: 'Upload successful',
              placement: 'bottomRight',
            });
          } else if (info.file.status === 'error') {
            notification.error({
              message: 'Upload failed',
              placement: 'bottomRight',
            });
          }
        }}
        beforeUpload={(file) => {
          setIsError(false);
          const isAllowedFormat = ['application/pdf'].includes(file.type);
          if (!isAllowedFormat && !file.type.includes('image' || 'svg')) {
            notification.error({
              message: 'You can only upload PDF or image file!',
              placement: 'bottomRight',
            });
            return false;
          }
          if (file.size / 1000000 > 10) {
            notification.error({
              message: 'File is larger than 10 MB!',
              placement: 'bottomRight',
            });
            return false;
          }
          return true;
        }}
        onRemove={async (file) => {
          notification.info({
            message: 'Removing file in progress.',
            placement: 'bottomRight',
          });
          let newFileList = [...fileList];
          let newFiles = [...files];
          let foundFile;
          for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].response.key === file.response.key) {
              foundFile = fileList[i];
              newFileList.splice(i, 1);
              if (
                data &&
                data.pbbFiles &&
                data.pbbFiles.length !== 0
              ) {
                for(let i = 0; i < data.pbbFiles.length; i++) {
                  if(data.pbbFiles[i]._id === foundFile.fileId) {
                    data.pbbFiles.splice(i, 1);
                  }
                }
                await Axios.put(
                  `${SERVER_URL}/pbb-reports/${data._id}`,
                  { pbbFiles: data.pbbFiles },
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${currentuser.data.token}` },
                  },
                );
              }
            }
          }
          for (let i = 0; i < files.length; i++) {
            if (files[i].originalName === file.name) {
              newFiles.splice(i, 1);
            }
          }

          setFileList(newFileList);
          setFiles(newFiles);
          let linkParts = foundFile && foundFile.response && foundFile.response.key.split('/');
          if (linkParts && linkParts.length !== 0) {
            await Axios.post(`${SERVER_URL}/remove-file/${linkParts[0]}/${linkParts[1]}`, {}, {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            });
          }
        }}
      >
        <Button className="uploadSketchButton" id='uploadButton' icon={<UploadOutlined />}>
          {t('uploadSketch')}
        </Button>
      </Upload>
    </Form.Item>
  );
};
export default withNamespaces()(UploadSketch);