import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/CatalogueTable';
import { SERVER_URL } from '../../config';
import EditCatalogue from './EditCatalogue';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt', 'startDate', 'endDate', 'status'];

const Catalogue = ({ t }) => {
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [catalogues, fetchCatalogues] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchCatalogues(`${SERVER_URL}/catalogue`, []);
  }, [fetchCatalogues]);

  let columnKeys;
  if (catalogues.data && catalogues.data.items && catalogues.data.items.length > 0) {
    const keys = Object.keys(catalogues.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.push('status');
  }

  let tableData = [];
  if (catalogues.data && catalogues.data.items && catalogues.data.items.length > 0) {
    tableData = catalogues.data.items.map((item) => {
      if (item.catalogueCategory && item.catalogueCategory.name) {
        item.catalogueCategory = t(item.catalogueCategory.name);
      }
      // item.dePrint = String(item.dePrint || '');
      // item.dePdf = String(item.dePdf || '');
      // item.enPrint = String(item.enPrint || '');
      // item.enPdf = String(item.enPdf || '');
      // item.frPrint = String(item.frPrint || '');
      // item.frPdf = String(item.frPdf || '');
      // item.nlPrint = String(item.nlPrint || '');
      // item.nlPdf = String(item.nlPdf || '');
      // item.dEnPrint = String(item.dEnPrint || '');
      // item.dEnPdf = String(item.dEnPdf || '');
      // item.esPrint = String(item.esPrint || '');
      // item.esPdf = String(item.esPdf || '');
      // item.plPrint = String(item.plPrint || '');
      // item.plPdf = String(item.plPdf || '');
      // item.sePrint = String(item.sePrint || '');
      // item.sePdf = String(item.sePdf || '');
      // item.dkPrint = String(item.dkPrint || '');
      // item.dkPdf = String(item.dkPdf || '');
      item.dePrint = t(String(item.dePrint));
      item.dePdf = t(String(item.dePdf));
      item.enPrint = t(String(item.enPrint));
      item.enPdf = t(String(item.enPdf));
      item.frPrint = t(String(item.frPrint));
      item.frPdf = t(String(item.frPdf));
      item.nlPrint = t(String(item.nlPrint));
      item.nlPdf = t(String(item.nlPdf));
      item.dEnPrint = t(String(item.dEnPrint));
      item.dEnPdf = t(String(item.dEnPdf));
      item.esPrint = t(String(item.esPrint));
      item.esPdf = t(String(item.esPdf));
      item.plPrint = t(String(item.plPrint));
      item.plPdf = t(String(item.plPdf));
      item.sePrint = t(String(item.sePrint));
      item.sePdf = t(String(item.sePdf));
      item.dkPrint = t(String(item.dkPrint));
      item.dkPdf = t(String(item.dkPdf));
      item.startDate = moment(item.startDate).format('MM/DD/YYYY');
      item.endDate = moment(item.endDate).format('MM/DD/YYYY');
      return item;
    });
  }

  const deleteEventHandler = async (id) => {
    try {
      if (currentuser.data.role.includes('superAdmin')) {
        await Axios.delete(`${SERVER_URL}/catalogue/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else {
        await Axios.put(`${SERVER_URL}/catalogue/${id}`, { status: 'INACTIVE' }, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }
      notification.success({
        message: t('deleteSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/catalogues');
    } catch (err) {
      notification.error({
        message: t('deleteProblem'),
        placement: 'bottomRight',
      });
    }
  };


  return (
    // <div className='table product-categories-table'>
    <div className='table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => setShowModal(!showModal)} type='primary'>
          {t('addCatalogue')}
        </Button>
      </div>
      {!catalogues.isLoading && catalogues.data && catalogues.data.items && catalogues.data.items.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          {catalogues.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!catalogues.isLoading && catalogues.data && catalogues.data.items && catalogues.data.items.length > 0 && (
            <Table
              data={tableData}
              columnKeys={columnKeys}
              title='Catalogue'
              editPath='/admin/edit-catalogue/'
              deleteHandler={deleteEventHandler}
            />
          )}
        </div>
      )}
      <EditCatalogue showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default withNamespaces()(Catalogue);