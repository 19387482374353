import React, { useEffect, useState, useMemo, useContext } from 'react';
import {
  Form,
  Input,
  Button,
  DatePicker,
  notification,
  InputNumber,
  Select,
  Collapse,
  Radio,
  Space
} from 'antd';
import moment from 'moment';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';
import { GalleryModal } from '.';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import countryList from 'react-select-country-list';

import { withNamespaces } from 'react-i18next';

const { Panel } = Collapse;

const formInit = {
  company: undefined,
  name: undefined,
  lastName: undefined,
  date: undefined,
  newLead: undefined,
  arrangeVisit: undefined,
  note: undefined,
  employeeName: undefined,
  leadConsent: undefined,
  leadConsentUpdated: undefined,
  jobFunction: undefined,
  street: undefined,
  zipCode: undefined,
  city: undefined,
  country: undefined,
  phoneNumber: undefined,
  email: undefined,
  dealer: undefined,
  project: undefined,
  metaCustomerCode: undefined,
  shelving: undefined,
  palletRacking: undefined,
  cantilever: undefined,
  multibloc: undefined,
  rackInspection: undefined,
  lighting: undefined,
  conveyoring: undefined,
  catalogueType: undefined
};

const EventReportForm = ({
  isNew,
  data,
  createHandler,
  updateHandler,
  eventSelect,
  salesManagerId,
  employeeName,
  token,
  t,
  selectedCatalogues
}) => {
  const user = useContext(UserContext);
  const [leadConsent, setLeadConsent] = useState(false);
  const [leadConsentUpdated, setLeadConsentUpdated] = useState();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const [visitReason, setVisitReason] = useState();
  const [catalogueType, setCatalogueType] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [followUp, setFollowUp] = useState();

  const [allProductCategories, fetchAllProductCategories] = useAxios(
    '',
    [],
    user.data.token,
    'get'
  );
  const [catalogues, fetchCatalogues] = useAxios('', [], user.data.token, 'get');

  useEffect(() => {
    for (let i = 0; i < eventSelect.data.items.length; i++) {
      if (eventSelect.data.items[i]._id === selectedEventId) {
        setSelectedEvent(eventSelect.data.items[i]);
      }
    }
  }, [selectedEventId]);

  useEffect(() => {
    fetchAllProductCategories(`${SERVER_URL}/product-category`, []);
    fetchCatalogues(`${SERVER_URL}/catalogue`, []);
  }, [fetchAllProductCategories, fetchCatalogues]);

  const handleFollowUp = (e) => {
    setFollowUp(e);
  };

  const options = useMemo(() => countryList().getData(), []);

  const handleLeadConsent = (e) => {
    setLeadConsent(e.target.checked);
    setLeadConsentUpdated(moment());
  };

  const handleVisitReason = (e) => {
    setVisitReason(e.target.value);
  };

  const handleCatalogueType = (e) => {
    setCatalogueType(e.target.value);
  };

  const [form] = Form.useForm();
  if (data) {
    data.date = moment(data.date);
    if (leadConsentUpdated && data.leadConsentUpdated !== leadConsentUpdated) {
      data.leadConsentUpdated = moment(leadConsentUpdated);
    }
    if (data.event && data.event._id) {
      data.event = data.event._id;
    }
  }

  let initialValues = { ...formInit, ...data };
  if (selectedCatalogues) {
    selectedCatalogues.forEach((catalogue) => {
      let singleCatalogue = {};
      if (catalogue.eventReport === data._id) {
        singleCatalogue[catalogue.catalogue._id] = catalogue;
        initialValues = { ...initialValues, ...singleCatalogue };
        form.setFieldsValue({
          ...singleCatalogue
        });
      }
    });
  }

  const onFinish = async (values, isNew) => {
    try {
      // if (values && values.shelving) {
      //   if (values.shelving.dePrint || values.shelving.dePdf || values.shelving.enPrint || values.shelving.enPdf || values.shelving.frPrint || values.shelving.frPdf) {
      //     values.shelving.catalogue = true;
      //   }
      //   if (values.shelving.dePrintQty && values.shelving.dePrintQty >= 1 || values.shelving.enPrintQty && values.shelving.enPrintQty >= 1 || values.shelving.frPrintQty && values.shelving.frPrintQty >= 1) {
      //     values.shelving.interest = true;
      //     values.shelving.catalogue = true;
      //   }
      // }
      // if (values && values.palletRacking) {
      //   if (values.palletRacking.dePrint || values.palletRacking.dePdf || values.palletRacking.enPrint || values.palletRacking.enPdf || values.palletRacking.frPrint || values.palletRacking.frPdf) {
      //     values.palletRacking.catalogue = true;
      //   }
      //   if (values.palletRacking.dePrintQty && values.palletRacking.dePrintQty >= 1 || values.palletRacking.enPrintQty && values.palletRacking.enPrintQty >= 1 || values.palletRacking.frPrintQty && values.palletRacking.frPrintQty >= 1) {
      //     values.palletRacking.interest = true;
      //     values.palletRacking.catalogue = true;
      //   }
      // }
      // if (values && values.cantilever) {
      //   if (values.cantilever.dePrint || values.cantilever.dePdf || values.cantilever.enPrint || values.cantilever.enPdf || values.cantilever.frPrint || values.cantilever.frPdf) {
      //     values.cantilever.catalogue = true;
      //   }
      //   if (values.cantilever.dePrintQty && values.cantilever.dePrintQty >= 1 || values.cantilever.enPrintQty && values.cantilever.enPrintQty >= 1 || values.cantilever.frPrintQty && values.cantilever.frPrintQty >= 1) {
      //     values.cantilever.interest = true;
      //     values.cantilever.catalogue = true;
      //   }
      // }
      // if (values && values.multibloc) {
      //   if (values.multibloc.dePrint || values.multibloc.dePdf || values.multibloc.enPrint || values.multibloc.enPdf || values.multibloc.frPrint || values.multibloc.frPdf) {
      //     values.multibloc.catalogue = true;
      //   }
      //   if (values.multibloc.dePrintQty && values.multibloc.dePrintQty >= 1 || values.multibloc.enPrintQty && values.multibloc.enPrintQty >= 1 || values.multibloc.frPrintQty && values.multibloc.frPrintQty >= 1) {
      //     values.multibloc.interest = true;
      //     values.multibloc.catalogue = true;
      //   }
      // }
      // if (values && values.rackInspection) {
      //   if (values.rackInspection.dePrint || values.rackInspection.dePdf || values.rackInspection.enPrint || values.rackInspection.enPdf || values.rackInspection.frPrint || values.rackInspection.frPdf) {
      //     values.rackInspection.catalogue = true;
      //   }
      //   if (values.rackInspection.dePrintQty && values.rackInspection.dePrintQty >= 1 || values.rackInspection.enPrintQty && values.rackInspection.enPrintQty >= 1 || values.rackInspection.frPrintQty && values.rackInspection.frPrintQty >= 1) {
      //     values.rackInspection.interest = true;
      //     values.rackInspection.catalogue = true;
      //   }
      // }
      // if (values && values.lighting) {
      //   if (values.lighting.dePrint || values.lighting.dePdf || values.lighting.enPrint || values.lighting.enPdf || values.lighting.frPrint || values.lighting.frPdf) {
      //     values.lighting.catalogue = true;
      //   }
      //   if (values.lighting.dePrintQty && values.lighting.dePrintQty >= 1 || values.lighting.enPrintQty && values.lighting.enPrintQty >= 1 || values.lighting.frPrintQty && values.lighting.frPrintQty >= 1) {
      //     values.lighting.interest = true;
      //     values.lighting.catalogue = true;
      //   }
      // }
      // if (values && values.conveyoring) {
      //   if (values.conveyoring.dePrint || values.conveyoring.dePdf || values.conveyoring.enPrint || values.conveyoring.enPdf || values.conveyoring.frPrint || values.conveyoring.frPdf) {
      //     values.conveyoring.catalogue = true;
      //   }
      //   if (values.conveyoring.dePrintQty && values.conveyoring.dePrintQty >= 1 || values.conveyoring.enPrintQty && values.conveyoring.enPrintQty >= 1 || values.conveyoring.frPrintQty && values.conveyoring.frPrintQty >= 1) {
      //     values.conveyoring.interest = true;
      //     values.conveyoring.catalogue = true;
      //   }
      // }

      if (leadConsentUpdated) {
        values.leadConsentUpdated = leadConsentUpdated;
      }
      if (visitReason) {
        values.visitReason = visitReason;
      }
      if (image) {
        values.leadImage = image._id;
      }
      if (isNew) {
        values.leadConsentUpdated = moment();

        if (employeeName) {
          values.employeeName = employeeName;
        }
        if (salesManagerId) {
          values.salesManager = salesManagerId;
        }
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } catch (error) {
      notification.error({
        message: error.message,
        placement: 'bottomRight'
      });
    }
  };

  const leadImageHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'leadImage' });
  };
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });

    setImage(values);
  };

  function disabledDate(current) {
    current = moment(new Date(current).toLocaleString()).format('MM/DD/YYYY');
    if (selectedEvent && selectedEvent.startDate && selectedEvent.endDate) {
      selectedEvent.startDate = moment(new Date(selectedEvent.startDate).toLocaleString()).format(
        'MM/DD/YYYY'
      );
      selectedEvent.endDate = moment(new Date(selectedEvent.endDate).toLocaleString()).format(
        'MM/DD/YYYY'
      );
      return (current && current < selectedEvent.startDate) || current > selectedEvent.endDate;
    }
  }

  // const interests = [
  //   'shelving',
  //   'palletRacking',
  //   'cantilever',
  //   'multibloc',
  //   'rackInspection',
  //   'lighting',
  //   'conveyoring'
  // ];

  const productCategoriesState =
    allProductCategories && allProductCategories.data && allProductCategories.data.items;
  const shelving =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'shelving');
  const palletRacking =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'palletRacking');
  const cantilever =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'cantilever');
  const multibloc =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'multibloc');
  const rackInspection =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'rackInspection');
  const lighting =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'lighting');
  const conveyoring =
    productCategoriesState && productCategoriesState.find((item) => item.name === 'conveyoring');
  const productCategories = {
    shelving: shelving,
    palletRacking: palletRacking,
    cantilever: cantilever,
    multibloc: multibloc,
    rackInspection: rackInspection,
    lighting: lighting,
    conveyoring: conveyoring
  };

  const interests = [];
  productCategoriesState?.forEach((item) => {
    interests.push(item.name);
  });

  console.log(data);

  return (
    <div className='panel panel-body'>
      <Form
        className='form-horizontal'
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
        layout='vertical'
        form={form}>
        <div className='event-report__form-group event-report__form-group-basic-info'>
          <Form.Item
            name='event'
            label={t('event')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Select
              placeholder={t('event')}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => setSelectedEventId(value)}>
              {eventSelect.data?.items?.map((eventOption) => {
                return (
                  <Select.Option key={eventOption._id} value={eventOption._id}>
                    {eventOption.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name='company'
            label={t('company')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
          <div className='event-report__inline-group'>
            <Form.Item
              name='name'
              label={t('firstName')}
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name='lastName'
              label={t('lastName')}
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name='jobFunction'
            label={t('jobFunction')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='street'
            label={t('street')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='zipCode'
            label={t('zipCode')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='city'
            label={t('city')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='country'
            label={t('country')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            {/* <CountrySelector country={country} setCountry={setCountry} /> */}
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              // placeholder={t('country')}
              getPopupContainer={(trigger) => trigger.parentNode}>
              {options.map((countryOption) => (
                <Select.Option key={countryOption.value} value={countryOption.label}>
                  {countryOption.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='phoneNumber'
            label={t('phoneNumber')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='email'
            label={t('email')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
          </Form.Item>
        </div>
        <div className='event-report__form-group event-report__form-group-basic-info'>
          <Form.Item label={t('visitCardImage')}>
            <Button onClick={leadImageHandler}>
              <UploadOutlined /> {t('uploadImage')}
            </Button>

            {image && image.originalname ? (
              <div>
                <p>{image.originalname}</p>
              </div>
            ) : (
              <p>{data?.leadImage?.originalname}</p>
            )}
          </Form.Item>
          <Form.Item
            name='date'
            label={t('date')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
          {/* <Form.Item name='newLead' label={t('newLead')}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item name='newLead' valuePropName='checked'>
            <Checkbox>{t('newLead')}</Checkbox>
          </Form.Item>
          {/* <Form.Item name='dealer' label={t('dealer')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item name='dealer' valuePropName='checked'>
            <Checkbox>{t('dealer')}</Checkbox>
          </Form.Item>
          {/* <Form.Item name='project' label={t('project')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item name='project' valuePropName='checked'>
            <Checkbox>{t('project')}</Checkbox>
          </Form.Item>
          <Form.Item name='metaCustomerCode' label={t('metaCustomerCode')}>
            <Input />
          </Form.Item>
        </div>
        <div className='event-report__form-group event-report__form-group-interests'>
          <h2>{t('mainPointOfInterest')}</h2>
          <div className='interests'>
            {interests?.map((interest) => (
              <div key={interest._id} className='interest'>
                <h2>{t(interest)}</h2>
                <Collapse>
                  {catalogues &&
                    catalogues.data &&
                    catalogues.data.items &&
                    catalogues.data.items?.map((catalogue) => {
                      if (catalogue.catalogueCategory.name === interest) {
                        return (
                          <Panel header={catalogue.name}>
                            <div>
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'dePrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.dePrint === undefined || !catalogue.dePrint
                                      }>
                                      {t('dePrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'dePrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.dePrint === undefined || !catalogue.dePrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'dePdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.dePdf === undefined || !catalogue.dePdf}>
                                      {t('dePdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'dePdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.dePdf === undefined || !catalogue.dePdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'enPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.enPrint === undefined || !catalogue.enPrint
                                      }>
                                      {t('enPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'enPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.enPrint === undefined || !catalogue.enPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'enPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.enPdf === undefined || !catalogue.enPdf}>
                                      {t('enPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'enPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.enPdf === undefined || !catalogue.enPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'frPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.frPrint === undefined || !catalogue.frPrint
                                      }>
                                      {t('frPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'frPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.frPrint === undefined || !catalogue.frPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'frPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.frPdf === undefined || !catalogue.frPdf}>
                                      {t('frPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'frPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.frPdf === undefined || !catalogue.frPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'nlPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.nlPrint === undefined || !catalogue.nlPrint
                                      }>
                                      {t('nlPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'nlPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.nlPrint === undefined || !catalogue.nlPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'nlPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.nlPdf === undefined || !catalogue.nlPdf}>
                                      {t('nlPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'nlPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.nlPdf === undefined || !catalogue.nlPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'dEnPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.dEnPrint === undefined || !catalogue.dEnPrint
                                      }>
                                      {t('dEnPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'dEnPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.dEnPrint === undefined || !catalogue.dEnPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'dEnPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.dEnPdf === undefined || !catalogue.dEnPdf
                                      }>
                                      {t('dEnPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'dEnPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.dEnPdf === undefined || !catalogue.dEnPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'esPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.esPrint === undefined || !catalogue.esPrint
                                      }>
                                      {t('esPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'esPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.esPrint === undefined || !catalogue.esPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'esPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.esPdf === undefined || !catalogue.esPdf}>
                                      {t('esPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'esPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.esPdf === undefined || !catalogue.esPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'plPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.plPrint === undefined || !catalogue.plPrint
                                      }>
                                      {t('plPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'plPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.plPrint === undefined || !catalogue.plPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'plPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.plPdf === undefined || !catalogue.plPdf}>
                                      {t('plPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'plPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.plPdf === undefined || !catalogue.plPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'sePrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.sePrint === undefined || !catalogue.sePrint
                                      }>
                                      {t('sePrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'sePrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.sePrint === undefined || !catalogue.sePrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'sePdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.sePdf === undefined || !catalogue.sePdf}>
                                      {t('sePdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'sePdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.sePdf === undefined || !catalogue.sePdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                              <div className='catalogue-group'>
                                <div className='catalogue-print'>
                                  <Form.Item
                                    name={[catalogue._id, 'dkPrint']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={
                                        catalogue.dkPrint === undefined || !catalogue.dkPrint
                                      }>
                                      {t('dkPrint')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'dkPrintQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={
                                        catalogue.dkPrint === undefined || !catalogue.dkPrint
                                      }
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                                <div className='catalogue-pdf'>
                                  <Form.Item
                                    name={[catalogue._id, 'dkPdf']}
                                    valuePropName='checked'
                                    className='interest__catalogue-first'>
                                    <Checkbox
                                      disabled={catalogue.dkPdf === undefined || !catalogue.dkPdf}>
                                      {t('dkPdf')}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    name={[catalogue._id, 'dkPdfQty']}
                                    className='interest__catalogue-second'>
                                    <InputNumber
                                      disabled={catalogue.dkPdf === undefined || !catalogue.dkPdf}
                                      min={0}
                                      placeholder={t('quantity')}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/*catalogue group end*/}
                            </div>
                          </Panel>
                        );
                      } else {
                        return;
                      }
                    })}
                </Collapse>
              </div>
            ))}
          </div>

          {/* <h3 className='choose-catalogue-title'>{t('catalogueType')}:</h3>
          <Form.Item className='choose-catalogue-item' name="catalogueType" rules={[
            {
              required: true,
              message: t('validationAlert'),
            },
          ]}>
            <Radio.Group onChange={handleCatalogueType} value={catalogueType}>
              <Space direction="horizontal">
                <Radio value="PDF">PDF</Radio>
                <Radio value="mailCatalogue">{t('mailCatalogue')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item> */}
        </div>
        <div className='event-report__form-group'>
          <h2>{t('visitReason')}:</h2>
          <Form.Item
            name='visitReason'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Radio.Group onChange={handleVisitReason} value={visitReason}>
              <Space direction='horizontal'>
                <Radio value='unplannedVisitReason'>{t('unplannedVisitReason')}</Radio>
                <Radio value='invitationVisitReason'>{t('invitationVisitReason')}</Radio>
                <Radio value='detailedRequestVisitReason'>{t('detailedRequestVisitReason')}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <h2>{t('followUp')}:</h2>
          <Form.Item name='followUp'>
            <Select getPopupContainer={(trigger) => trigger.parentNode} onChange={handleFollowUp}>
              <Select.Option key='sales' value='sales'>
                {t('sales')}
              </Select.Option>
              <Select.Option key='purchasing' value='purchasing'>
                {t('purchasing')}
              </Select.Option>
              <Select.Option key='other' value='other'>
                {t('other')}
              </Select.Option>
            </Select>
          </Form.Item>
          {isNew
            ? followUp &&
              followUp === 'other' && (
                <Form.Item name='followUpOther' label={t('other')}>
                  <Input maxLength={60} />
                </Form.Item>
              )
            : data.followUpOther && (
                <Form.Item name='followUpOther'>
                  <Input maxLength={60} />
                </Form.Item>
              )}
          <h2>{t('whatAreTheNextSteps')}</h2>
          <Form.Item name='arrangeVisit' valuePropName='checked'>
            <Checkbox>{t('arrangeVisit')}</Checkbox>
          </Form.Item>
          <Collapse>
            <Panel header={t('privacyStatementLabel')}>
              <p>{t('privacyStatement')}</p>
            </Panel>
          </Collapse>
          <Form.Item
            name='leadConsent'
            valuePropName='checked'
            onChange={handleLeadConsent}
            value={leadConsent}
            className='event-report-consent-checkbox'>
            <Checkbox>{t('privacyAgreement')}</Checkbox>
          </Form.Item>
          <Form.Item hidden name='leadConsentUpdated' value={leadConsentUpdated}>
            <Input />
          </Form.Item>
        </div>
        <div className='event-report__form-group'>
          <Form.Item name='note' label={t('note') + ':'}>
            <TextArea maxLength={500} />
          </Form.Item>
        </div>
        <div className='text-right'>
          <Button type='primary' htmlType='submit'>
            {isNew ? t('addEventReport') : t('save')}
          </Button>
        </div>
      </Form>
      {modal.visible && (
        <GalleryModal
          className='gallery-modal'
          visible={modal.visible}
          limit={modal.limit}
          formKey={modal.formKey}
          imageSavePath='public/images/users/'
          imageType='data'
          imageHeight={200}
          SERVER_URL={SERVER_URL}
          token={token}
          form={form}
          onCancel={() => setModal({ ...modal, visible: false })}
          onInsert={(values) => onInsert(values, modal.formKey)}
        />
      )}
    </div>
  );
};

export default withNamespaces()(EventReportForm);

{
  /* <div className='interest__catalogue'>
  <div>
    <Form.Item name={['shelving', 'dePrint']} valuePropName="checked"  className='interest__catalogue-first'>
      <Checkbox disabled={shelvingProductCategory && shelvingProductCategory.dePrint}>{t('dePrint')}</Checkbox>
    </Form.Item>
    <Form.Item name={['shelving', 'dePrintQty']} valuePropName="checked" className='interest__catalogue-second'>
      <InputNumber disabled={shelvingProductCategory && shelvingProductCategory.dePrint} min={0} placeholder={t('quantity')} />
    </Form.Item>
  </div>
  <div>
    <Form.Item name={['shelving', 'dePdf']} valuePropName="checked"  className='interest__catalogue-first'>
      <Checkbox disabled={shelvingProductCategory && shelvingProductCategory.dePdf}>{t('dePdf')}</Checkbox>
    </Form.Item>
    <Form.Item name={['shelving', 'dePdfQty']} valuePropName="checked" className='interest__catalogue-second'>
      <InputNumber disabled={shelvingProductCategory && shelvingProductCategory.dePdf} min={0} placeholder={t('quantity')} />
    </Form.Item>
  </div>
  <div>
    <Form.Item name={['shelving', 'enPrint']} valuePropName="checked"  className='interest__catalogue-first'>
      <Checkbox disabled={shelvingProductCategory && shelvingProductCategory.enPrint}>{t('enPrint')}</Checkbox>
    </Form.Item>
    <Form.Item name={['shelving', 'enPrintQty']} valuePropName="checked" className='interest__catalogue-second'>
      <InputNumber disabled={shelvingProductCategory && shelvingProductCategory.enPrint} min={0} placeholder={t('quantity')} />
    </Form.Item>
  </div>
  <div>
    <Form.Item name={['shelving', 'enPdf']} valuePropName="checked"  className='interest__catalogue-first'>
      <Checkbox disabled={shelvingProductCategory && shelvingProductCategory.enPdf}>{t('enPdf')}</Checkbox>
    </Form.Item>
    <Form.Item name={['shelving', 'enPdfQty']} valuePropName="checked" className='interest__catalogue-second'>
      <InputNumber disabled={shelvingProductCategory && shelvingProductCategory.enPdf} min={0} placeholder={t('quantity')} />
    </Form.Item>
  </div>
  <div>
    <Form.Item name={['shelving', 'frPrint']} valuePropName="checked"  className='interest__catalogue-first'>
      <Checkbox disabled={shelvingProductCategory && shelvingProductCategory.enPrint}>{t('frPrint')}</Checkbox>
    </Form.Item>
    <Form.Item name={['shelving', 'frPrintQty']} valuePropName="checked" className='interest__catalogue-second'>
      <InputNumber disabled={shelvingProductCategory && shelvingProductCategory.frPrint} min={0} placeholder={t('quantity')} />
    </Form.Item>
  </div>
  <div>
    <Form.Item name={['shelving', 'frPdf']} valuePropName="checked"  className='interest__catalogue-first'>
      <Checkbox disabled={shelvingProductCategory && shelvingProductCategory.frPdf}>{t('frPdf')}</Checkbox>
    </Form.Item>
    <Form.Item name={['shelving', 'frPdfQty']} valuePropName="checked" className='interest__catalogue-second'>
      <InputNumber disabled={shelvingProductCategory && shelvingProductCategory.frPdf} min={0} placeholder={t('quantity')} />
    </Form.Item>
  </div>
</div> */
}
