import React, { useContext, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const formInit = {
  name: undefined,
  location: undefined,
  startDate: undefined,
  endDate: undefined,
};

const ViewEvent = (props) => {
  const [form] = Form.useForm();
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [event, fetchEvent] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchEvent(`${SERVER_URL}/events/${id}`, []);
    }
  }, [id, fetchEvent]);

  if (event && event.data) {
    form.setFieldsValue({
      name: event.data.name,
      location: event.data.location,
      startDate: moment(new Date(event.data.endDate).toLocaleString()).format('MM/DD/YYYY'),
      endDate: moment(new Date(event.data.endDate).toLocaleString()).format('MM/DD/YYYY'),
    });
  }

  let initialValues = { ...formInit };

  return (
    <div className='dashboard'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => window.location.href = '/admin/events'} type='primary'>
          {t('events')}
        </Button>
      </div>
      <div className='card-wrapper'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          layout='vertical'
          form={form}
        >
          <Form.Item name='name' label={t('eventName')}>
            <Input disabled className='eventField' />
          </Form.Item>
          <Form.Item name='location' label={t('eventLocation')}>
            <Input disabled className='eventField' />
          </Form.Item>
          <Form.Item name='startDate' label={t('startDate')}>
            <Input disabled className='eventField' />
          </Form.Item>
          <Form.Item name='endDate' label={t('endDate')}>
            <Input disabled className='eventField' />
          </Form.Item>
        </Form>

      </div>
    </div>
  );
};

export default withNamespaces()(ViewEvent);