import React, { useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  TimePicker
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import UploadSketch from '../base/UploadSketch';
import { withNamespaces } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../../App';
import i18n from '../../i18n';
import EmbedImage from '../base/EmbedImage';

const ProjectDescriptionSheetForm = ({
  data,
  files,
  setFiles,
  form,
  options,
  projectManagers,
  t
}) => {
  const currentuser = useContext(UserContext);
  const [leveling, setLeveling] = useState(data && data.leveling);
  const [assembly, setAssembly] = useState(data && data.assembly);
  const [constructionRoad, setConstructionRoad] = useState(data && data.constructionRoad);
  const [productionLine, setProductionLine] = useState(data && data.productionLine);
  const [gate, setGate] = useState(data && data.gate);
  const [obstacles, setObstacles] = useState(data && data.obstacles);
  const [auxiliaryStuff, setAuxiliaryStuff] = useState(data && data.auxiliaryStuff);
  const [liftTruck, setLiftTruck] = useState(data && data.liftTruck);
  const [forkliftTruck, setForkliftTruck] = useState(data && data.forkliftTruck);
  const [elevator, setElevator] = useState(data && data.elevator);
  const [truckWithPlatform, setTruckwithPlatform] = useState(data && data.truckWithPlatform);
  const [truckWithForklift, setTruckWithForklift] = useState(data && data.truckWithForklift);
  const [rampPlatform, setRampPlatform] = useState(data && data.rampPlatform);
  const [floorHeating, setFloorHeating] = useState(data && data.floorHeating);
  const [ramp, setRamp] = useState(data && data.ramp);
  const [gateUnloading, setGateUnloading] = useState(data && data.gateUnloading);
  const [door, setDoor] = useState(data && data.door);
  const [yardUnloading, setYardUnloading] = useState(data && data.yardUnloading);
  const [safetyInstructions, setSafetyInstructions] = useState(data && data.safetyInstruction);
  const [waterManagement, setWaterManagement] = useState(data && data.waterManagement);
  const [scissorLift, setScissorLift] = useState(data && data.scissorLift);
  const [scissorLiftSize, setScissorLiftSize] = useState(data && data.scissorLiftSize);
  const [hall, setHall] = useState(data && data.hall);
  const [unhinderedDrilling, setUnhinderedDrilling] = useState(data && data.unhinderedDrilling);
  const METACountries =
    i18n.language === 'German'
      ? [
          { name: 'Deutschland', value: 'Germany' },
          { name: 'Schweiz', value: 'Switzerland' },
          { name: 'Italien', value: 'Italy' },
          { name: 'Frankreich', value: 'France' },
          { name: 'Spanien', value: 'Spain' },
          { name: 'Portugal', value: 'Portugal' },
          { name: 'Vereinigtes Königreich', value: 'United Kingdom' },
          { name: 'Tschechien', value: 'Czech Republic' },
          { name: 'Polen', value: 'Poland' },
          { name: 'Rumänien', value: 'Romania' },
          { name: 'Niederlande', value: 'Netherlands' },
          { name: 'Belgien', value: 'Belgium' },
          { name: 'Luxemburg', value: 'Luxembourg' },
          { name: 'Österreich', value: 'Austria' },
          { name: 'USA', value: 'USA' },
          { name: 'Serbien', value: 'Serbia' },
          { name: 'Kroatien', value: 'Croatia' },
          { name: 'Bosnien', value: 'Bosnia' },
          { name: 'Mazedonien', value: 'Macedonia' },
          { name: 'Litauen', value: 'Lithuania' },
          { name: 'Estland', value: 'Estonia' },
          { name: 'Lettland', value: 'Latvia' },
          { name: 'Ukraine', value: 'Ukraine' },
          { name: 'Ungarn', value: 'Hungary' },
          { name: 'Slowenien', value: 'Slovenia' },
          { name: 'Slowakei', value: 'Slovakia' },
          { name: 'Irland', value: 'Ireland' }
        ]
      : [
          { name: 'Germany', value: 'Germany' },
          { name: 'Switzerland', value: 'Switzerland' },
          { name: 'Italy', value: 'Italy' },
          { name: 'France', value: 'France' },
          { name: 'Spain', value: 'Spain' },
          { name: 'Portugal', value: 'Portugal' },
          { name: 'United Kingdom', value: 'United Kingdom' },
          { name: 'Czech Republic', value: 'Czech Republic' },
          { name: 'Poland', value: 'Poland' },
          { name: 'Romania', value: 'Romania' },
          { name: 'Netherlands', value: 'Netherlands' },
          { name: 'Belgium', value: 'Belgium' },
          { name: 'Luxembourg', value: 'Luxembourg' },
          { name: 'Austria', value: 'Austria' },
          { name: 'USA', value: 'USA' },
          { name: 'Serbia', value: 'Serbia' },
          { name: 'Croatia', value: 'Croatia' },
          { name: 'Bosnia', value: 'Bosnia' },
          { name: 'Macedonia', value: 'Macedonia' },
          { name: 'Lithuania', value: 'Lithuania' },
          { name: 'Estonia', value: 'Estonia' },
          { name: 'Latvia', value: 'Latvia' },
          { name: 'Ukraine', value: 'Ukraine' },
          { name: 'Hungary', value: 'Hungary' },
          { name: 'Slovenia', value: 'Slovenia' },
          { name: 'Slovakia', value: 'Slovakia' },
          { name: 'Ireland', value: 'Ireland' }
        ];

  const copyAddress = () => {
    form.setFieldsValue({
      shippingCompany: form.getFieldValue('billingCompany'),
      shippingStreet: form.getFieldValue('billingStreet'),
      shippingPostalCode: form.getFieldValue('billingPostalCode'),
      shippingCity: form.getFieldValue('billingCity'),
      shippingContactPerson: form.getFieldValue('billingContactPerson'),
      shippingDepartment: form.getFieldValue('billingDepartment'),
      shippingPhone: form.getFieldValue('billingPhone'),
      shippingEmail: form.getFieldValue('billingEmail'),
      shippingAppointment: form.getFieldValue('billingAppointment')
    });
  };

  /* function disableAssemblyDate(current) {
    return current && current.valueOf() < form.getFieldValue('deliveryDate');
  } */

  return (
    <div>
      <h3 id='project-description-sheet'>{t('projDescSheet')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='customerNumber' label={`${t('projDescSheet_customerNumber')}:`}>
            <Input type='number' placeholder={`${t('projDescSheet_customerNumber')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='customerName'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_customerName')}:`}>
            <Input placeholder={`${t('projDescSheet_customerName')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='customerCountry'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_customerCountry')}:`}>
            <Select
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
              placeholder={`${t('projDescSheet_customerCountry')}`}
              // getPopupContainer={(trigger) => trigger.parentNode}
            >
              {/*options.map((countryOption) => (
                <Select.Option key={countryOption.value} value={countryOption.label}>
                  {countryOption.label}
                </Select.Option>
              ))*/}
              {METACountries.map((countryOption) => (
                <Select.Option key={Math.random()} value={countryOption.value}>
                  {countryOption.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='customerRequestNo'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_customerRequestNumber')}:`}>
            <Input placeholder={`${t('projDescSheet_customerRequestNumber')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='quotationNo' label={`${t('projDescSheet_quotationNumberMETA')}:`}>
            <Input placeholder={`${t('projDescSheet_quotationNumberMETA')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='dealerOrderNo' label={`${t('projDescSheet_dealerOrderNumber')}:`}>
            <Input placeholder={`${t('projDescSheet_dealerOrderNumber')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='endCustomerOrderNo'
            label={`${t('projDescSheet_endCustomerOrderNumber')}:`}>
            <Input placeholder={`${t('projDescSheet_endCustomerOrderNumber')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='orderNo' label={`${t('projDescSheet_orderNumberMETA')}:`}>
            <Input type='number' placeholder={`${t('projDescSheet_orderNumberMETA')}`} />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('projDescSheet_billingAddress')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingCompany'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('company')}:`}>
            <Input placeholder={`${t('company')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingStreet'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('street')}:`}>
            <Input placeholder={`${t('street')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingPostalCode'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('zipCode')}:`}>
            <Input placeholder={`${t('zipCode')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingCity'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('city')}:`}>
            <Input placeholder={`${t('city')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingContactPerson'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_contactPerson')}:`}>
            <Input placeholder={`${t('projDescSheet_contactPerson')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='billingDepartment' label={`${t('projDescSheet_department')}:`}>
            <Input placeholder={`${t('projDescSheet_department')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingPhone'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_phone/mobile')}:`}>
            <Input placeholder={`${t('projDescSheet_phone/mobile')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='billingEmail'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('email')}:`}>
            <Input placeholder={`${t('email')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name='billingAppointment'
        valuePropName='checked'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox className='pbbCheckbox'>{t('projDescSheet_customerRequestConfirm')}: </Checkbox>
      </Form.Item>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('projDescSheet_shippingAddress')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Button onClick={copyAddress} style={{ marginBottom: '10px' }}>
        {t('projDescSheet_sameAsAbove')}
      </Button>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingCompany'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('company')}:`}>
            <Input placeholder={`${t('company')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingStreet'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('street')}:`}>
            <Input placeholder={`${t('street')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingPostalCode'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('zipCode')}:`}>
            <Input placeholder={`${t('zipCode')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingCity'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('city')}:`}>
            <Input placeholder={`${t('city')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingContactPerson'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_autherizedAcceptor')}:`}>
            <Input placeholder={`${t('projDescSheet_autherizedAcceptor')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='shippingDepartment' label={`${t('projDescSheet_department')}:`}>
            <Input placeholder={`${t('projDescSheet_department')}:`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingPhone'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_phone/mobile')}:`}>
            <Input placeholder={`${t('projDescSheet_phone/mobile')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='shippingEmail'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('email')}:`}>
            <Input placeholder={`${t('email')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name='shippingAppointment'
        valuePropName='checked'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox className='pbbCheckbox'>{t('projDescSheet_customerRequestConfirm')}: </Checkbox>
      </Form.Item>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('projDescSheet_generalInformation')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      {!currentuser.data.role.includes('externalUser') && (
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item name='projectManager' label={`${t('projDescSheet_projectManager')}:`}>
              <Select
                placeholder={`${t('projDescSheet_projectManager')}`}
                filterSort={(optionA, optionB) =>
                  optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                }>
                {projectManagers
                  .filter((item) => !item.role.includes('salesAgent'))
                  .map((manager) => (
                    <Select.Option value={manager._id}>
                      {manager.lastName} {manager.firstName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name='territoryManager'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}
              label={`${t('projDescSheet_territoryManager')}:`}>
              <Select
                placeholder={`${t('projDescSheet_territoryManager')}`}
                filterSort={(optionA, optionB) =>
                  optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
                }>
                {projectManagers
                  .filter((item) =>
                    ['salesAgent', 'salesManager'].some((role) => item.role.includes(role))
                  )
                  .map((manager) => (
                    <Select.Option value={manager._id}>
                      {manager.lastName} {manager.firstName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col xs={24} md={6} className='generalInformationField'>
          <Form.Item
            name='dateAppointment'
            label={`${t('projDescSheet_dateOnSiteAppointment')}:`}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <DatePicker placeholder={t('selectDate')} format='DD.MM.YYYY' />
          </Form.Item>
        </Col>
        <Col xs={24} md={6} className='generalInformationField'>
          <Form.Item
            name='requestDate'
            label={`${t('projDescSheet_requestDate')}:`}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <DatePicker placeholder={t('selectDate')} format='DD.MM.YYYY' />
          </Form.Item>
        </Col>
        <Col xs={24} md={6} className='generalInformationField'>
          <Form.Item
            name='offerDeadline'
            label={`${t('projDescSheet_offerDeadline')}:`}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <DatePicker placeholder={t('selectDate')} format='DD.MM.YYYY' />
          </Form.Item>
        </Col>
        <Col xs={24} md={6} className='generalInformationField'>
          <Form.Item
            name='realizationDate'
            label={`${t('projDescSheet_dateOfRealization')}:`}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <DatePicker placeholder={t('selectDate')} format='DD.MM.YYYY' />
          </Form.Item>
        </Col>
      </Row>
      <p className='pbbText'>{t('projDescSheet_detailProjectDescriptionMessage')}</p>
      <div className='pbbPenalty'>
        <span>{t('projDescSheet_penalty')}</span>
        <Form.Item
          name='penalty'
          style={{ marginBottom: '0' }}
          valuePropName='checked'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox style={{ flexDirection: 'row-reverse' }}>{t('yes')}</Checkbox>
        </Form.Item>
      </div>
      <div className='penaltyText'>
        <span>{t('projDescSheet_penaltyRate')}</span>
      </div>
      <Form.Item
        name='generalGuarantees'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}
        label={`${t('projDescSheet_generalGuarantees')}:`}>
        <Input placeholder={`${t('projDescSheet_generalGuarantees')}`} />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='insurance'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox className='pbbCheckbox'>{t('projDescSheet_insurance')}</Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='tipComission' valuePropName='checked'>
            <Checkbox className='pbbCheckbox'>{t('projDescSheet_tipCommission')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='competition'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_competition')}:`}>
            <Input placeholder={`${t('projDescSheet_competition')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='orderChance'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_orderChanceIn%')}:`}>
            <Input type='number' placeholder={`${t('projDescSheet_orderChanceIn%')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card className='pbbCard' title={`${t('projDescSheet_budgetPlanning')}:`}>
            <Form.Item
              name='budgetPlanning'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>
          {t('projDescSheet_levelingAndStructuralConditions')} ({t('important')}!):
        </h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='seismicZone'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_seismicZone')}:`}>
            <Input placeholder={`${t('projDescSheet_seismicZone')}`} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='undergroundClass'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_undergroundClass')}:`}>
            <Input placeholder={`${t('projDescSheet_undergroundClass')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name='floorCondition'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}
        label={`${t('projDescSheet_floorCondition')}:`}>
        <Input.TextArea rows={2} placeholder={`${t('projDescSheet_floorCondition')}`} />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_magnesiteFlooring')}:`}>
            <Form.Item
              name='magnesiteFlooring'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          {(localStorage.getItem('language') === 'German' || i18n.language === 'German') && (
            <Card className='pbbCard' title='Wasserhaushaltsgesetz:'>
              <Form.Item
                name='waterManagement'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group onChange={(event) => setWaterManagement(event.target.value)}>
                  <Space direction='horizontal'>
                    <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                      {t('yes')}
                    </Radio>
                    <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                      {t('no')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {waterManagement === 'yes' && (
                <div>
                  <Form.Item name='coatedFloor' valuePropName='checked'>
                    <Checkbox className='pbbCheckbox'>Beschichteter Boden</Checkbox>
                  </Form.Item>
                  <Form.Item name='foil' valuePropName='checked'>
                    <Checkbox className='pbbCheckbox'>Folie</Checkbox>
                  </Form.Item>
                </div>
              )}
            </Card>
          )}
          <Card className='pbbCard' title={`${t('projDescSheet_drilling130mm')}:`}>
            <>
              <Form.Item
                name='unhinderedDrilling'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group onChange={(event) => setUnhinderedDrilling(event.target.value)}>
                  <Space direction='horizontal'>
                    <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                      {t('yes')}
                    </Radio>
                    <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                      {t('no')}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              {unhinderedDrilling === 'no' && (
                <Card
                  className='pbbCard'
                  title={`${t('projDescSheet_drillingDepth')}`}
                  style={{ marginTop: '0.625rem' }}>
                  <Form.Item
                    name='drillingDepth'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}>
                    <Input
                      type='number'
                      placeholder={`${t('projDescSheet_drillingDepth')}`}
                      suffix='mm'
                    />
                  </Form.Item>
                </Card>
              )}
            </>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_floorHeating')}:`}>
            <Form.Item
              name='floorHeating'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setFloorHeating(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {floorHeating === 'yes' && (
              <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
                {`${t('drillingWarning')}`}
              </p>
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_floorFlatness')}:`}>
            <Form.Item
              name='floorFlatness'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_needsLeveling')}?`}>
            <Form.Item
              name='leveling'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setLeveling(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {leveling === 'yes' && (
              <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
                <Form.Item
                  name='levelingExecutor'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}
                  style={{ marginBottom: '10px' }}>
                  <Input placeholder={`${t('projDescSheet_byWhom')}?`} />
                </Form.Item>
                <Form.Item
                  name='surcharge'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}
                  style={{ marginBottom: '10px' }}>
                  <Input placeholder={`${t('projDescSheet_surcharge')}:`} />
                </Form.Item>
                <Form.Item name='levelingInfo'>
                  <Input placeholder={`${t('projDescSheet_levelingInfo')}:`} />
                </Form.Item>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Form.Item
        name='additions'
        style={{ marginTop: '10px' }}
        // rules={[
        //   {
        //     required: true,
        //     message: t('validationAlert'),
        //   },
        // ]}
        label={`${t('projDescSheet_additions')}:`}>
        <Input placeholder={`${t('projDescSheet_additions')}`} />
      </Form.Item>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('projDescSheet_assemblyInf')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card
            className='pbbCard pbbAssembly'
            title={t('projDescSheet_assembly')}
            // extra={<Form.Item style={{ marginBottom: 0 }} name='assemblyAsOption' valuePropName="checked">
            //   <Checkbox disabled={assembly !== 'yes'} style={{ flexDirection: "row-reverse" }}>
            //     Calculate as an option
            //   </Checkbox>
            // </Form.Item>}
          >
            <Form.Item
              name='assembly'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setAssembly(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='no'>
                    {t('no')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='calculateOption'>
                    {t('projDescSheet_calculateOption')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='assemblyTeam'>
            <Input placeholder={`${t('projDescSheet_assemblyTeam')}`} />
          </Form.Item>
          <Form.Item name='assemblyInfo'>
            <Input.TextArea rows={3} placeholder={`${t('projDescSheet_assemblyInfo')}`} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item name='deliveryDate' label={t('projDescSheet_deliveryDate')}>
            <DatePicker placeholder={t('selectDate')} format='DD.MM.YYYY' />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item name='assemblyDate' label={t('projDescSheet_assemblyDate')}>
            <DatePicker
              placeholder={t('selectDate')}
              disabledDate={(current) =>
                current && current.valueOf() < form.getFieldValue('deliveryDate')
              }
              format='DD.MM.YYYY'
            />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('projDescSheet_workingHours')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16} align='middle' style={{ marginBottom: '24px' }}>
        <Col xs={24} md={4}>
          <p style={{ marginBottom: '0px' }}>{t('projDescSheet_goodsReceipt')}:</p>
        </Col>
        <Col xs={24} md={20}>
          <Form.Item
            name='goodsReceiptInfo'
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input placeholder={t('projcDescSheet_goodsReceiptInfo')} />
          </Form.Item>
        </Col>
        {/* <Col style={{ padding: 0, textAlign: 'right' }} xs={3} md={3}>
          <span>{t('from')}</span>
        </Col>
        <Col style={{ paddingRight: 0 }} xs={7} md={7}>
          <Form.Item
            name='goodsReceiptFrom'
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <TimePicker placeholder={t('selectTime')} format={'HH:mm'} />
          </Form.Item>
        </Col>
        <Col style={{ paddingRight: 0, textAlign: 'right' }} xs={3} md={3}>
          <span>{t('to')}</span>
        </Col>
        <Col xs={7} md={7}>
          <Form.Item
            name='goodsReceiptTo'
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input />
            <TimePicker placeholder={t('selectTime')} format={'HH:mm'} />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={16} align='middle' style={{ marginBottom: '24px' }}>
        <Col xs={24} md={4}>
          <p style={{ marginBottom: '0px' }}>
            {t('projDescSheet_workingHrsInstTeam')} ({t('projDescSheet_minWorkingHrsInstTeam')}):
          </p>
        </Col>
        <Col xs={24} md={20}>
          <Form.Item
            name='instalationHoursInfo'
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input placeholder={t('projDescSheet_workingHrsInstTeamInfo')} />
          </Form.Item>
        </Col>
        {/* <Col style={{ padding: 0, textAlign: 'right' }} xs={3} md={3}>
          <span>{t('from')}</span>
        </Col>
        <Col style={{ paddingRight: 0 }} xs={7} md={7}>
          <Form.Item
            name='instalationHoursFrom'
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <TimePicker placeholder={t('selectTime')} format={'HH:mm'} />
          </Form.Item>
        </Col>
        <Col style={{ paddingRight: 0, textAlign: 'right' }} xs={3} md={3}>
          <span>{t('to')}</span>
        </Col>
        <Col xs={7} md={7}>
          <Form.Item
            name='instalationHoursTo'
            style={{ marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <TimePicker placeholder={t('selectTime')} format={'HH:mm'} />
          </Form.Item>
        </Col> */}
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>
          {t('projDescSheet_access')} -{' '}
          <span style={{ color: '#E30613' }}>{t('carefullFillingWarning')}</span>{' '}
        </h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='constructionRoad'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox
              onChange={(event) => setConstructionRoad(event.target.checked)}
              className='pbbCheckbox'>
              {t('projDescSheet_constructionRoad')}:{' '}
            </Checkbox>
          </Form.Item>
          {constructionRoad && (
            <div>
              <Form.Item
                name='constructionRoadText'
                // rules={[
                //   {
                //     required: true,
                //     message: t('validationAlert'),
                //   },
                // ]}
              >
                <Input placeholder={t('specificationRoad')} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='constructionRoad' />
            </div>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='productionLine'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox
              onChange={(event) => setProductionLine(event.target.checked)}
              className='pbbCheckbox'>
              {t('projDescSheet_productionLine')}:{' '}
            </Checkbox>
          </Form.Item>
          {productionLine && (
            <div>
              <Form.Item
                name='productionLineText'
                // rules={[
                //   {
                //     required: true,
                //     message: t('validationAlert'),
                //   },
                // ]}
              >
                <Input placeholder={t('specificationLine')} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='productionLine' />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='gate'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert')
            //   }
            // ]}
          >
            <Checkbox onChange={(event) => setGate(event.target.checked)} className='pbbCheckbox'>
              {t('projDescSheet_gate')}:{' '}
            </Checkbox>
          </Form.Item>
          {gate && (
            <div>
              <Form.Item
                name='gateText'
                // rules={[
                //   {
                //     required: true,
                //     message: t('validationAlert'),
                //   },
                // ]}
              >
                <Input placeholder={t('specificationDoor')} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='gate' />
            </div>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='obstacles'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert')
            //   }
            // ]}
          >
            <Checkbox
              onChange={(event) => setObstacles(event.target.checked)}
              className='pbbCheckbox'>
              {t('projDescSheet_obstacles')}:{' '}
            </Checkbox>
          </Form.Item>
          {obstacles && (
            <div>
              <Form.Item
                name='obstaclesText'
                // rules={[
                //   {
                //     required: true,
                //     message: t('validationAlert'),
                //   },
                // ]}
              >
                <Input placeholder={t('obstaclesDesc')} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='obstacles' />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_unloading')}`}>
            <Form.Item
              name='unloading'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='Customer'>
                    {t('customer')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='META'>
                    META
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_consSiteUnload')}:`}>
            <Form.Item
              name='transport'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='Customer'>
                    {t('customer')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='META'>
                    META
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>
          {t('projDescSheet_unloadingPlace')} -{' '}
          <span style={{ color: '#E30613' }}>{t('carefullFillingWarning')}</span>{' '}
        </h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='ramp'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert'),
            //   },
            // ]}
          >
            <Checkbox onChange={(event) => setRamp(event.target.checked)} className='pbbCheckbox'>
              {t('projDescSheet_ramp')}:{' '}
            </Checkbox>
          </Form.Item>
          {ramp && (
            <div>
              <Form.Item
                name='rampInfo'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={`${t('projDescSheet_unloadingPlaceInfo')}`} />
              </Form.Item>
              {/* <Row gutter={10} align='middle' justify='space-between'>
                <Col xs={4} md={4}>
                  <span>{t('projDescSheet_ramp')}:</span>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name='rampWidth'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={1} md={1}>
                  <span>X</span>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name='rampHeight'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={7} md={7}>
                  <span>{t('measure')}</span>
                </Col>
              </Row> */}
              <UploadSketch data={data} files={files} setFiles={setFiles} name='ramp' />
            </div>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='rampPlatform'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox
              onChange={(event) => setRampPlatform(event.target.checked)}
              className='pbbCheckbox'>
              {t('projDescSheet_rampLiftingPlatform')}
            </Checkbox>
          </Form.Item>
          {rampPlatform && (
            <div>
              <Form.Item
                name='rampPlatformInfo'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={`${t('projDescSheet_unloadingPlaceInfo')}`} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='rampPlatform' />
            </div>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='gateUnloading'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert'),
            //   },
            // ]}
          >
            <Checkbox
              onChange={(event) => setGateUnloading(event.target.checked)}
              className='pbbCheckbox'>
              {t('projDescSheet_gate')}:{' '}
            </Checkbox>
          </Form.Item>
          {gateUnloading && (
            <div>
              {/* <Row gutter={10} align='middle' justify='space-between'>
                <Col xs={4} md={4}>
                  <span>{t('projDescSheet_gate')}:</span>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name='gateWidth'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={1} md={1}>
                  <span>X</span>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name='gateHeight'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={7} md={7}>
                  <span>{t('measure')}</span>
                </Col>
              </Row> */}
              <Form.Item
                name='gateInfo'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={`${t('projDescSheet_unloadingPlaceInfo')}`} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='gateUnloading' />
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='door'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert'),
            //   },
            // ]}
          >
            <Checkbox onChange={(event) => setDoor(event.target.checked)} className='pbbCheckbox'>
              {t('projDescSheet_door')}:
            </Checkbox>
          </Form.Item>
          {door && (
            <div>
              {/* <Row gutter={10} align='middle' justify='space-between'>
                <Col xs={4} md={4}>
                  <span>{t('projDescSheet_door')}:</span>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name='doorWidth'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={1} md={1}>
                  <span>X</span>
                </Col>
                <Col xs={24} md={6}>
                  <Form.Item
                    name='doorHeight'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={7} md={7}>
                  <span>{t('measure')}</span>
                </Col>
              </Row> */}
              <Form.Item
                name='doorInfo'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={`${t('projDescSheet_unloadingPlaceInfo')}`} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='door' />
            </div>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='hallUnloading'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert'),
            //   },
            // ]}
          >
            <Checkbox style={{ backgroundColor: '#f1f1f1' }} className='pbbCheckbox'>
              {t('projDescSheet_hallUnloading')}{' '}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='yardUnloading'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t('validationAlert'),
            //   },
            // ]}
          >
            <Checkbox
              onChange={(event) => setYardUnloading(event.target.checked)}
              className='pbbCheckbox'>
              {t('projDescSheet_yardUnloading')}:
            </Checkbox>
          </Form.Item>
          {yardUnloading && (
            <div>
              {/* <Row gutter={10} align='middle' justify='space-between'>
                <Col xs={4} md={4}>
                  <span>{t('projDescSheet_yardUnloading')}:</span>
                </Col>
                <Col xs={4} md={4}>
                  <Form.Item
                    name='yardUnloadingWidth'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={1} md={1}>
                  <span>X</span>
                </Col>
                <Col xs={4} md={4}>
                  <Form.Item
                    name='yardUnloadingHeight'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <span>{t('measure')}</span>
                </Col>
              </Row> */}
              <Form.Item
                name='yardInfo'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={`${t('projDescSheet_unloadingPlaceInfo')}`} />
              </Form.Item>
              <UploadSketch data={data} files={files} setFiles={setFiles} name='yardUnloading' />
            </div>
          )}
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_customerAssistants')}?`}>
            <Form.Item
              name='auxiliaryStuff'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setAuxiliaryStuff(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {auxiliaryStuff === 'yes' && (
              <Form.Item
                name='auxiliaryStuffQty'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input type='number' placeholder={`${t('quantity')}`} />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('projDescSheet_liftingEquipment')} </h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_liftTruck')}`}>
            <Form.Item
              name='liftTruck'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setLiftTruck(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Not required'>
                    {t('projDescSheet_notRequired')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Customer'>
                    {t('customer')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='META'>
                    META
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {/* {(liftTruck === 'Customer' || liftTruck === 'META') && (
              <div style={{ marginTop: '1rem' }}>
                <Form.Item
                  name='bearingCapacity'
                >
                  <Input type='number' placeholder={`${t('projDescSheet_bearingCapacity')}:`} suffix='kg' />
                </Form.Item>
                <Form.Item
                  style={{ marginTop: '1rem' }}
                  name='forkLength'
                >
                  <Input type='number' placeholder={`${t('projDescSheet_forkLength')}:`} suffix='mm' />
                </Form.Item>
              </div>
            )} */}
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_forklift')}`}>
            <Form.Item
              name='forkliftTruck'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setForkliftTruck(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Not required'>
                    {t('projDescSheet_notRequired')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Customer'>
                    {t('customer')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='META'>
                    META
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {(forkliftTruck === 'Customer' || forkliftTruck === 'META') && (
              <div style={{ marginTop: '1rem' }}>
                <Card className='pbbCard' title={`${t('projDescSheet_isCapacity1500kg')}:`}>
                  <Form.Item name='loadCapacity'>
                    <Radio.Group>
                      <Space direction='horizontal'>
                        <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                          {t('yes')}
                        </Radio>
                        <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                          {t('no')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Card>
                <Form.Item name='liftingHeight' label={`${t('projDescSheet_liftingHeight')}:`}>
                  <Input
                    type='number'
                    placeholder={`${t('projDescSheet_liftingHeight')}:`}
                    suffix='mm'
                  />
                </Form.Item>
                <Card
                  className='pbbCard'
                  title={`${t('projDescSheet_driver')}?`}
                  style={{ marginTop: '1rem' }}>
                  <Form.Item name='driver'>
                    <Radio.Group>
                      <Space direction='horizontal'>
                        <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                          {t('yes')}
                        </Radio>
                        <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                          {t('no')}
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Form.Item
        className='pbbCheckboxSecond'
        name='elevator'
        valuePropName='checked'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox onChange={(event) => setElevator(event.target.checked)}>
          {t('projDescSheet_elevator')}
        </Checkbox>
      </Form.Item>
      {elevator && (
        <div>
          <Form.Item
            name='elevatorType'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_elevatorType')}:`}>
            <Input placeholder={`${t('projDescSheet_elevatorType')}`} />
          </Form.Item>
          <Form.Item
            name='bearingCapacityElevator'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('projDescSheet_bearingCapacity')}:`}>
            <Input
              type='number'
              placeholder={`${t('projDescSheet_bearingCapacity')}`}
              suffix='kg'
            />
          </Form.Item>
          {/* <Row gutter={10} align='middle' justify='space-between'>
            <Col xs={4} md={4}>
              <span>{t('projDescSheet_cabin')}:</span>
            </Col>
            <Col xs={4} md={4}>
              <Form.Item
                name='cabinLength'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert'),
                  },
                ]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col xs={1} md={1}>
              <span>X</span>
            </Col>
            <Col xs={4} md={4}>
              <Form.Item
                name='cabinWidth'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert'),
                  },
                ]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col xs={1} md={1}>
              <span>X</span>
            </Col>
            <Col xs={4} md={4}>
              <Form.Item
                name='cabinHeigth'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert'),
                  },
                ]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <span style={{ fontSize: '12px' }}>({t('projDescSheet_cabinSizeLegend')})</span>
            </Col>
          </Row> */}
        </div>
      )}
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            className='pbbCheckboxSecond'
            name='truckWithPlatform'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox onChange={(event) => setTruckwithPlatform(event.target.checked)}>
              {t('projDescSheet_liftingPlatformTruck')}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            className='pbbCheckboxSecond'
            name='truckWithForklift'
            valuePropName='checked'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Checkbox onChange={(event) => setTruckWithForklift(event.target.checked)}>
              {t('projDescSheet_forkliftTruck')}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_scissorLift')}`}>
            <Form.Item
              name='scissorLift'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setScissorLift(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Not required'>
                    {t('projDescSheet_notRequired')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Customer'>
                    {t('customer')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='META'>
                    META
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {scissorLift === 'Customer' && (
              <>
                <Form.Item
                  name='bearingCapacityScissor'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}
                  style={{ marginTop: '16px', padding: '8px 0' }}>
                  <Input
                    type='number'
                    placeholder={`${t('projDescSheet_bearingCapacity')}:`}
                    suffix='kg'
                  />
                </Form.Item>
                <Form.Item
                  name='scissorWorkingHeight'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}
                  style={{ padding: '8px 0' }}>
                  <Input
                    type='number'
                    placeholder={`${t('projDescSheet_workingHeight')}:`}
                    suffix='m'
                  />
                </Form.Item>
                <Form.Item
                  name='scissorLength'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}
                  style={{ padding: '8px 0' }}>
                  <Input type='number' placeholder={`${t('length')}:`} suffix='m' />
                </Form.Item>
                <Form.Item
                  name='scissorWidth'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}
                  style={{ padding: '8px 0' }}>
                  <Input type='number' placeholder={`${t('width')}:`} suffix='m' />
                </Form.Item>
              </>
            )}
            {scissorLift === 'META' && (
              <>
                <Divider
                  className='pbbReportDivider'
                  style={{ minWidth: 'unset', marginTop: '15px' }}
                />
                <Form.Item name='scissorLiftSize'>
                  <Radio.Group onChange={(event) => setScissorLiftSize(event.target.value)}>
                    <Space direction='vertical'>
                      <Radio className='pbbRadioOption' value='Small scissor stage'>
                        {t('projDescSheet_scissorLiftSmall')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='Large scissor stage'>
                        {t('projDescSheet_scissorLiftLarge')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Card
        className='pbbCard'
        title={`${t('projDescSheet_newBuilding')}/${t('projDescSheet_oldBuilding')}?`}>
        <Form.Item
          name='building'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Radio.Group>
            <Space direction='horizontal'>
              <Radio style={{ flexDirection: 'row-reverse' }} value='New building'>
                {t('projDescSheet_newBuilding')}
              </Radio>
              <Radio style={{ flexDirection: 'row-reverse' }} value='Old building'>
                {t('projDescSheet_oldBuilding')}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Card>
      {/* <Form.Item name='building'
        rules={[
          {
            required: true,
            message: t('validationAlert'),
          },
        ]}
      >
        <Radio.Group style={{ width: '100%' }}>
          <Space style={{ width: '100%' }} direction="horizontal">
            <Radio className="pbbRadioOptionSecond" value='New building'>New building</Radio>
            <Radio className="pbbRadioOptionSecond" value='Old building'>Old building</Radio>
          </Space>
        </Radio.Group>
      </Form.Item> */}
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_toolsRoom')}?`}>
            <Form.Item name='lockableRoom'>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('lighting')}?`}>
            <Form.Item
              name='lighting'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_permits')}?`}>
            <Form.Item
              name='separationAndWelding'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_power')}?`}>
            <Form.Item
              name='powerOnSite'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} align='middle'>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_hallTemperature')}?`}>
            <Form.Item
              name='hall'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setHall(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='Heated hall'>
                    {t('projDescSheet_hotHall')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='Unheated hall'>
                    {t('projDescSheet_unheatedHall')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='Cold hall'>
                    {t('projDescSheet_coldHall')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {hall === 'Cold hall' && (
              <Form.Item
                name='temperature'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input
                  type='number'
                  placeholder={`${t('projDescSheet_temperaturePrompt')}:`}
                  suffix='°C'
                />
              </Form.Item>
            )}
          </Card>
          {/* <Form.Item name='hall'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Radio.Group style={{ width: '100%' }}>
              <Space style={{ width: '100%' }} direction="horizontal">
                <Radio className="pbbRadioOptionSecond" value='Hot hall'>Hot hall</Radio>
                <Radio className="pbbRadioOptionSecond" value='Cold hall'>Cold hall</Radio>
              </Space>
            </Radio.Group>
          </Form.Item> */}
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={`${t('projDescSheet_safetyInstructions')}`}>
            <Form.Item
              name='safetyInstruction'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setSafetyInstructions(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {safetyInstructions === 'yes' && (
              <Form.Item
                name='duration'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={`${t('duration')}:`} />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>
      <Divider className='pbbReportDivider' />
      <Form.Item
        name='miscellaneous'
        style={{ margin: '16px 0 32px' }}
        label={`${t('miscellaneous')}:`}>
        {/* <Input placeholder='Miscellaneous:' /> */}
        <Input.TextArea maxLength={500} placeholder={`${t('miscellaneous')}`} />
      </Form.Item>

      <Divider className='pbbReportDivider' />
      <EmbedImage data={data} files={files} setFiles={setFiles} name='projDescSheet_file' />
    </div>
  );
};

export default withNamespaces()(ProjectDescriptionSheetForm);
