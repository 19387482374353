import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { useSelector, shallowEqual } from 'react-redux';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {
  const userRoles = user && user.role.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1));
  const allowedRoles = allowed.sort((a, b) => (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : -1));

  return (
    <Route
      {...rest}
      render={(props) =>
        user && allowedRoles.some((role) => userRoles.includes(role)) ? ( // if signed in and user's role is allowed
          <Component {...props} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        )
      }
    />
  );
};

export default ProtectedRoute;
