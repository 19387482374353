import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/EventsTable';
import { SERVER_URL } from '../../config';
import EditEvent from './EditEvent';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt', 'disabled', 'status'];

const Event = ({ t }) => {
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [events, fetchEvents] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    // const filter = { $or: [{ disabled: { $exists: false } }, { disabled: false }] };
    // fetchEvents(`${SERVER_URL}/events?filter=${JSON.stringify(filter)}`, []);
    fetchEvents(`${SERVER_URL}/events`, []);
  }, [fetchEvents]);

  const deleteEventHandler = async (id) => {
    try {
      if (currentuser.data.role.includes('superAdmin')) {
        await Axios.delete(`${SERVER_URL}/events/${id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else {
        await Axios.put(`${SERVER_URL}/events/${id}`, { status: 'INACTIVE' }, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }
      notification.success({
        message: t('deleteSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/events');
    } catch (err) {
      notification.error({
        message: t('deleteProblem'),
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (events.data && events.data.items && events.data.items.length > 0) {
    const keys = Object.keys(events.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.push('status');
  }

  let tableData = events.data.items;
  if (events.data && events.data.items && events.data.items.length > 0) {
    tableData = events.data.items.map((item) => {
      // item.startDate = new Date(item.startDate).toLocaleString().split(',')[0];
      // item.endDate = new Date(item.endDate).toLocaleString().split(',')[0];
      item.name = <Link style={{ textDecoration: 'none' }} className='eventLink' to={`/admin/event-reports?event=${item._id}`}>{item.name}</Link>;
      item.startDate = moment(item.startDate).format('MM/DD/YYYY');
      item.endDate = moment(item.endDate).format('MM/DD/YYYY');
      return item;
    });
  }

  return (
    <div className='table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => setShowModal(!showModal)} type='primary'>
          {t('addEvent')}
        </Button>
      </div>
      {!events.isLoading && events.data && events.data.items && events.data.items.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          {events.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
          {!events.isLoading && events.data && events.data.items && events.data.items.length > 0 && (
            <Table
              data={tableData}
              deleteHandler={deleteEventHandler}
              columnKeys={columnKeys}
              title='Event'
              editPath='/admin/edit-event/'
              viewPath='/admin/view-event/'
            />
          )}
        </div>
      )}
      <EditEvent showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default withNamespaces()(Event);
