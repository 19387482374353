import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Drawer, notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import EventReportForm from '../../components/forms/EventReportForm';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const EditEventReport = (props) => {
  const currentuser = useContext(UserContext);
  const [salesAgentData, fetchSalesAgentData] = useAxios('', null, currentuser.data.token, 'get');
  const [result, setResult] = useState(false);
  const [eventReport, fetchEventReport] = useAxios('', null, currentuser.data.token, 'get');
  const [events, fetchEvents] = useAxios('', [], currentuser.data.token, 'get');
  const [categories, fetchCategories] = useAxios('', [], currentuser.data.token, 'get');
  const [cataloguesByEventReport, fetchCataloguesByEventReport] = useAxios(
    '',
    [],
    currentuser.data.token,
    'get'
  );
  const history = useHistory();
  const eventReportId = props.eventReportId;

  //povlace se podaci trenutnog user-a i onda se iz njegovih podataka izvlaci salesManager podatak
  useEffect(() => {
    if (currentuser) {
      fetchSalesAgentData(`${SERVER_URL}/users/${currentuser.data.id}`, []);
    }
  }, [currentuser, fetchSalesAgentData]);

  useEffect(() => {
    fetchEvents(`${SERVER_URL}/events`, []);
    fetchCategories(`${SERVER_URL}/product-category`, []);
  }, [fetchEvents, fetchCategories]);

  useEffect(() => {
    if (eventReportId) {
      const filter = { eventReport: eventReportId };
      fetchEventReport(`${SERVER_URL}/event-reports/${eventReportId}`, []);
      fetchCataloguesByEventReport(
        `${SERVER_URL}/selected-catalogue?filter=${JSON.stringify(filter)}`,
        []
      );
    }
  }, [eventReportId, fetchEventReport, fetchCataloguesByEventReport]);

  const updateEventReport = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/event-reports/${eventReportId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight'
      });
      history.push('/admin');
      history.push('/admin/event-reports');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight'
      });
    }
  };

  const createEventReport = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/event-reports`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } }
      );
      notification.success({
        message: t('createdSuccessfully'),
        placement: 'bottomRight'
      });
      history.push('/admin');
      history.push('/admin/event-reports');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight'
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  // let filteredEvents = [];
  // if (events && events.data && events.data.items && events.data.items.length !== 0) {
  //   filteredEvents = events.data.items.filter((event) => !event.disabled ||
  //     (eventReport && eventReport.data && event._id === eventReport.data.event._id));
  // }

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {eventReportId && eventReport.isLoading && (
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        )}
        {eventReportId && !eventReport.isLoading && !eventReport.isError && eventReport.data && (
          <Drawer
            title={t('updateEventReport')}
            placement='right'
            onClose={onClose}
            visible={props.showModal}
            className='event-report__form-drawer'>
            <EventReportForm
              isNew={false}
              data={eventReport.data}
              updateHandler={updateEventReport}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              eventSelect={events}
              interests={categories && categories.data && categories.data.items}
              selectedCatalogues={
                cataloguesByEventReport &&
                cataloguesByEventReport.data &&
                cataloguesByEventReport.data.items
              }
            />
          </Drawer>
        )}
        {eventReportId && !eventReport.isLoading && eventReport.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong: </h2>
        )}
        {!eventReportId && eventReport && !eventReport.data && (
          <Drawer
            title={t('addEventReport')}
            placement='right'
            onClose={onClose}
            visible={props.showModal}
            className='event-report__form-drawer'>
            <EventReportForm
              isNew={true}
              data={null}
              createHandler={createEventReport}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              eventSelect={events}
              salesManagerId={salesAgentData?.data?.salesManager}
              employeeName={salesAgentData?.data?.firstName + ' ' + salesAgentData?.data?.lastName}
              interests={categories && categories.data && categories.data.items}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(EditEventReport);
