import { Card, Checkbox, Col, Divider, Form, Input, Radio, Row, Space, Select } from 'antd';
import React, { useState } from 'react';
import EmbedImage from '../base/EmbedImage';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

const { Option } = Select;

const MultipalPalletForm = ({ data, files, setFiles, t }) => {
  const [palletSupport, setPalletSupport] = useState(data && data.palletSupportMultipal);
  const [beams, setBeams] = useState(data && data.beamMultipal);
  const [beamsNew, setBeamsNew] = useState(data && data.beamMultipalNew);
  const [backCladding, setBackCladding] = useState(data && data.backCladdingMultipal);
  const [backStops, setBackStops] = useState(data && data.backStopsMultipal);
  const [depth, setDepth] = useState(data && data.depth);
  const [selectedDepth, setSelectedDepth] = useState(
    data && data.depth?.find((d) => d === 'specialDepth')
  );
  const [heightInside, setHeightInside] = useState(data && data.heightInside);
  const [heightOutside, setHeightOutside] = useState(data && data.heightOutside);
  const [customInside, setCustomInside] = useState(
    data && data.heightInside.find((d) => d === 'customInside')
  );
  const [customOutside, setCustomOutside] = useState(
    data && data.heightOutside.find((d) => d === 'customOutside')
  );
  const [compHeights, setCompHeights] = useState(data && data.compartmentHeights);
  const [selectedHeights, setSelectedHeights] = useState(data && data.compartmentHeights);
  const [backCladdingMultipalChecked, setBackCladdingMultipalChecked] = useState(
    data && data.backCladdingMultipalChecked
  );
  const [backStopsChecked, setBackStopsChecked] = useState(data && data.backStopsChecked);
  const [palletSupportBarChecked, setPalletSupportBarChecked] = useState(
    data && data.palletSupportBarChecked
  );

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  const beamLength = [
    { id: '1', name: '1,825 mm', beam: 'beamLengthOne', load: 'beamLoadOne', bay: 'bayLoadOne' },
    { id: '2', name: '2,225 mm', beam: 'beamLengthTwo', load: 'beamLoadTwo', bay: 'bayLoadTwo' },
    {
      id: '3',
      name: '2,700 mm',
      beam: 'beamLengthThree',
      load: 'beamLoadThree',
      bay: 'bayLoadThree'
    },
    { id: '4', name: '2,825 mm', beam: 'beamLengthFour', load: 'beamLoadFour', bay: 'bayLoadFour' },
    { id: '5', name: '3,300 mm', beam: 'beamLengthFive', load: 'beamLoadFive', bay: 'bayLoadFive' },
    { id: '6', name: '3,600 mm', beam: 'beamLengthSix', load: 'beamLoadSix', bay: 'bayLoadSix' },
    {
      id: 'Special',
      name: t('special'),
      beam: 'beamLengthSpecial',
      load: 'beamLoadSpecial',
      bay: 'bayLoadSpecial'
    }
  ];

  const [selectedBeams, setSelectedBeams] = useState(
    data && beamLength.filter((a) => data.selectedBeams.includes(a.name))
  );

  return (
    <div>
      <h3 id='meta-multipal'>{t('metaMultipal')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='multipalLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoMultipal'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultipal_surface')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultipal_beams')}>
            <Form.Item
              name='beamMultipal'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setBeams(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('metaMultiTiers_galvanized')}
                  </Radio>
                  {/* <Radio className='pbbRadioOption' value='RAL 2001 red orange'>
                    RAL 2001 red orange
                  </Radio> */}
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('gentianBlueRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('lightGreyRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special RAL colors'>
                    {t('metaMultipal_specialRALColorCharge')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {beams === 'Special RAL colors' && (
              <Form.Item
                style={{ marginTop: '10px' }}
                name='specialRALBeamColors'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={t('metaMultipal_specialRALColorCharge')} />
              </Form.Item>
            )}
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaMultipal_beamsNew')}>
            <Form.Item
              name='beamMultipalNew'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group onChange={(event) => setBeamsNew(event.target.value)}>
                <Space direction='vertical'>
                  <Radio className='pbbRadioOption' value='Galvanized'>
                    {t('metaMultiTiers_galvanized')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 2001 red orange'>
                    {t('redOrangeRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                    {t('gentianBlueRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                    {t('lightGreyRAL')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='Special RAL colors'>
                    {t('metaMultipal_specialRALColorCharge')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {beamsNew === 'Special RAL colors' && (
              <Form.Item
                style={{ marginTop: '10px' }}
                name='specialRALBeamNewColors'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input placeholder={t('metaMultipal_specialRALColorCharge')} />
              </Form.Item>
            )}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Card className='pbbCard' title={t('metaMultiTiers_backCladding')}>
            <Form.Item name='backCladdingMultipalChecked' style={{ marginBottom: '1rem' }}>
              <Radio.Group onChange={(event) => setBackCladdingMultipalChecked(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio className='pbbRadioOption' value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {backCladdingMultipalChecked === 'yes' && (
              <>
                <Form.Item
                  name='backCladdingMultipal'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Radio.Group onChange={(event) => setBackCladding(event.target.value)}>
                    <Space direction='vertical'>
                      {/* <Radio className='pbbRadioOption' value='Galvanized'>
                        {t('metaMultiTiers_galvanized')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 2001 red orange'>
                        RAL 2001 red orange
                      </Radio> */}
                      <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                        {t('gentianBlueRAL')}
                      </Radio>
                      {/* <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                        {t('lightGreyRAL')}
                      </Radio> */}
                      <Radio className='pbbRadioOption' value='Special RAL colors'>
                        {t('metaMultipal_specialRALColorCharge')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {backCladding === 'Special RAL colors' && (
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name='specialRALBackCladdingColors'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}>
                    <Input placeholder={t('metaMultipal_specialRALColorCharge')} />
                  </Form.Item>
                )}
              </>
            )}
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card className='pbbCard' title={t('metaMultipal_backStops')}>
            <Form.Item name='backStopsChecked' style={{ marginBottom: '1rem' }}>
              <Radio.Group onChange={(event) => setBackStopsChecked(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio className='pbbRadioOption' value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {backStopsChecked === 'yes' && (
              <>
                <Form.Item
                  name='backStopsMultipal'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Radio.Group onChange={(event) => setBackStops(event.target.value)}>
                    <Space direction='vertical'>
                      <Radio className='pbbRadioOption' value='Galvanized'>
                        {t('metaMultiTiers_galvanized')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 2001 red orange'>
                        RAL 2001 red orange
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                        {t('gentianBlueRAL')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                        {t('lightGreyRAL')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='Special RAL colors'>
                        {t('metaMultipal_specialRALColorCharge')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {backStops === 'Special RAL colors' && (
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name='specialRALBackStopColors'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}>
                    <Input placeholder={t('metaMultipal_specialRALColorCharge')} />
                  </Form.Item>
                )}
              </>
            )}
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card className='pbbCard' title={t('metaMultipal_palletSupport')}>
            <Form.Item name='palletSupportBarChecked' style={{ marginBottom: '1rem' }}>
              <Radio.Group onChange={(event) => setPalletSupportBarChecked(event.target.value)}>
                <Space direction='horizontal'>
                  <Radio className='pbbRadioOption' value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio className='pbbRadioOption' value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {palletSupportBarChecked === 'yes' && (
              <>
                <Form.Item
                  name='palletSupportMultipal'
                  rules={[
                    {
                      required: true,
                      message: t('validationAlert')
                    }
                  ]}>
                  <Radio.Group onChange={(event) => setPalletSupport(event.target.value)}>
                    <Space direction='vertical'>
                      <Radio className='pbbRadioOption' value='Galvanized'>
                        {t('metaMultiTiers_galvanized')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 2001 red orange'>
                        RAL 2001 red orange
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                        {t('gentianBlueRAL')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                        {t('lightGreyRAL')}
                      </Radio>
                      <Radio className='pbbRadioOption' value='Special RAL colors'>
                        {t('metaMultipal_specialRALColorCharge')}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {palletSupport === 'Special RAL colors' && (
                  <Form.Item
                    style={{ marginTop: '10px' }}
                    name='specialRALPalletColors'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert')
                      }
                    ]}>
                    <Input placeholder={t('metaMultipal_specialRALColorCharge')} />
                  </Form.Item>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>

      <div style={{ display: 'flex' }}>
        <Form.Item
          name='heightInside'
          label={`${t('metaMultipal_heightInside')}`}
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox.Group
            defaultValue={heightInside}
            onChange={(e) => setCustomInside(e.includes('customInside') ? true : false)}>
            <Space direction='vertical'>
              <Checkbox value='2,200 mm' />
              <Checkbox value='2,700 mm' />
              <Checkbox value='3,300 mm' />
              <Checkbox value='3,800 mm' />
              <Checkbox value='4,400 mm' />
              <Checkbox value='4,900 mm' />
              <Checkbox value='5,500 mm' />
              <Checkbox value='6,000 mm' />
              <Checkbox value='6,600 mm' />
              <Checkbox value='7,100 mm' />
              <Checkbox value='7,700 mm' />
              <Checkbox value='customInside' />
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1.95rem' }}>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '2.200 mm' : '2,200 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '2.700 mm' : '2,700 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '3.300 mm' : '3,300 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '3.800 mm' : '3,800 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '4.400 mm' : '4,400 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '4.900 mm' : '4,900 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '5.500 mm' : '5,500 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '6.000 mm' : '6,000 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '6.600 mm' : '6,600 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '7.100 mm' : '7,100 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>
            {i18n.language === 'German' ? '7.700 mm' : '7,700 mm'}
          </span>
          <span style={{ marginBottom: '.5rem' }}>{t('specialHeight')}</span>
        </div>
        <Form.Item
          name='heightOutside'
          label={`${t('metaMultipal_heightOutside')}`}
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox.Group
            defaultValue={heightOutside}
            onChange={(e) => setCustomOutside(e.includes('customOutside') ? true : false)}>
            <Space direction='vertical'>
              <Checkbox value='2,200 mm' />
              <Checkbox value='2,700 mm' />
              <Checkbox value='3,300 mm' />
              <Checkbox value='3,800 mm' />
              <Checkbox value='4,400 mm' />
              <Checkbox value='4,900 mm' />
              <Checkbox value='5,500 mm' />
              <Checkbox value='6,000 mm' />
              <Checkbox value='6,600 mm' />
              <Checkbox value='7,100 mm' />
              <Checkbox value='7,700 mm' />
              <Checkbox value='customOutside' />
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </div>
      {customInside && (
        <Form.Item
          name='customInsideHeight'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Input placeholder={t('metaMultipal_customHeightInside')} type='number' />
        </Form.Item>
      )}
      {customOutside && (
        <Form.Item
          name='customOutsideHeight'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Input placeholder={t('metaMultipal_customHeightOutside')} type='number' />
        </Form.Item>
      )}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultipal_beamLength')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={4} md={4}>
          <Form.Item
            name='selectedBeams'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Select
              style={{ width: '20rem' }}
              showArrow
              mode='tags'
              onChange={(val) => setSelectedBeams(beamLength.filter((a) => val.includes(a.name)))}>
              {beamLength.map(({ name, id }) => (
                <Option key={id} value={name}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectedBeams?.map(({ id, name, beam, load, bay }) => (
            <div style={{ display: 'flex', gap: '16px' }}>
              <h2>{name}:</h2>
              <Form.Item
                name={load}
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input
                  type='number'
                  style={{ width: '12rem' }}
                  placeholder={`${t('metaMultipal_beamLoad')} ${name}`}
                  suffix='kg'
                />
              </Form.Item>
              <Form.Item
                name={bay}
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Input
                  type='number'
                  style={{ width: '12rem' }}
                  placeholder={`${t('metaMultipal_bayLoad')} ${name}`}
                  suffix='kg'
                />
              </Form.Item>
            </div>
          ))}
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('depth')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Form.Item
        name='depth'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox.Group
          defaultValue={depth}
          onChange={(e) => setSelectedDepth(e.includes('specialDepth') ? true : false)}>
          <Space direction='horizontal'>
            <Checkbox value='depthOne' className='pbbCheckbox'>
              800 mm
            </Checkbox>
            <Checkbox value='depthTwo' className='pbbCheckbox'>
              {i18n.language === 'German' ? '1.100 mm' : '1,100 mm'}
            </Checkbox>
            <Checkbox value='specialDepth' className='pbbCheckbox'>
              {t('metaMultipal_specialDepth')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
      {selectedDepth && (
        <Form.Item
          name='specialDepth'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Input type='number' suffix='mm' />
        </Form.Item>
      )}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultipal_floorHeights')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Form.Item
        name='compartmentHeights'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox.Group defaultValue={compHeights} onChange={(e) => setSelectedHeights(e)}>
          <Space direction='horizontal' className='compartment-heights-levels'>
            <Checkbox value='upperEdge' className='pbbCheckbox'>
              {t('upperEdge')}
            </Checkbox>
            <Checkbox value='level1' className='pbbCheckbox'>
              {t('level1')}
            </Checkbox>
            <Checkbox value='level2' className='pbbCheckbox'>
              {t('level2')}
            </Checkbox>
            <Checkbox value='level3' className='pbbCheckbox'>
              {t('level3')}
            </Checkbox>
            <Checkbox value='level4' className='pbbCheckbox'>
              {t('level4')}
            </Checkbox>
            <Checkbox value='level5' className='pbbCheckbox'>
              {t('level5')}
            </Checkbox>
            <Checkbox value='level6' className='pbbCheckbox'>
              {t('level6')}
            </Checkbox>
            <Checkbox value='level7' className='pbbCheckbox'>
              {t('level7')}
            </Checkbox>
            <Checkbox value='level8' className='pbbCheckbox'>
              {t('level8')}
            </Checkbox>
            <Checkbox value='level9' className='pbbCheckbox'>
              {t('level9')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
      {selectedHeights?.map((height) => (
        <Form.Item
          name={height}
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Input type='number' suffix='mm' placeholder={t(height)} />
        </Form.Item>
      ))}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMultipal_loadCarrier')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      {/* <Row gutter={8}>
        <Col xs={24} md={5}>
          <Form.Item
            name='palletWeight'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          // label={`${t('metaMultipal_palletWeight')}:`}
          >
            <Input type='number' placeholder={t('metaMultipal_palletWeight')} suffix='kg' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Row gutter={8}>
            <Col xs={6} md={6}>
              {t('metaMultipal_palletSize')}:
            </Col>
            <Col xs={8} md={8}>
              <Form.Item
                name='palletSizeDimensionOne'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert'),
                  },
                ]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col xs={1} md={1}>
              x
            </Col>
            <Col xs={8} md={8}>
              <Form.Item
                name='palletSizeDimensionSecond'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert'),
                  },
                ]}
              >
                <Input type='number' suffix='mm' />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name='palletHeight'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input type='number' placeholder={`${t('metaMultipal_palletHeight')}:`} suffix='mm' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name='overhang'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
            label={`${t('metaMultipal_overhang')}:`}
          >
            <Input type='number' placeholder={`${t('metaMultipal_overhang')}`} suffix='mm' />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='carrierLoad'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
            label={`${t('metaMultipal_loadCarrier')}:`}
          >
            <Input type='number' placeholder={`${t('metaMultipal_loadCarrier')}`} suffix='kg' />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='loadCarrierType'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
            label={`${t('metaMultipal_loadCarrierType')}:`}
          >
            <Input placeholder={`${t('metaMultipal_loadCarrierType')}`} />
          </Form.Item>
        </Col>
      </Row>

      <EmbedImage data={data} files={files} setFiles={setFiles} name='multipalPalletImage' />
      <Form.Item name='specialMultipal' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={`${t('positioning/SpecialFeatures')}:`} />
      </Form.Item> */}
      <Form.Item
        name='loadCarrierType'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Checkbox.Group defaultValue={compHeights} onChange={(e) => {}}>
          <Space direction='horizontal'>
            <Checkbox value='euroPallet' className='pbbCheckbox'>
              {t('metaMultipal_euroPallet')}
            </Checkbox>
            <Checkbox value='disposablePallet' className='pbbCheckbox'>
              {t('metaMultipal_disposablePallet')}
            </Checkbox>
            <Checkbox value='meshBox' className='pbbCheckbox'>
              {t('metaMultipal_meshBox')}
            </Checkbox>
            <Checkbox value='industrialPallet' className='pbbCheckbox'>
              {t('metaMultipal_industrialPallet')}
            </Checkbox>
            <Checkbox value='other' className='pbbCheckbox'>
              {t('metaMultipal_other')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name='carrierDimensionsInfo'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Input placeholder={t('metaMultipal_dimensionsLoadCarrier')} />
      </Form.Item>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <EmbedImage
            data={data}
            files={files}
            setFiles={setFiles}
            name='multipalPalletImage'
            title={t('loadCarrierIllustration')}
          />
        </Col>
      </Row>
      <Form.Item
        name='carrierWeightInfo'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Input type='number' suffix='kg' placeholder={t('metaMultipal_wightPerLoadCarrier')} />
      </Form.Item>
      <Form.Item
        name='carrierOverhangInfo'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}>
        <Input
          type='number'
          suffix='mm'
          placeholder={t('metaMultipal_overhangOfGoodsLoadCarrier')}
        />
      </Form.Item>
    </div>
  );
};
export default withNamespaces()(MultipalPalletForm);
