import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Tabs, Typography, Card } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
// import { UserLog } from '../../components/forms';
import moment from 'moment';
import EditUserModal from '../../components/modals/EditModal';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const ViewUser = (props /*, editPath*/) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchUser, fetchLog]);

  useEffect(() => {
    if (id) {
      let sevenDayBefor = moment().subtract(7, 'day').format('YYYY-MM-DD');
      let sevenDayBeforGet = moment(sevenDayBefor).format('YYYY-MM-DD');
      let from = sevenDayBeforGet;
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className='edit-panel'>
      {(currentuser.data.role.includes('admin') ||
        currentuser.data.role.includes('superAdmin')) && (
        <div className='actions-block'>
          <Link to='/admin/users'>
            <Button type='primary'>{t('users')}</Button>
          </Link>
        </div>
      )}

      <div className='content content-full-height'>
        {id && user.isLoading && <LoadingOutlined className='loader' spin />}
        {id && !user.isLoading && user.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}
        {id && user && !user.isLoading && !user.isError && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto' }}>
                {/* <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} /> */}

                {/*<Link to={'/admin/edit-user/' + id}>*/}
                <Button
                  type='primary'
                  block
                  icon={<EditOutlined />}
                  style={{ marginTop: '1rem' }}
                  onClick={() => setShowModal(!showModal)}>
                  {t('editUser')}
                </Button>
                {/*</Link>*/}
              </div>
              <Content style={{ padding: '15px', minHeight: 280, color: '#000' }}>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Email:</span> {user.data ? user.data.email : 'Email'}
                </p>
                <p
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    margin: '0 0 15px',
                    textTransform: 'capitalize'
                  }}>
                  <span className='bold-label'>{t('role')}:</span>{' '}
                  {user.data ? user.data.role[0] : 'Role'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Status:</span>{' '}
                  {user.data ? user.data.status : 'Status'}
                </p>
                {currentuser.data.id === id && (
                  <Button className='log-out-btn' type='secondary' onClick={handleSignOutSubmit}>
                    {t('logout')}
                  </Button>
                )}
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab={t('profile')} key='1'>
                  <div className='card-wrapper'>
                    <Card title={t('userInformation')} bordered={false}>
                      <p>
                        <Text strong>{t('firstName')}: </Text>
                        {user.data ? user.data.firstName : 'No data'}
                      </p>
                      <p>
                        <Text strong>{t('lastName')}: </Text>
                        {user.data ? user.data.lastName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {user.data ? user.data.email : 'No data'}
                      </p>
                      <p style={{ textTransform: 'capitalize' }}>
                        <Text strong>{t('role')}: </Text>
                        {user.data ? user.data.role[0] : 'No data'}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {user.data ? user.data.status : 'No data'}
                      </p>
                    </Card>
                  </div>
                </TabPane>

                {/* <TabPane tab='LOGS' key='2'>
                  {log.data.length > 0 ? (
                    <UserLog data={log.data} userId={id} updateHandler={updateUser} csv={csv.data} />
                  ) : (
                    'No Data'
                  )}
                </TabPane> */}
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal userId={id} showModal={showModal} setShowModal={setShowModal} profile={true} />
    </div>
  );
};

export default withNamespaces()(ViewUser);
