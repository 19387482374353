import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Drawer, notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import ProductCategoryForm from '../../components/forms/ProductCategoryForm';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const EditProductCategory = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [productCategory, fetchProductCategory] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const id = props.id;

  useEffect(() => {
    if (id) {
      fetchProductCategory(`${SERVER_URL}/product-category/${id}`, []);
    }
  }, [id, fetchProductCategory]);

  const updateProductCategory = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/product-category/${id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/product-category');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const createProductCategory = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/product-category`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: t('createdSuccessfully'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/product-category');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {id && productCategory.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {id && !productCategory.isLoading && !productCategory.isError && productCategory.data && (
          <Drawer title={t('updateCategory')} placement='right' onClose={onClose} visible={props.showModal}>
            <ProductCategoryForm
              isNew={false}
              data={productCategory.data}
              updateHandler={updateProductCategory}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          </Drawer>
        )}
        {id && !productCategory.isLoading && productCategory.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}
        {!id && productCategory && !productCategory.data && (
          <Drawer title={t('addCategory')} placement='right' onClose={onClose} visible={props.showModal}>
            <ProductCategoryForm
              isNew={true}
              data={null}
              createHandler={createProductCategory}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(EditProductCategory);
