import React from "react";
import { Table, Divider } from 'antd';
import { withNamespaces } from "react-i18next";

const InfoSheet = ({ t }) => {
  const data = [
    {
      shelvingSystem: t('infoSheetRow1Title'),
      forkliftMin15To: 'X',
      smallScissorLift: null,
      largeScissorLift: null,
    },
    {
      shelvingSystem: t('infoSheetRow2Title'),
      forkliftMin15To: 'X',
      smallScissorLift: null,
      largeScissorLift: 'X',
    },
    {
      shelvingSystem: t('infoSheetRow3Title'),
      forkliftMin15To: 'X',
      smallScissorLift: null,
      largeScissorLift: 'X',
    },
    {
      shelvingSystem: t('infoSheetRow4Title'),
      forkliftMin15To: 'X',
      smallScissorLift: null,
      largeScissorLift: null,
    },
    {
      shelvingSystem: t('infoSheetRow5Title'),
      forkliftMin15To: 'X',
      smallScissorLift: 'X',
      largeScissorLift: null,
    },
    {
      shelvingSystem: t('infoSheetRow6Title'),
      forkliftMin15To: 'X',
      smallScissorLift: null,
      largeScissorLift: 'X',
    },
    {
      shelvingSystem: t('infoSheetRow7Title'),
      forkliftMin15To: 'X',
      smallScissorLift: null,
      largeScissorLift: 'X',
    },
    {
      shelvingSystem: t('infoSheetRow8Title'),
      forkliftMin15To: 'X',
      smallScissorLift: 'X',
      largeScissorLift: null,
    },
    {
      shelvingSystem: t('infoSheetRow9Title'),
      forkliftMin15To: 'X',
      smallScissorLift: 'X',
      largeScissorLift: null,
    },
    {
      shelvingSystem: t('infoSheetRow10Title'),
      forkliftMin15To: 'X',
      smallScissorLift: 'X',
      largeScissorLift: null,
    },
  ];
  const columns = [
    {
      title: t('infoSheetShelvingSystem'),
      dataIndex: 'shelvingSystem',
      key: 'shelvingSystem',
      render: value => <div style={{ textAlign: 'left' }}>{value}</div>
    },
    {
      title: t('infoSheetForkliftMin15to'),
      dataIndex: 'forkliftMin15To',
      key: 'forkliftMin15To',
    },
    {
      title: t('infoSheetSmallScissorLift'),
      dataIndex: 'smallScissorLift',
      key: 'smallScissorLift',
    },
    {
      title: t('infoSheetLargeScissorLift'),
      dataIndex: 'largeScissorLift',
      key: 'largeScissorLift',
    },
  ];

  return (
    <div>
      <h3 className="info-sheet-title" id='info-sheet'>{t('infoSheetTitle')}</h3>

      <Divider className="pbbReportDivider" />
      <p>
        {t('infoSheetDescOne')}
      </p>
      <p>
        {t('infoSheetDescTwo')}
      </p>
      <div className="info-sheet-table">
        <Table
          bordered
          dataSource={data}
          columns={columns}
          pagination={false}
        />
      </div>
      <div className="under-table-info">
        <div className="under-table-info__row">
          <span className="under-table-info__title">{t('infoSheetSmallScissorLift')}:</span>
          <span>
            {t('infoSheetLoadCapacity230')} <br />
            {t('infoSheetLength17')}
          </span>
          <span>
            {t('infoSheetHeight65')} <br />
            {t('infoSheetWidth08')}
          </span>
        </div>
        <div className="under-table-info__row">
          <span className="under-table-info__title">{t('infoSheetLargeScissorLift')}:</span>
          <span>
            {t('infoSheetLoadCapacity450')} <br />
            {t('infoSheetLength24')}
          </span>
          <span>
            {t('infoSheetWorkingHeight10')} <br />
            {t('infoSheetWidth12')}
          </span>
        </div>
      </div>
      <div className="forklift-with-cage__wrapper">
        <div className="forklift-with-cage">
          <h2>{t('infoSheetForkliftWithCageTitle')}</h2>
          <ul>
            <li>• {t('infoSheetForkliftWithCageList1')}</li>
            <li>• {t('infoSheetForkliftWithCageList2')}</li>
            <li>• {t('infoSheetForkliftWithCageList3')}</li>
          </ul>
          <p>
            {t('infoSheetForkliftWithCageDesc1')}
            {t('infoSheetForkliftWithCageDesc2')}
            {t('infoSheetForkliftWithCageDesc3')}
          </p>
        </div>
      </div>
      <h3 className="info-sheet-title footer">{t('infoSheetFooter')}</h3>
    </div>
  );
};

export default withNamespaces()(InfoSheet);