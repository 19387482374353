import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Select } from 'antd';
// import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import {
  UserOutlined,
  LogoutOutlined,
  CalendarOutlined,
  FileTextOutlined,
  AppstoreOutlined,
  ReconciliationOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import i18n from '../../i18n';
// import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons";
import { withNamespaces } from 'react-i18next';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);

  if (i18n && i18n.language) {
    localStorage.setItem('language', i18n.language);
  }

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    localStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6 }}
      width={220}
    >
      <Link to='/admin' className='logo-desktop'>
        <div className='logo'>
          <img src='/metalogo.svg' style={{ maxHeight: '49px', width: '70px' }} alt='META logo' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {currentuser.data &&
          currentuser.data.role &&
          (currentuser.data.role.includes('admin') ||
            currentuser.data.role.includes('superAdmin') ||
            currentuser.data.role.includes('salesAgent') ||
            currentuser.data.role.includes('salesManager')) && (
            <SubMenu
              key='eventsModuleMenu'
              title='Events Module'
              icon={<CalendarOutlined />}
              className='nav-submenu-item'
            >
              {currentuser.data &&
                currentuser.data.role &&
                (currentuser.data.role.includes('admin') || currentuser.data.role.includes('superAdmin')) && (
                  <Menu.Item key='events'>
                    <Link to={'/admin/events'}>
                      <CalendarOutlined /> {props.t('events')}
                    </Link>
                  </Menu.Item>
                )}
              {currentuser.data &&
                currentuser.data.role &&
                !currentuser.data.role.includes('internalUser') &&
                !currentuser.data.role.includes('externalUser') && (
                  <Menu.Item key='eventReports'>
                    <Link to={'/admin/event-reports'}>
                      <FileTextOutlined /> {props.t('eventReports')}
                    </Link>
                  </Menu.Item>
                )}
              {currentuser.data &&
                currentuser.data.role &&
                (currentuser.data.role.includes('admin') || currentuser.data.role.includes('superAdmin')) && (
                  <Menu.Item key='catalogues'>
                    <Link to={'/admin/catalogues'}>
                      <AppstoreOutlined /> {props.t('catalogues')}
                    </Link>
                  </Menu.Item>
                )}
              {currentuser.data && currentuser.data.role && currentuser.data.role.includes('superAdmin') && (
                <Menu.Item key='productCategories'>
                  <Link to={'/admin/product-category'}>
                    <UnorderedListOutlined /> {props.t('productCategory')}
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
        {currentuser.data &&
          currentuser.data.role &&
          (currentuser.data.role.includes('pbbAdmin') ||
            currentuser.data.role.includes('superAdmin') ||
            currentuser.data.role.includes('internalUser') ||
            currentuser.data.role.includes('externalUser')) && (
            <SubMenu
              key='pbbModuleMenu'
              title='PBB Module'
              icon={<ReconciliationOutlined />}
              className='nav-submenu-item'
            >
              <Menu.Item key='pbbReports'>
                <Link to={'/admin/pbb-reports'}>
                  <FileTextOutlined />
                  {props.t('pbbReports')}
                </Link>
              </Menu.Item>
              <Menu.Item key='pbbDrafts'>
                <Link to={'/admin/pbb-drafts'}>
                  <FileTextOutlined />
                  {props.t('pbbDrafts')}
                </Link>
              </Menu.Item>
            </SubMenu>
          )}
        {currentuser.data &&
          currentuser.data.role &&
          (currentuser.data.role.includes('admin') || currentuser.data.role.includes('superAdmin')) && (
            <Menu.Item key='users'>
              <Link to={'/admin/users'}>
                <UserOutlined /> {props.t('users')}
              </Link>
            </Menu.Item>
          )}
        {/* {currentuser.data && currentuser.data.role && currentuser.data.role.includes('admin') && <Menu.Item key='languages'>
          <Link to={'/admin/languages'}><TranslationOutlined /> Languages</Link>
        </Menu.Item>} */}

        {/* {ROUTES.map((route) => (
          <SubMenu
            className='submenu'
            key={route.label.toLowerCase()}
            title={
              isCollapsed ? (
                <route.icon />
              ) : (
                <span>
                  <route.icon />
                  {route.label}
                </span>
              )
            }
          >
            {route.children
              .filter((item) => item.showInMenu)
              .map((item) => (
                <Menu.Item key={item.path}>
                  <Link to={item.path}>{item.label}</Link>
                </Menu.Item>
              ))}
          </SubMenu>
        ))} */}
      </Menu>
      <div className='sider-profile-controls'>
        <Link
          to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </Link>

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
        <Select
          className='sider-profile-controls sider-profile-controls-language'
          getPopupContainer={(trigger) => trigger.parentNode}
          value={
            // localStorage.getItem('language') ? localStorage.getItem('language') :
            i18n.language
            //currentuser.language && currentuser.language.selected.name
          }
          onChange={(lang) => {
            console.log('lang: ', lang);
            i18n.changeLanguage(lang);
            localStorage.setItem('language', lang);
            currentuser.setLanguage({
              ...currentuser.language,
              selected: currentuser.language.list.find((l) => l.name === lang),
            });
            window.location.reload();
          }}
        >
          {currentuser.language &&
            currentuser.language.list.map((lang) => (
              <Select.Option value={lang.name} key={lang.name}>
                {lang.name}
              </Select.Option>
            ))}
        </Select>
      </div>
    </Sider>
  );
};
export default withNamespaces()(NavMenu);
