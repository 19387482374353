import React, { useContext } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { UserContext } from '../App';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  background: '#e3e3e3',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: '#fff'
};

const companyLogoStyle = {
  maxWidth: '100px',
  marginBottom: '50px'
};

const Login = () => {
  const user = useContext(UserContext);

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
        <img style={companyLogoStyle} className="login-logo" src="/META_Logo_DE.svg" alt="Meta Logo" />
        <Meta
            title="Login"
          />
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item name='password' rules={[{ required: true, message: 'Please enter your   password!' }]}>
              <Input.Password placeholder='Password' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{'width':'100%'}} htmlType='submit'>
                               Submit</Button>
            </Form.Item>
            <Form.Item style={{paddingTop: '30px'}}>
              <Link to="/forgot-password" className="link-forgot-password">Forgot password</Link>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;
