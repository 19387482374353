import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
// import Data from '../pages/data/Data';
// import EditData from '../pages/data/EditData';
// import Categories from '../pages/data/Categories';
// import Attributes from '../pages/data/Attributes';
// import Gallery from '../pages/gallery/Gallery';
// import GalleryNewContent from '../pages/gallery/GalleryNewContent';
// import Post from '../pages/post/Post';
// import Newsletter from '../pages/newsletter/Newsletter';
// import EditPost from '../pages/post/EditPost';
// import Tag from '../pages/tags/Tag';
// import EditTag from '../pages/tags/EditTag';
// import Declaration from '../pages/declaration/Declaration';
// import EditDeclaration from '../pages/declaration/EditDeclaration';
// import Languages from '../pages/languages/Languages';
// import DraftPost from '../pages/post/DraftPost';
// import ImportData from '../pages/importData/ImportData';
// import Pages from '../pages/page/Page';
// import EditPages from '../pages/page/EditPage';
// import Invoices from '../pages/invoices/Invoice';
// import Orders from '../pages/orders/Orders';
// import EditOrder from '../pages/orders/EditOrders';
// import Refunds from '../pages/refund/Refunds';
// import EditRefund from '../pages/refund/EditRefund';
import Event from '../pages/event/Event';
import EditEvent from '../pages/event/EditEvent';
import ViewEvent from '../pages/event/ViewEvent';
import EventReport from '../pages/eventReport/EventReport';
import EditEventReport from '../pages/eventReport/EditEventReport';
import ViewEventReport from '../pages/eventReport/ViewEventReport';
import ProductCategory from '../pages/productCategory/ProductCategory';
import EditProductCategory from '../pages/productCategory/EditProductCategory';
import Catalogue from '../pages/catalogues/Catalogue';
import EditCatalogue from '../pages/catalogues/EditCatalogue';
import PbbReport from '../pages/pbbReport/PbbReport';
import EditPbbReport from '../pages/pbbReport/EditPbbReport';
import ViewPbbReport from '../pages/pbbReport/ViewPbbReport';
import PbbDraft from '../pages/pbbDraft/PbbDraft';

const routes = [
  {
    label: 'Users',
    children: [
      {
        label: 'All Users',
        path: '/admin/users',
        component: Users,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'New User',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin', 'salesManager', 'salesAgent', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin', 'pbbAdmin', 'salesManager', 'salesAgent', 'superAdmin', 'internalUser', 'externalUser'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Events',
    children: [
      {
        label: 'All Events',
        path: '/admin/events',
        component: Event,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'New Event',
        path: '/admin/new-event',
        component: EditEvent,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Edit Event',
        path: '/admin/edit-event/:eventId',
        component: EditEvent,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-event/:id',
        component: ViewEvent,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },

  {
    label: 'Event Reports',
    children: [
      {
        label: 'All Event Reports',
        path: '/admin/event-reports',
        component: EventReport,
        allowed: ['admin', 'salesManager', 'salesAgent', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'New Event Report',
        path: '/admin/new-event-report',
        component: EditEventReport,
        allowed: ['admin', 'salesAgent', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Edit Event Report',
        path: '/admin/edit-event-report/:eventId',
        component: EditEventReport,
        allowed: ['admin', 'salesAgent', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-event-report/:id',
        component: ViewEventReport,
        allowed: ['admin', 'salesManager', 'salesAgent', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Catalogues',
    children: [
      {
        label: 'All Catalogues',
        path: '/admin/catalogues',
        component: Catalogue,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'New Catalogue',
        path: '/admin/new-catalogue',
        component: EditCatalogue,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
      {
        label: 'Edit Catalogue',
        path: '/admin/edit-catalogue/:id',
        component: EditCatalogue,
        allowed: ['admin', 'superAdmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Product Categories',
    children: [
      {
        label: 'All Product Categories',
        path: '/admin/product-category',
        component: ProductCategory,
        allowed: ['superAdmin'],
      },
    ],
  },
  {
    label: 'PBB Report',
    children: [
      {
        label: 'All PBB Reports',
        path: '/admin/pbb-reports',
        component: PbbReport,
        allowed: ['pbbAdmin', 'superAdmin', 'internalUser', 'externalUser', 'salesManager', 'salesAgent'],
        showInMenu: false,
      },
      {
        label: 'All PBB Drafts',
        path: '/admin/pbb-drafts',
        component: PbbDraft,
        allowed: ['pbbAdmin', 'superAdmin', 'internalUser', 'externalUser', 'salesManager', 'salesAgent'],
        showInMenu: false,
      },
      {
        label: 'New PBB Reports',
        path: '/admin/new-pbb-report',
        component: EditPbbReport,
        allowed: ['pbbAdmin', 'superAdmin', 'internalUser', 'externalUser', 'salesManager', 'salesAgent'],
        showInMenu: false,
      },
      {
        label: 'Edit PBB Report',
        path: '/admin/edit-pbb-report/:id',
        component: EditPbbReport,
        allowed: ['pbbAdmin', 'superAdmin', 'internalUser', 'externalUser', 'salesManager', 'salesAgent'],
        showInMenu: false,
      },
      {
        label: 'View PBB Report',
        path: '/admin/view-pbb-report/:id',
        component: ViewPbbReport,
        allowed: ['pbbAdmin', 'superAdmin', 'internalUser', 'externalUser', 'salesManager', 'salesAgent'],
        showInMenu: false,
      },
    ],
  },
  // View user stoji ovde samo kao ruta koja omogucava da se klikom na ikonicu profil redirectuje na profil
  // Ne dirati
  // {
  //   label: 'Gallery',
  //   allowed: ['admin'],
  //   children: [
  //     {
  //       label: 'Gallery',
  //       path: '/admin/gallery',
  //       component: Gallery,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New Content',
  //       path: '/admin/gallery/new-content',
  //       component: GalleryNewContent,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Data',
  //   children: [
  //     {
  //       label: 'All Data',
  //       path: '/admin/data',
  //       component: Data,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New Data',
  //       path: '/admin/new-data',
  //       component: EditData,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit Data',
  //       path: '/admin/edit-data/:dataId',
  //       component: EditData,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Categories',
  //       path: '/admin/data-categories',
  //       component: Categories,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Add/Edit Category',
  //       path: '/admin/data-categories/:categoryId',
  //       component: Categories,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Attributes',
  //       path: '/admin/data-attributes',
  //       component: Attributes,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Post',
  //   children: [
  //     {
  //       label: 'All posts',
  //       path: '/admin/posts',
  //       component: Post,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New post',
  //       path: '/admin/new-post',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit post',
  //       path: '/admin/edit-post/:postId',
  //       component: EditPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'All DraftPosts',
  //       path: '/admin/draft-post/:postId',
  //       component: DraftPost,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'All tags',
  //       path: '/admin/tags',
  //       component: Tag,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New tag',
  //       path: '/admin/new-tag',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //     {
  //       label: 'Edit tag',
  //       path: '/admin/edit-tag/:tagId',
  //       component: EditTag,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Newsletter',
  //   children: [
  //     {
  //       label: 'All newsletter',
  //       path: '/admin/newsletter',
  //       component: Newsletter,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },

  // {
  //   label: 'Pages',
  //   children: [
  //     {
  //       label: 'All pages',
  //       path: '/admin/Pages',
  //       component: Pages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New page',
  //       path: '/admin/new-page',
  //       component: EditPages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit page',
  //       path: '/admin/edit-page/:id',
  //       component: EditPages,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Orders',
  //   children: [
  //     {
  //       label: 'All orders',
  //       path: '/admin/orders',
  //       component: Orders,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit order',
  //       path: '/admin/edit-order/:orderId',
  //       component: EditOrder,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Invoices',
  //   children: [
  //     {
  //       label: 'All invoices',
  //       path: '/admin/invoices',
  //       component: Invoices,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'Refunds',
  //   children: [
  //     {
  //       label: 'All refunds',
  //       path: '/admin/refunds',
  //       component: Refunds,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit order',
  //       path: '/admin/edit-refunds/:refundId',
  //       component: EditRefund,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Declarations',
  //   children: [
  //     {
  //       label: 'All declarations',
  //       path: '/admin/declarations',
  //       component: Declaration,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New declaration',
  //       path: '/admin/new-declaration',
  //       component: EditDeclaration,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'Edit post',
  //       path: '/admin/edit-declaration/:declarationId',
  //       component: EditDeclaration,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Import Data',
  //   children: [
  //     {
  //       label: 'Import Data',
  //       path: '/admin/ImportData',
  //       component: ImportData,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'My profile',
  //   children: [
  //     {
  //       label: 'View',
  //       path: '/admin/view-user/:id',
  //       component: ViewUser,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Languages',
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
