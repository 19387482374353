import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Drawer, notification /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import EventForm from '../../components/forms/EventForm';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const EditEvent = (props) => {
  const currentuser = useContext(UserContext);
  const [result, setResult] = useState(false);
  const [event, fetchEvent] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const eventId = props.eventId;

  useEffect(() => {
    if (eventId) {
      fetchEvent(`${SERVER_URL}/events/${eventId}`, []);
    }
  }, [eventId, fetchEvent]);

  const updateEvent = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/events/${eventId}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: t('updateSuccessful'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/events');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const createEvent = async (data) => {
    try {
      await Axios.post(
        `${SERVER_URL}/events`,
        data,
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: t('createdSuccessfully'),
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/events');
    } catch (err) {
      notification.error({
        message: t('updateCreateProblem'),
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        {eventId && event.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {eventId && !event.isLoading && !event.isError && event.data && (
          <Drawer title={t('updateEvent')} placement="right" onClose={onClose} visible={props.showModal}>
            <EventForm
              isNew={false}
              data={event.data}
              updateHandler={updateEvent}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          </Drawer>
        )}
        {eventId && !event.isLoading && event.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!eventId && event && !event.data && (
          <Drawer title={t('addEvent')}  placement="right" onClose={onClose} visible={props.showModal}>
            <EventForm
              isNew={true}
              data={null}
              createHandler={createEvent}
              result={result}
              setResult={setResult}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(EditEvent);
