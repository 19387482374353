import React from 'react';
import { Button, Card, Col, Form, Row, Select, Input, Checkbox } from 'antd';

import { withNamespaces } from 'react-i18next';

const formInit = {
  name: undefined,
  enPrint: false,
  enPdf: false,
  dePrint: false,
  dePdf: false,
  frPrint: false,
  frPdf: false,
};

const ProductCategoryForm = ({ isNew, data, createHandler, updateHandler, t }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const onFinish = async (values, isNew) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Form.Item name='name'>
            <Input />
          </Form.Item>
          {/* <Form.Item name='dePrint' valuePropName="checked">
            <Checkbox>DE Print</Checkbox>
          </Form.Item>
          <Form.Item name='dePdf' valuePropName="checked">
            <Checkbox>DE PDF</Checkbox>
          </Form.Item>
          <Form.Item name='enPrint' valuePropName="checked">
            <Checkbox>EN Print</Checkbox>
          </Form.Item>
          <Form.Item name='enPdf' valuePropName="checked">
            <Checkbox>EN PDF</Checkbox>
          </Form.Item>
          <Form.Item name='frPrint' valuePropName="checked">
            <Checkbox>FR Print</Checkbox>
          </Form.Item>
          <Form.Item name='frPdf' valuePropName="checked">
            <Checkbox>FR PDF</Checkbox>
          </Form.Item> */}
          <Button type='primary' htmlType='submit'>
            {isNew ? t('addCategory') : t('save')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default withNamespaces()(ProductCategoryForm);
