import React, { useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  background: '#e3e3e3',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: '#fff'
};

const companyLogoStyle = {
  maxWidth: '100px',
  marginBottom: '50px'
};

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const onFinish = async () => {
    if (password === checkPassword) {
      const urlParts = window.location.href.split('/');
      const token = urlParts[urlParts.length - 1];
      try {
        const resetPassword = await Axios.post(`${SERVER_URL}/email-verification/${token}`, { password }, { withCredentials: false });
        if (resetPassword.status === 200) {
          notification.success({
            message: 'Password was successfully changed.',
            placement: 'bottomRight'
          });
          setTimeout(() => {
            window.location.href = '/admin/login';
          }, 1500);
        }
      } catch (err) {
        notification.warn({
          message: err.response.data.message,
          placement: 'bottomRight'
        });
      }
    }
    else {
      alert("Password and Confirm password don't match");
    }
  };

  return (
    <div className="log" style={backgroundStyle}>
      <div className="card-wrapper">
        <Card className="login-header" bordered={false} style={loginCardStyle}>
          <img style={companyLogoStyle} className="login-logo" src="/META_Logo_DE.svg" alt="Meta Logo" />
          <Meta
            title="Enter new password"
          />
          <Form {...layout} name='basic' onFinish={onFinish}>
            <Form.Item name='password' rules={[{ required: true, message: 'Please enter new password!' }]}>
              <Input onChange={(e) => setPassword(e.target.value)} placeholder='New password' type='password' />
            </Form.Item>
            <Form.Item>
              <Input onChange={(e) => setCheckPassword(e.target.value)} placeholder='Confirm new password' type='password' />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button type='primary' style={{ 'width': '100%' }} htmlType='submit'>Confirm</Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ChangePassword;