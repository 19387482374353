import { Card, Checkbox, Divider, Form, Input, Radio, Space, Row, Col } from 'antd';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
import EmbedImage from '../base/EmbedImage';

const SpeedRackForm = ({ data, files, setFiles, t, form }) => {
  const [singleRow, setSingleRow] = useState(data && data.singleRowSpeedRack);
  const [doubleRow, setDoubleRow] = useState(data && data.doubleRowSpeedRack);
  const [frameColor, setFrameColor] = useState(data && data.frameSpeedRack);
  const [beamsColor, setBeamsColor] = useState(data && data.beamsSpeedRack);
  const [claddingColor, setCladdingColor] = useState(data && data.claddingSpeedRack);
  const [frameHeight, setFrameHeight] = useState(data && data.frameHeightSpeedRack);
  const [shelfWidth, setShelfWidth] = useState(data && data.shelfWidthSpeedRack);
  const [shelfDepth, setShelfDepth] = useState(data && data.shelfDepthSpeedRack);
  const [steelPanels, setSteelPanels] = useState(data && data.steelPanelsSpeedRack);
  const [frameSurface, setFrameSurface] = useState(data?.frameSurfaceSpeedRack);
  const [steppedBeamsSurface, setSteppedBeamsSurface] = useState(
    data?.steppedBeamsSurfaceSpeedRack
  );
  const [backCladdingSurface, setBackCladdingSurface] = useState(
    data?.backCladdingSurfaceSpeedRack
  );

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  const shelfDepthsSpeedRack =
    i18n.language === 'German'
      ? [
          { label: '400 mm', value: '400' },
          { label: '600 mm', value: '600' },
          { label: '800 mm', value: '800' },
          { label: '', value: 'special-depth-speed-rack' }
        ]
      : [
          { label: '400 mm', value: '400' },
          { label: '600 mm', value: '600' },
          { label: '800 mm', value: '800' }
        ];
  return (
    <div>
      <h3 id='meta-speed-rack'>{t('metaSpeedRack_title')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='speedRackLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoSpeedRack'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaSpeedRack_profileConnector')}>
            <Form.Item
              name='profileConnector'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title={t('metaSpeedRack_floorFixing')}>
            <Form.Item
              name='floorFixingSpeedRack'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Radio.Group>
                <Space direction='horizontal'>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='yes'>
                    {t('yes')}
                  </Radio>
                  <Radio style={{ flexDirection: 'row-reverse' }} value='no'>
                    {t('no')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      {/* <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_surface')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div> */}
      {/* <Row gutter={16}>
        <Col xs={24} md={8}>
          <div style={{ marginBottom: '10px' }}>{t('metaMiniSpeedRack_frame')}</div>
          <Form.Item
            name='frameSurfaceSpeedRack'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                if (frameSurface === 'special color') {
                  form.setFieldValue('frameSurfaceSpecialColorSpeedRack', null);
                }

                setFrameSurface(e.target.value);
              }}
              style={{ width: '100%' }}
            >
              <Space direction='vertical' style={{ width: '100%' }} size='large'>
                <Radio className='pbbCheckbox' value='Galvanized'>
                  {t('metaMiniSpeedRack_galvanized')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 7035 light grey'>
                  {t('RAL7035lightgrey')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 5010 gentian blue'>
                  {t('RAL5010GentianBlue')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='special color'>
                  Special RAL color{' '}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {frameSurface === 'special color' && (
            <Form.Item
              name='frameSurfaceSpecialColorSpeedRack'
              rules={[{ required: true, message: t('validationAlert') }]}
            >
              <Input placeholder='Special RAL color:' />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} md={8}>
          <div style={{ marginBottom: '10px' }}>{t('metaMiniSpeedRack_steppedBeams')}</div>
          {/* <Form.Item
            name='beamsSpeedRack'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Radio.Group onChange={(e) => setBeamsColor(e.target.value)}>
              <Space direction='vertical'>
                <Radio className='pbbCheckbox' value='galvanizedBeamsSpeedRack'>
                  {t('metaMiniSpeedRack_galvanized')}
                </Radio>
                <Radio className='pbbCheckbox' value='ralGreyBeamsSpeedRack'>
                  {t('RAL7035lightgrey')}
                </Radio>
                <Radio className='pbbCheckbox' value='ralBlueBeamsSpeedRack'>
                  {t('RAL5010GentianBlue')}
                </Radio>
                <Radio className='pbbCheckbox' value='Special Color'>
                  {' '}
                  {t('metaMultiTiers_specialRALColor')}{' '}
                </Radio>
                {beamsColor === 'Special Color' && (
                  <Form.Item
                    name='specialColorBeamsSpeedRack'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                  </Form.Item>
                )}
              </Space>
            </Radio.Group>
          </Form.Item> */}
      {/* <Form.Item
            name='steppedBeamsSurfaceSpeedRack'
            rules={[{ required: true, message: t('validationAlert') }]}
          >
            <Radio.Group
              onChange={(e) => {
                if (steppedBeamsSurface === 'special color') {
                  form.setFieldValue('steppedBeamsSurfaceSpecialColorSpeedRack', null);
                }

                setSteppedBeamsSurface(e.target.value);
              }}
              style={{ width: '100%' }}
            >
              <Space direction='vertical' style={{ width: '100%' }} size='large'>
                <Radio className='pbbCheckbox' value='Galvanized'>
                  {t('metaMiniSpeedRack_galvanized')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 7035 light grey'>
                  {t('RAL7035lightgrey')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 5010 gentian blue'>
                  {t('RAL5010GentianBlue')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='special color'>
                  Special RAL color{' '}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {steppedBeamsSurface === 'special color' && (
            <Form.Item
              name='steppedBeamsSurfaceSpecialColorSpeedRack'
              rules={[{ required: true, message: t('validationAlert') }]}
            >
              <Input placeholder='Special RAL color:' />
            </Form.Item>
          )}
        </Col>
        <Col xs={24} md={8}>
          <div style={{ marginBottom: '10px' }}>{t('metaMiniSpeedRack_backCladding')}</div> */}
      {/* <Form.Item
            name='claddingSpeedRack'
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Radio.Group onChange={(e) => setCladdingColor(e.target.value)}>
              <Space direction='vertical'>
                <Radio className='pbbCheckbox' value='galvanizedCladdingSpeedRack'>
                  {t('metaMiniSpeedRack_galvanized')}
                </Radio>
                <Radio className='pbbCheckbox' value='ralGreyCladdingSpeedRack'>
                  {t('RAL7035lightgrey')}
                </Radio>
                <Radio className='pbbCheckbox' value='ralBlueCladdingSpeedRack'>
                  {t('RAL5010GentianBlue')}
                </Radio>
                <Radio className='pbbCheckbox' value='Special Color'>
                  {' '}
                  {t('metaMultiTiers_specialRALColor')}{' '}
                </Radio>
                {claddingColor === 'Special Color' && (
                  <Form.Item
                    name='specialColorCladdingSpeedRack'
                    rules={[
                      {
                        required: true,
                        message: t('validationAlert'),
                      },
                    ]}
                  >
                    <Input placeholder={t('metaMultiTiers_specialRALColor')} />
                  </Form.Item>
                )}
              </Space>
            </Radio.Group>
          </Form.Item> */}
      {/* <Form.Item
            name='backCladdingSurfaceSpeedRack'
            rules={[{ required: true, message: t('validationAlert') }]}
          >
            <Radio.Group
              onChange={(e) => {
                if (backCladdingSurface === 'special color') {
                  form.setFieldValue('backCladdingSurfaceSpecialColorSpeedRack', null);
                }

                setBackCladdingSurface(e.target.value);
              }}
              style={{ width: '100%' }}
            >
              <Space direction='vertical' style={{ width: '100%' }} size='large'>
                <Radio className='pbbCheckbox' value='Galvanized'>
                  {t('metaMiniSpeedRack_galvanized')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 7035 light grey'>
                  {t('RAL7035lightgrey')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='RAL 5010 gentian blue'>
                  {t('RAL5010GentianBlue')}{' '}
                </Radio>
                <Radio className='pbbCheckbox' value='special color'>
                  Special RAL color{' '}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {backCladdingSurface === 'special color' && (
            <Form.Item
              name='backCladdingSurfaceSpecialColorSpeedRack'
              rules={[{ required: true, message: t('validationAlert') }]}
            >
              <Input placeholder='Special RAL color:' />
            </Form.Item>
          )}
        </Col>
      </Row> */}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_frameHeight')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Form.Item
          name='frameHeightSpeedRack'
          // valuePropName='checked'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              { label: i18n.language === 'German' ? '1.970 mm' : '1,970 mm', value: '1970' },
              { label: i18n.language === 'German' ? '2.470 mm' : '2,470 mm', value: '2470' },
              { label: i18n.language === 'German' ? '2.970 mm' : '2,970 mm', value: '2970' },
              { label: '', value: 'special-height' }
            ]}
            onChange={(value) => {
              const specialHeightInForm = form.getFieldValue('specialFrameHeightSpeedRack');
              // AKO SE DESELEKTUJE "special-height" CHECKBOX A PRETHODNO JE UNETA VREDNOST U "special-height" INPUT, BRISE SE specialHeightClip
              // IZ FORM OBJEKTA
              if (specialHeightInForm && !value.includes('special-height')) {
                form.setFieldValue('specialFrameHeightSpeedRack', null);
              }
              // AKO POSTOJI VALIDATION ERROR ZA "special-height" INPUT I DESELEKTUJE SE "special-height" CHECKBOX, BRISE SE ERROR
              // JER POLJE VISE NIJE REQUIRED
              if (
                form.getFieldError('specialFrameHeightSpeedRack').length > 0 &&
                !value.includes('special-height')
              ) {
                form.setFields([
                  {
                    name: 'specialFrameHeightSpeedRack',
                    errors: []
                  }
                ]);
              }
              // SETOVANJE STATE-A DA BI SE IZAZVAO RE-RENDER
              setFrameHeight(value);
            }}
          />
        </Form.Item>
        <Form.Item
          name='specialFrameHeightSpeedRack'
          rules={[
            { required: frameHeight?.includes('special-height'), message: t('validationAlert') }
          ]}>
          <Input
            type='number'
            disabled={!frameHeight?.includes('special-height')}
            placeholder={`${t('specialHeight')}:`}
            suffix='mm'
          />
        </Form.Item>
      </Row>
      {/* <Form.Item
        name='frameHeightSpeedRack'
        rules={[
          {
            required: true,
            message: t('validationAlert'),
          },
        ]}
      >
        <Checkbox.Group defaultValue={frameHeight} onChange={(e) => setFrameHeight(e)}>
          <Space direction='horizontal'>
            <Checkbox value='1,970 mm' className='pbbCheckbox'>
              1,970 mm
            </Checkbox>
            <Checkbox value='2,470 mm' className='pbbCheckbox'>
              2,470 mm
            </Checkbox>
            <Checkbox value='2,970 mm' className='pbbCheckbox'>
              2,970 mm
            </Checkbox>
            <Checkbox value='custom' className='pbbCheckbox'>
              {t('metaMiniSpeedRack_specialHeight')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
      {frameHeight?.includes('custom') && (
        <Form.Item
          name='customFrameHeightSpeedRack'
          rules={[
            {
              required: true,
              message: t('validationAlert'),
            },
          ]}
        >
          <Input type='number' placeholder={t('metaMiniSpeedRack_specialHeight')} />
        </Form.Item>
      )} */}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_shelfWidth')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      {/* <Form.Item
        name='shelfWidthSpeedRack'
        rules={[
          {
            required: true,
            message: t('validationAlert'),
          },
        ]}
      >
        <Checkbox.Group defaultValue={shelfWidth} onChange={(e) => setShelfWidth(e)}>
          <Space direction='horizontal'>
            <Checkbox value='1,700 mm (500 kg)' className='pbbCheckbox'>
              1,700 mm (500 kg)
            </Checkbox>
            <Checkbox value='2,000 mm (500 kg)' className='pbbCheckbox'>
              2,000 mm (500 kg)
            </Checkbox>
            <Checkbox value='2,500 mm (400 kg)' className='pbbCheckbox'>
              2,500 mm (400 kg)
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item> */}
      <Row gutter={16}>
        <Form.Item
          name='shelfWidthSpeedRack'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              {
                label: i18n.language === 'German' ? '1.700 mm (500 kg)' : '1,700 mm (500 kg)',
                value: '1700'
              },
              {
                label: i18n.language === 'German' ? '2.000 mm (500 kg)' : '2,000 mm (500 kg)',
                value: '2000'
              },
              {
                label: i18n.language === 'German' ? '2.500 mm (400 kg)' : '2,500 mm (400 kg)',
                value: '2500'
              }
            ]}
          />
        </Form.Item>
      </Row>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_shelfDepth')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      {/* <Form.Item
        name='shelfDepthSpeedRack'
        rules={[
          {
            required: true,
            message: t('validationAlert'),
          },
        ]}
      >
        <Checkbox.Group defaultValue={shelfDepth} onChange={(e) => setShelfDepth(e)}>
          <Space direction='horizontal'>
            <Checkbox value='400 mm' className='pbbCheckbox'>
              400 mm
            </Checkbox>
            <Checkbox value='600 mm' className='pbbCheckbox'>
              600 mm
            </Checkbox>
            <Checkbox value='800 mm' className='pbbCheckbox'>
              800 mm
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item> */}
      <Row gutter={16}>
        <Form.Item
          name='shelfDepthSpeedRack'
          // valuePropName='checked'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={shelfDepthsSpeedRack}
            onChange={(value) => {
              const specialDepthInForm = form.getFieldValue('shelfDepthSpecialFeaturesSpeedRack');
              if (specialDepthInForm && !value.includes('special-depth-speed-rack')) {
                form.setFieldValue('shelfDepthSpecialFeaturesSpeedRack', null);
              }
              if (
                form.getFieldError('shelfDepthSpecialFeaturesSpeedRack').length > 0 &&
                !value.includes('special-depth-speed-rack')
              ) {
                form.setFields([
                  {
                    name: 'shelfDepthSpecialFeaturesSpeedRack',
                    errors: []
                  }
                ]);
              }
              setShelfDepth(value);
            }}
          />
        </Form.Item>
        {i18n.language === 'German' && (
          <Form.Item
            name='shelfDepthSpecialFeaturesSpeedRack'
            rules={[
              {
                required: shelfDepth?.includes('special-depth-speed-rack'),
                message: t('validationAlert')
              }
            ]}>
            <Input
              type='number'
              disabled={!shelfDepth?.includes('special-depth-speed-rack')}
              placeholder={`${t('metaSpeedRack_specialFeatures')}`}
            />
          </Form.Item>
        )}
      </Row>
      {i18n.language === 'English' && (
        <Row>
          <Form.Item name='shelfDepthSpecialFeaturesSpeedRack'>
            <Input type='number' placeholder={`${t('metaSpeedRack_specialFeatures')}`} />
          </Form.Item>
        </Row>
      )}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('metaMiniSpeedRack_floors')}</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      {/* <Form.Item
        name='steelPanelsSpeedRack'
        rules={[
          {
            required: true,
            message: t('validationAlert'),
          },
        ]}
      >
        <Checkbox.Group defaultValue={steelPanels} onChange={(e) => setSteelPanels(e)}>
          <Space direction='horizontal'>
            <Checkbox value='steelPanels' className='pbbCheckbox'>
              {t('metaMiniSpeedRack_steelPanels')}
            </Checkbox>
            <Checkbox value='chipboard' className='pbbCheckbox'>
              {t('metaMiniSpeedRack_chipboard')}
            </Checkbox>
            <Checkbox value='custom' className='pbbCheckbox'>
              {t('metaMiniSpeedRack_specialHeight')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>
      {steelPanels?.includes('custom') && (
        <Form.Item
          name='customSteelPanelsSpeedRack'
          rules={[
            {
              required: true,
              message: t('validationAlert'),
            },
          ]}
        >
          <Input type='number' placeholder={t('metaSpeedRack_numberOfShelvesPerField')} suffix={t('pieces')} />
        </Form.Item>
      )} */}
      <Row gutter={16}>
        <Form.Item
          name='floorsSpeedRack'
          rules={[{ required: true, message: t('validationAlert') }]}>
          <Checkbox.Group
            options={[
              { label: `${t('metaMiniSpeedRack_steelPanels')}`, value: 'steel-panels' },
              { label: `${t('metaMiniSpeedRack_chipboard')}`, value: 'chipboard' }
            ]}
          />
        </Form.Item>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name='shelvesPerBaySpeedRack'
            rules={[{ required: true, message: t('validationAlert') }]}
            label={`${t('metaMiniRack_shelvesPerBay')}:`}>
            <Input
              type='number'
              placeholder={`${t('metaMiniRack_shelvesPerBay')}`}
              suffix={t('pieces')}
            />
          </Form.Item>
        </Col>
      </Row>

      <EmbedImage data={data} files={files} setFiles={setFiles} name='speedRackImage' />
      <Form.Item name='specialSpeedRack' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={t('positioning/SpecialFeatures')} />
      </Form.Item>
    </div>
  );
};

export default withNamespaces()(SpeedRackForm);
