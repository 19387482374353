import { Card, Checkbox, Divider, Form, Input, Radio, Select, Space, Row, Col, Button } from 'antd';
import React, { useState } from 'react';
import EmbedImage from '../base/EmbedImage';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

const MultistrongForm = ({ data, files, setFiles, t, setHeightQty, heightQty }) => {
  const [light, setLight] = useState(data && data.light);
  const [medium, setMedium] = useState(data && data.medium);
  const [heavy, setHeavy] = useState(data && data.heavy);
  const [heightValueOne, setHeightValueOne] = useState(data && data.heightOne);
  const [cantileverLength, setCantileverLength] = useState(data && data.cantileverLength);
  const [dimension, setDimension] = useState(data && data.dimensionBetweenLines);
  const [krH, setKrH] = useState(data && data.krH);
  const [sides, setSides] = useState(data && data.sides);
  const [endStop, setEndStop] = useState(data && data.endStop);
  const [endStopCantilever, setEndStopCantilever] = useState(data && data.endStopCantilever);
  const [endStopFoot, setEndStopFoot] = useState(data && data.endStopFoot);

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  return (
    <div>
      <h3 id='meta-multistrong'>{t('metaMultistrong')}</h3>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className='pbbCard' title='Layout:'>
            <Form.Item
              name='multistrongLayout'
              rules={[{ required: true, message: t('validationAlert') }]}>
              <Checkbox.Group
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' }
                ]}
                onChange={(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false);
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoMultistrong'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert')
                }
              ]}>
              <Input.TextArea rows={3} placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item name='light' valuePropName='checked'>
            <Checkbox onChange={(event) => setLight(event.target.checked)} className='pbbCheckbox'>
              LIGHT
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name='medium' valuePropName='checked'>
            <Checkbox onChange={(event) => setMedium(event.target.checked)} className='pbbCheckbox'>
              MEDIUM
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name='heavy' valuePropName='checked' style={{ marginBottom: '35px' }}>
            <Checkbox onChange={(event) => setHeavy(event.target.checked)} className='pbbCheckbox'>
              HEAVY
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Form.Item
          name='sides'
          rules={[
            {
              required: true,
              message: t('validationAlert')
            }
          ]}>
          <Checkbox.Group defaultValue={sides} onChange={(e) => setSides(e)}>
            <Space direction='horizontal'>
              <Checkbox value='singleSided' className='pbbCheckboxThree'>
                {t('metaMultistrong_singleSided')}
              </Checkbox>
              <Checkbox value='doubleSided' className='pbbCheckboxThree'>
                {t('metaMultistrong_doubleSided')}
              </Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        {sides?.map((side) => (
          <Form.Item
            name={`${side}Pieces`}
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Input placeholder={t(`metaMultistrong_${side}`)} type='number' suffix={t('pieces')} />
          </Form.Item>
        ))}
      </Row> */}

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('surface')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        {light && (
          <Col xs={24} md={8}>
            <Card className='pbbCard' title='Kr-L'>
              <Form.Item
                name='krL'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}
                initialValue='RAL 5010 gentian blue'>
                <Radio.Group defaultValue='RAL 5010 gentian blue' value='RAL 5010 gentian blue'>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='RAL 5010 gentian blue' checked>
                      {t('gentianBlueRAL')}
                    </Radio>
                    {/* <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                      {t('lightGreyRAL')}
                    </Radio> */}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
        )}
        {medium && (
          <Col xs={24} md={8}>
            <Card className='pbbCard' title='Kr-M'>
              <Form.Item
                name='krM'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}
                initialValue='RAL 5010 gentian blue'>
                <Radio.Group defaultValue='RAL 5010 gentian blue' value='RAL 5010 gentian blue'>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='RAL 5010 gentian blue' checked>
                      {t('gentianBlueRAL')}
                    </Radio>
                    {/* <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                      {t('lightGreyRAL')}
                    </Radio> */}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
        )}
        {heavy && (
          <Col xs={24} md={8}>
            <Card className='pbbCard' title='Kr-H'>
              <Form.Item
                name='krH'
                rules={[
                  {
                    required: true,
                    message: t('validationAlert')
                  }
                ]}>
                <Radio.Group onChange={(e) => setKrH(e.target.value)}>
                  <Space direction='vertical'>
                    <Radio className='pbbRadioOption' value='RAL 5010 gentian blue'>
                      {t('gentianBlueRAL')}
                    </Radio>
                    <Radio className='pbbRadioOption' value='RAL 7035 light grey'>
                      {t('lightGreyRAL')}
                    </Radio>
                    <Radio className='pbbRadioOption' value='Hot-dip galvanized'>
                      {t('metaMultistrong_hotDipGalvanized')}
                    </Radio>
                    <Radio className='pbbRadioOption' value='Special RAL color'>
                      {t('metaMultistrong_specialRALColor')}
                    </Radio>
                    {krH === 'Special RAL color' && (
                      <Form.Item
                        name='customKrH'
                        rules={[
                          {
                            required: true,
                            message: t('validationAlert')
                          }
                        ]}>
                        <Input placeholder={t('metaMultistrong_specialRALColor')} />
                      </Form.Item>
                    )}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
        )}
      </Row>

      <Form.Item
        name='storageLevelsNum'
        rules={[
          {
            required: true,
            message: t('validationAlert')
          }
        ]}
        label={`${t('metaMultistrong_noOfStorageLvl')}:`}>
        <Input
          type='number'
          placeholder={`${t('metaMultistrong_noOfStorageLvl')}`}
          suffix={t('metaMultistrong_noOfStorageLvlSuffix')}
        />
      </Form.Item>

      <Card className='pbbCard' title={t('metaMultistrong_endStop')}>
        {/*<Row gutter={16} style={{ paddingLeft: '16px' }}>
          <Form.Item name='endStop' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}>
            <Checkbox.Group className='checkboxGroup' defaultValue={endStop} onChange={e => setEndStop(e)}>
              <Checkbox value='cantilevers'>{t('metaMultistrong_cantilevers')}</Checkbox>
              <Checkbox value='foot'>{t('metaMultistrong_foot')}</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          {endStop?.map((stop) => (
            <Form.Item
              name={stop}
              rules={[
                {
                  required: true,
                  message: t('validationAlert'),
                },
              ]}
            >
              <Select style={{ width: '10rem' }} placeholder={stop === 'cantilevers' ? t('metaMultistrong_cantilevers') : stop === 'foot' ? t('metaMultistrong_foot') : stop} mode='tags' showArrow>
                <Select.Option value='170 mm'>170 mm</Select.Option>
                <Select.Option value='270 mm'>270 mm</Select.Option>
                {heavy && <Select.Option value='200 mm'>200 mm</Select.Option>}
              </Select>
            </Form.Item>
          ))}
            </Row>*/}
        <Row gutter={16} style={{ paddingLeft: '16px', marginBottom: '5px' }}>
          <Form.Item
            name='endStopCantilever'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t("validationAlert")
            //   }
            // ]}
          >
            <Checkbox onChange={(e) => setEndStopCantilever(e.target.checked)}>
              {t('metaMultistrong_cantilevers')}
            </Checkbox>
          </Form.Item>
          {endStopCantilever && (
            <Form.Item
              name='cantilevers'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              {/* <Input placeholder={t('metaMultistrong_cantilevers')} /> */}
              {/* <Select
                style={{ width: '10rem' }}
                placeholder={t('metaMultistrong_cantilevers')}
                mode='tags'
                showArrow>
                <Select.Option value='170 mm'>170 mm</Select.Option>
                <Select.Option value='270 mm'>270 mm</Select.Option> */}
              {/* {heavy && <Select.Option value='200 mm'>200 mm</Select.Option>} */}
              {/* </Select> */}
              <Checkbox.Group style={{ marginLeft: '2rem' }}>
                <Space direction='horizontal'>
                  <Checkbox value='170 mm'>170 mm</Checkbox>
                  <Checkbox value='270 mm'>270 mm</Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
          )}
        </Row>
        <Row gutter={16} style={{ paddingLeft: '16px' }}>
          <Form.Item
            name='endStopFoot'
            valuePropName='checked'
            // rules={[
            //   {
            //     required: true,
            //     message: t("validationAlert")
            //   }
            // ]}
          >
            <Checkbox onChange={(e) => setEndStopFoot(e.target.checked)}>
              {t('metaMultistrong_foot')}
            </Checkbox>
          </Form.Item>
          {endStopFoot && (
            <Form.Item
              name='foot'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Checkbox.Group style={{ marginLeft: '2rem' }}>
                <Space direction='horizontal'>
                  <Checkbox value='170 mm'>170 mm</Checkbox>
                  <Checkbox value='270 mm'>270 mm</Checkbox>
                </Space>
              </Checkbox.Group>
            </Form.Item>
          )}
        </Row>
      </Card>

      <div className='sectionTitle'>
        <h4 className='pbb-section-title'>{t('height')}:</h4>
        <Divider className='pbbReportDivider' style={{ minWidth: 'unset', marginTop: '15px' }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name='heightOne'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}>
            <Select onChange={(value) => setHeightValueOne(value)} placeholder={t('height')}>
              {(light || medium) && (
                <Select.Option value='2000 mm'>
                  {i18n.language === 'German' ? '2.000 mm' : '2,000 mm'}
                </Select.Option>
              )}
              {(light || medium) && (
                <Select.Option value='2500 mm'>
                  {i18n.language === 'German' ? '2.500 mm' : '2,500 mm'}
                </Select.Option>
              )}
              {(light || medium || heavy) && (
                <Select.Option value='3000 mm'>
                  {i18n.language === 'German' ? '3.000 mm' : '3,000 mm'}
                </Select.Option>
              )}
              {medium && (
                <Select.Option value='3500 mm'>
                  {i18n.language === 'German' ? '3.500 mm' : '3,500 mm'}
                </Select.Option>
              )}
              {heavy && (
                <Select.Option value='4000 mm'>
                  {i18n.language === 'German' ? '4.000 mm' : '4,000 mm'}
                </Select.Option>
              )}
              {/* <Select.Option value='5000 mm'>{i18n.language === 'German' ? '5.000 mm' : '5,000 mm'}</Select.Option>
              <Select.Option value='5500 mm'>{i18n.language === 'German' ? '5.500 mm' : '5,500 mm'}</Select.Option>
              <Select.Option value='6000 mm'>{i18n.language === 'German' ? '6.000 mm' : '6,000 mm'}</Select.Option>
              <Select.Option value='custom'>{t('enterCustomHeight')}</Select.Option> */}
            </Select>
          </Form.Item>
          {heightValueOne === 'custom' && (
            <Form.Item
              name='heightOneCustom'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' placeholder='Custom height:' suffix='mm' />
            </Form.Item>
          )}
          <div>
            <div style={{ marginBottom: '10px' }}>{t('metaMultistrong_overhang')}:</div>
            <Form.Item
              name='overhangMultistrong'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' placeholder='mm' />
            </Form.Item>
          </div>
        </Col>
        <Form.List name='heights'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <React.Fragment key={field.key}>
                  <Col xs={24} md={6}>
                    <Form.Item name={[field.name, 'height']}>
                      <Select
                        placeholder='Height'
                        rules={[
                          {
                            required: true,
                            message: t('validationAlert')
                          }
                        ]}>
                        {(light || medium) && (
                          <>
                            <Select.Option value='2000 mm'>
                              {i18n.language === 'German' ? '2.000 mm' : '2,000 mm'}
                            </Select.Option>
                            <Select.Option value='2500 mm'>
                              {i18n.language === 'German' ? '2.500 mm' : '2,500 mm'}
                            </Select.Option>
                          </>
                        )}
                        {(light || medium || heavy) && (
                          <Select.Option value='3000 mm'>
                            {i18n.language === 'German' ? '3.000 mm' : '3,000 mm'}
                          </Select.Option>
                        )}
                        {medium && (
                          <Select.Option value='3500 mm'>
                            {i18n.language === 'German' ? '3.500 mm' : '3,500 mm'}
                          </Select.Option>
                        )}
                        {heavy && (
                          <Select.Option value='4000 mm'>
                            {i18n.language === 'German' ? '4.000 mm' : '4,000 mm'}
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                    {field.name === 'custom' && (
                      <Form.Item
                        name={[field.name, 'customHeight']}
                        rules={[
                          {
                            required: true,
                            message: t('validationAlert')
                          }
                        ]}>
                        <Input type='number' placeholder='Custom height:' suffix='mm' />
                      </Form.Item>
                    )}
                    <div>
                      <div style={{ marginBottom: '10px' }}>{t('metaMultistrong_overhang')}:</div>
                      <Form.Item
                        name={[field.name, 'overhang']}
                        rules={[
                          {
                            required: true,
                            message: t('validationAlert')
                          }
                        ]}>
                        <Input type='number' placeholder='mm' />
                      </Form.Item>
                    </div>
                    <Button onClick={() => remove(field.name)}>{t('remove')}</Button>
                  </Col>
                </React.Fragment>
              ))}
              {fields.length < 9 && (
                <Col xs={24} md={3}>
                  <Form.Item>
                    <Button type='primary' onClick={() => add()}>
                      {t('addMoreBtn')}
                    </Button>
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Form.List>
      </Row>

      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name='carrierLoadMultistrong'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaMultistrong_loadCarrier')}:`}>
            <Input placeholder={t('metaMultistrong_loadCarrier')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name='dimensionsMultistrong'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaMultistrong_dimensions')}:`}>
            <Input placeholder={t('metaMultistrong_dimensions')} />
          </Form.Item>
        </Col>
      </Row>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item
            name='cantileverLength'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaMultistrong_cantileverLenght')}:`}>
            <Select
              onChange={(value) => setCantileverLength(value)}
              placeholder={t('metaMultistrong_cantileverLenght')}>
              {(light || medium) && (
                <>
                  <Select.Option value='400 mm'>400 mm</Select.Option>
                  <Select.Option value='500 mm'>500 mm</Select.Option>
                  <Select.Option value='600 mm'>600 mm</Select.Option>
                </>
              )}
              {(medium || heavy) && <Select.Option value='800 mm'>800 mm</Select.Option>}
              {heavy && (
                <>
                  <Select.Option value='1000 mm'>1.000 mm</Select.Option>
                  <Select.Option value='1250 mm'>1.250 mm</Select.Option>
                </>
              )}
              {/* <Select.Option value='1500 mm'>{i18n.language === 'German' ? '1.500 mm' : '1,500 mm'}</Select.Option>
              <Select.Option value='1750 mm'>{i18n.language === 'German' ? '1.750 mm' : '1,750 mm'}</Select.Option>
              <Select.Option value='2000 mm'>{i18n.language === 'German' ? '2.000 mm' : '2,000 mm'}</Select.Option>
              <Select.Option value='custom'>{t('customLength')}</Select.Option> */}
            </Select>
          </Form.Item>
          {cantileverLength === 'custom' && (
            <Form.Item
              name='customCantileverLength'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}>
              <Input type='number' placeholder='Custom cantilever length (mm)' />
            </Form.Item>
          )}
          {cantileverLength && (
            <div>
              <Form.Item name='armLoad'>
                <Input type='number' placeholder={t('metaMultistrong_armLoad')} suffix='kg' />
              </Form.Item>
              <Form.Item name='uprightLoad'>
                <Input type='number' placeholder={t('metaMultistrong_uprightLoad')} suffix='kg' />
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
      <Divider className='pbbReportDivider' />
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name='dimensionBetweenLines'
            rules={[
              {
                required: true,
                message: t('validationAlert')
              }
            ]}
            label={`${t('metaMultistrong_dimensionsCenterLines')}:`}>
            <Select
              onChange={(value) => setDimension(value)}
              placeholder={`${t('metaMultistrong_dimensionsCenterLines')}:`}>
              {heavy && (
                <Select.Option value='1,000 mm'>
                  {i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}
                </Select.Option>
              )}
              {(light || medium) && (
                <Select.Option value='1,030 mm'>
                  {i18n.language === 'German' ? '1.030 mm' : '1,030 mm'}
                </Select.Option>
              )}
              {/* {heavy && <Select.Option value='1,250 mm'>{i18n.language === 'German' ? '1.250 mm' : '1,250 mm'}</Select.Option>} */}
              {(light || medium) && (
                <Select.Option value='1,330 mm'>
                  {i18n.language === 'German' ? '1.330 mm' : '1,330 mm'}
                </Select.Option>
              )}
              {/* {heavy && <Select.Option value='1,500 mm'>{i18n.language === 'German' ? '1.500 mm' : '1,500 mm'}</Select.Option>} */}
              {/* <Select.Option value='special'>{t('metaMultistrong_specialDimension')}</Select.Option> */}
              {/* {heavy && <Select.Option value='Gratings'>{t('metaMultistrong_gratings')}</Select.Option>} */}
              {/* {(light || medium) && <Select.Option value='Shelves'>{t('metaMultistrong_shelves')}</Select.Option>} */}
            </Select>
          </Form.Item>
          {dimension === 'special' && (
            <Form.Item
              name='specialDimension'
              rules={[
                {
                  required: true,
                  message: t('validationAlert')
                }
              ]}
              label={`${t('metaMultistrong_specialDimension')}:`}>
              <Input
                type='number'
                placeholder={t('metaMultistrong_specialDimension')}
                suffix='mm'
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      <EmbedImage data={data} files={files} setFiles={setFiles} name='multistrongImage' />
      <Form.Item name='specialMultistrong' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={t('positioning/SpecialFeatures')} />
      </Form.Item>
    </div>
  );
};

export default withNamespaces()(MultistrongForm);
