import React, { useContext, useEffect } from 'react';
import { Button, Collapse } from 'antd';
import { CheckOutlined, ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';

import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

const { Panel } = Collapse;

const ViewEventReport = (props) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [eventReport, fetchEventReport] = useAxios('', null, currentuser.data.token, 'get');
  const [selectedCatalogues, fetchSelectedCatalogues] = useAxios(
    '',
    null,
    currentuser.data.token,
    'get'
  );

  useEffect(() => {
    if (id) {
      fetchEventReport(`${SERVER_URL}/event-reports/${id}`, []);
    }
  }, [id, fetchEventReport]);
  useEffect(() => {
    if (id) {
      const filter = { eventReport: id };
      fetchSelectedCatalogues(
        `${SERVER_URL}/selected-catalogue?filter=${JSON.stringify(filter)}`,
        []
      );
    }
  }, [id, fetchSelectedCatalogues]);
  let imageUrl;
  if (eventReport && eventReport.data && eventReport.data.leadImage) {
    imageUrl =
      eventReport &&
      eventReport.data &&
      eventReport.data.leadImage &&
      eventReport.data.leadImage.url
        ? eventReport.data.leadImage.url
        : eventReport.data.leadImage;
  }

  const getUrl = (itemUrl) => {
    let url = '';
    if (itemUrl) url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;
    return url;
  };

  let formattedDate;
  if (eventReport && eventReport.data && eventReport.data.date) {
    formattedDate = new Date(eventReport.data.date).toLocaleString();
  }

  const interests = [
    'shelving',
    'palletRacking',
    'cantilever',
    'multibloc',
    'rackInspection',
    'lighting',
    'conveyoring'
  ];

  return (
    <div className='dashboard'>
      <div className='actions-block event-report__actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => (window.location.href = '/admin/event-reports')} type='primary'>
          {t('eventReports')}
        </Button>
      </div>
      <div className='event-report'>
        {eventReport && eventReport.data && (
          <>
            <div className='event-report__data'>
              {eventReport.data.event && (
                <p className='event-report__data-row'>
                  <strong>{t('event')}:</strong> {eventReport.data.event.name}
                </p>
              )}
              {eventReport.data.company && (
                <p className='event-report__data-row'>
                  <strong>{t('company')}:</strong> {eventReport.data.company}{' '}
                  {imageUrl && (
                    <a target='_blank' rel='noreferrer' href={imageUrl}>
                      ({t('visitCardImage')})
                    </a>
                  )}
                </p>
              )}
              {eventReport.data.name && (
                <p className='event-report__data-row'>
                  <strong>{t('firstName')}:</strong> {eventReport.data.name}
                </p>
              )}
              {eventReport.data.lastName && (
                <p className='event-report__data-row'>
                  <strong>{t('lastName')}:</strong> {eventReport.data.lastName}
                </p>
              )}
              {eventReport.data.jobFunction && (
                <p className='event-report__data-row'>
                  <strong>{t('jobFunction')}:</strong> {eventReport.data.jobFunction}
                </p>
              )}
              {eventReport.data.street && (
                <p className='event-report__data-row'>
                  <strong>{t('street')}:</strong> {eventReport.data.street}
                </p>
              )}
              <div className='event-report__address-group'>
                {eventReport.data.zipCode && (
                  <p className='event-report__data-row'>
                    <strong>{t('zipCode')}:</strong> {eventReport.data.zipCode}
                  </p>
                )}
                {eventReport.data.city && (
                  <p className='event-report__data-row'>
                    <strong>{t('city')}:</strong> {eventReport.data.city}
                  </p>
                )}
                {eventReport.data.country && (
                  <p className='event-report__data-row'>
                    <strong>{t('country')}:</strong> {eventReport.data.country}
                  </p>
                )}
              </div>
              {eventReport.data.phoneNumber && (
                <p className='event-report__data-row'>
                  <strong>{t('phoneNumber')}:</strong> {eventReport.data.phoneNumber}
                </p>
              )}
              {eventReport.data.email && (
                <p className='event-report__data-row'>
                  <strong>{t('email')}:</strong> {eventReport.data.email}
                </p>
              )}
              {eventReport.data.date && (
                <p className='event-report__data-row'>
                  <strong>{t('date')}:</strong> {formattedDate}
                </p>
              )}
              <p className='event-report__data-row'>
                <strong>{t('newLead')}:</strong> {eventReport.data.newLead ? t('yes') : t('no')}
              </p>
              <p className='event-report__data-row'>
                <strong>{t('dealer')}:</strong> {eventReport.data.dealer ? t('yes') : t('no')}
              </p>
              <p className='event-report__data-row'>
                <strong>{t('project')}:</strong> {eventReport.data.project ? t('yes') : t('no')}
              </p>
              {eventReport.data.metaCustomerCode && (
                <p className='event-report__data-row'>
                  <strong>{t('metaCustomerCode')}:</strong> {eventReport.data.metaCustomerCode}
                </p>
              )}
              {eventReport.data.visitReason && (
                <p className='event-report__data-row'>
                  <strong>{t('visitReason')}:</strong> {t(`${eventReport.data.visitReason}`)}
                </p>
              )}
              {eventReport.data.arrangeVisit ? (
                <p className='event-report__data-row'>
                  <CheckOutlined /> {t('arrangeVisit')}
                </p>
              ) : (
                <p className='event-report__data-row'>
                  <CloseOutlined /> {t('arrangeVisit')}
                </p>
              )}
              {eventReport.data.followUp ? (
                <p className='event-report__data-row'>
                  <CheckOutlined /> {t('followUp') + ': ' + t(eventReport.data.followUp)}
                  {eventReport.data.followUpOther && (
                    <span> ({eventReport.data.followUpOther})</span>
                  )}
                </p>
              ) : (
                <p className='event-report__data-row'>
                  <CloseOutlined /> {t('followUp')}
                </p>
              )}
              {eventReport.data.leadConsent ? (
                <p className='event-report__data-row event-report__consent-positive'>
                  <CheckOutlined /> {t('privacyAgreement')}{' '}
                  {eventReport.data.leadConsentUpdated
                    ? ' (' +
                      eventReport.data.leadConsentUpdated?.split('T')[0] +
                      ' ' +
                      eventReport.data.leadConsentUpdated?.split('T')[1].split('Z')[0] +
                      ')'
                    : null}
                </p>
              ) : (
                <p className='event-report__data-row event-report__consent-negative'>
                  <CloseOutlined /> {t('privacyAgreement')}{' '}
                  {eventReport.data.leadConsentUpdated
                    ? ' (' +
                      eventReport.data.leadConsentUpdated?.split('T')[0] +
                      ' ' +
                      eventReport.data.leadConsentUpdated?.split('T')[1].split('Z')[0] +
                      ')'
                    : null}
                </p>
              )}
              {eventReport.data.note && (
                <>
                  <p className='event-report__data-row'>
                    <strong>{t('note')}:</strong>
                  </p>
                  <p className='event-report__data-row'>{eventReport.data.note}</p>
                </>
              )}
            </div>
            {eventReport.data.catalogueType && (
              <p className='event-report__data-row'>
                <strong>{t('catalogueType')}:</strong> {t(`${eventReport.data.catalogueType}`)}
              </p>
            )}
            <Collapse>
              {interests.map((interest) => {
                return (
                  <Panel
                    header={eventReport.data[interest] ? t(interest) : '🚫 ' + t(interest)}
                    key={interest.id}>
                    <div className='event-report__interest'>
                      {eventReport.data[interest] && eventReport.data[interest].interest && (
                        <h2 style={{ fontWeight: '700' }} className='event-report__interest-name'>
                          {t(interest)} <CheckOutlined />
                        </h2>
                      )}
                    </div>
                    <ul className='event-report__interest-catalogue-data'>
                      {eventReport.data[interest] && eventReport.data[interest].catalogue ? (
                        <li>
                          {selectedCatalogues &&
                            selectedCatalogues.data &&
                            selectedCatalogues.data.items &&
                            selectedCatalogues.data.items.map((selectedCatalogue) => {
                              console.log('selectedCatalogue', selectedCatalogue);
                              return (
                                <ul className='event-report__interest-catalogue-data-list'>
                                  {selectedCatalogue.catalogue.name && (
                                    <h3 style={{ marginTop: '15px' }}>
                                      {selectedCatalogue.catalogue.name}
                                    </h3>
                                  )}
                                  {selectedCatalogue.dePrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('dePrint')} (
                                      {selectedCatalogue.dePrintQty && selectedCatalogue.dePrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.dePdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('dePdf')} (
                                      {selectedCatalogue.dePdfQty && selectedCatalogue.dePdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.enPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('enPrint')} (
                                      {selectedCatalogue.enPrintQty && selectedCatalogue.enPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.enPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('enPdf')} (
                                      {selectedCatalogue.enPdfQty && selectedCatalogue.enPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.frPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('frPrint')} (
                                      {selectedCatalogue.frPrintQty && selectedCatalogue.frPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.frPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('frPdf')} (
                                      {selectedCatalogue.frPdfQty && selectedCatalogue.frPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.nlPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('nlPrint')} (
                                      {selectedCatalogue.nlPrintQty && selectedCatalogue.nlPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.nlPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('nlPdf')} (
                                      {selectedCatalogue.nlPdfQty && selectedCatalogue.nlPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.dEnPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('dEnPrint')} (
                                      {selectedCatalogue.dEnPrintQty &&
                                        selectedCatalogue.dEnPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.dEnPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('dEnPdf')} (
                                      {selectedCatalogue.dEnPdfQty && selectedCatalogue.dEnPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.esPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('esPrint')} (
                                      {selectedCatalogue.esPrintQty && selectedCatalogue.esPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.esPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('esPdf')} (
                                      {selectedCatalogue.esPdfQty && selectedCatalogue.esPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.plPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('plPrint')} (
                                      {selectedCatalogue.plPrintQty && selectedCatalogue.plPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.plPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('plPdf')} (
                                      {selectedCatalogue.plPdfQty && selectedCatalogue.plPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.sePrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('sePrint')} (
                                      {selectedCatalogue.sePrintQty && selectedCatalogue.sePrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.sePdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('sePdf')} (
                                      {selectedCatalogue.sePdfQty && selectedCatalogue.sePdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.dkPrint && (
                                    <li>
                                      <ArrowRightOutlined /> {t('dkPrint')} (
                                      {selectedCatalogue.dkPrintQty && selectedCatalogue.dkPrintQty}
                                      ) <CheckOutlined />
                                    </li>
                                  )}
                                  {selectedCatalogue.dkPdf && (
                                    <li>
                                      <ArrowRightOutlined /> {t('dkPdf')} (
                                      {selectedCatalogue.dkPdfQty && selectedCatalogue.dkPdfQty}){' '}
                                      <CheckOutlined />
                                    </li>
                                  )}
                                </ul>
                              );
                            })}
                        </li>
                      ) : null}
                    </ul>
                  </Panel>
                );
              })}
            </Collapse>
          </>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(ViewEventReport);
