import React from 'react';
import { Form, Input, Button, DatePicker, notification } from 'antd';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';

const formInit = {
  name: undefined,
  location: undefined,
  startDate: undefined,
  endDate: undefined,
};

const EventForm = ({ isNew, data, createHandler, updateHandler, t }) => {
  const [form] = Form.useForm();
  if (data) {
    data.startDate = moment(data.startDate);
    data.endDate = moment(data.endDate);
  }
  let initialValues = { ...formInit, ...data, };

  const onFinish = async (values, isNew) => {
    try {
      if (moment(values.endDate).isBefore(moment(values.startDate), 'days')) {
        throw new Error('End date is before start date.');
      }
      if (isNew) {
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } catch (error) {
      notification.error({
        message: error.message,
        placement: 'bottomRight'
      });
    }

  };


  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Form.Item name='name' label={t('eventName')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='location' label={t('eventLocation')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='startDate' label={t('startDate')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <DatePicker placeholder={t('selectDate')} />
          </Form.Item>
          <Form.Item name='endDate' label={t('endDate')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <DatePicker placeholder={t('selectDate')} />
          </Form.Item>
          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? t('addEvent') : t('save')}
            </Button>
          </div>
        </Form>

      </div>
    </div>
  );
};

export default withNamespaces()(EventForm);