import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/ProductCategoryTable';
import { SERVER_URL } from '../../config';
import EditProductCategory from './EditProductCategory';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'createdAt', 'updatedAt', 'startDate', 'endDate'];

const ProductCategory = ({ t }) => {
  const currentuser = useContext(UserContext);
  const [showModal, setShowModal] = useState();
  const [productCategories, fetchProductCategories] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchProductCategories(`${SERVER_URL}/product-category`, []);
  }, [fetchProductCategories]);
  console.log('productCategories: ', productCategories);

  let columnKeys;
  if (productCategories.data && productCategories.data.items && productCategories.data.items.length > 0) {
    const keys = Object.keys(productCategories.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = productCategories.data.items;
  if (productCategories.data && productCategories.data.items && productCategories.data.items.length > 0) {
    tableData = productCategories.data.items.map((item) => {
      // item.dePrint = String(item.dePrint);
      // item.dePdf = String(item.dePdf);
      // item.enPrint = String(item.enPrint);
      // item.enPdf = String(item.enPdf);
      // item.frPrint = String(item.frPrint);
      // item.frPdf = String(item.frPdf);
      // item.startDate = moment(item.startDate).format('MM/DD/YYYY');
      // item.endDate = moment(item.endDate).format('MM/DD/YYYY');
      return item;
    });
  }

  return (
    <div className='table product-categories-table'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => setShowModal(!showModal)} type='primary'>
          {t('addCategory')}
        </Button>
      </div>
      {!productCategories.isLoading &&
        productCategories.data &&
        productCategories.data.items &&
        productCategories.data.items.length > 0 && (
          <div style={{ textAlign: 'center' }}>
            {productCategories.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
            {!productCategories.isLoading &&
              productCategories.data &&
              productCategories.data.items &&
              productCategories.data.items.length > 0 && (
                <Table
                  data={tableData}
                  columnKeys={columnKeys}
                  title='Product category'
                  editPath='/admin/edit-product-category/'
                />
              )}
          </div>
        )}
      <EditProductCategory showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default withNamespaces()(ProductCategory);
