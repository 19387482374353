import { Card, Checkbox, Divider, Form, Input, Radio, Space, Row, Col, Button } from 'antd';
import React, { useState } from 'react';
import EmbedImage from '../base/EmbedImage';
import UploadSketch from '../base/UploadSketch';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

const MezzanineForm = ({ data, files, setFiles, t }) => {
  const [surface, setSurface] = useState(data && data.surface);
  const [floorCapacity, setFloorCapacity] = useState(data && data.floorCapacity);
  const [xBracing, setXBracing] = useState(data && data.xBracing);
  const [meshSize, setMeshSize] = useState(data && data.meshSizeMezzanine);
  const [palletTransfer, setPalletTransfer] = useState(data && data.palletTransferMezzanine);
  const [stepWidthOne, setStepWidthOne] = useState(data && data.stepWidthMezzanineOne);
  const [aisleFlooring, setAisleFlooring] = useState(data && data.mezzanineAisleFlooring);
  const [extra, setExtra] = useState(data && data.mezzanineExtra);

  const [isBothLayouts, setIsBothLayouts] = useState(false);

  return (
    <div>
      <h3 id='meta-steel-construction'>{t('metaMezzanineTitle')}</h3>
      <Divider className="pbbReportDivider" />
      {/* <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title="Layout:">
            <Form.Item 
              name='mezzanineLayout'
              rules={[{ required: true, message: t('validationAlert') }]}
            >
              <Checkbox.Group 
                className='inline-flex-around'
                options={[
                  { label: t('singleSided'), value: 'singleSided' },
                  { label: t('doubleSided'), value: 'doubleSided' },
                ]}
                onChange = {(value) => {
                  value.length === 2 ? setIsBothLayouts(true) : setIsBothLayouts(false)
                }}
              />
            </Form.Item>
            <Form.Item
              name='layoutInfoMezzanine'
              className='m-block-10'
              rules={[
                {
                  required: isBothLayouts,
                  message: t('validationAlert'),
                },
              ]}
            >
              <Input placeholder={`${t('metaLayoutInfo')}:`} />
            </Form.Item>
            <p className='pbbText' style={{ paddingTop: '10px', paddingLeft: '10px' }}>
              {t('detailsAsSketch')}
            </p>
          </Card>
        </Col>
      </Row> */}

      <div style={{ textAlign: 'left' }}>
        <img width="475" src="/mezzanine2.png" />
      </div>
      <div className='sectionTitle'>
        <h4 className="pbb-section-title">{t('metaMezzanineGeneralInformation')}</h4>
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzanineDesign')}>
            <Form.Item name='design'>
              <Radio.Group>
                <Space direction="vertical">
                  <Radio className="pbbRadioOption" value='Hot-rolled'>{t('metaMezzanineHotRolled')}</Radio>
                  <Radio className="pbbRadioOption" value='Cold-rolled'>{t('metaMezzanineColdRolled')}</Radio>
                  <Radio className="pbbRadioOption" value='Hybrid'>{t('metaMezzanineHybrid')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzanineSurface')}>
            <Form.Item name='surface' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group onChange={(event) => setSurface(event.target.value)}>
                <Space direction="vertical">
                  <Radio className="pbbRadioOption" value='RAL5010GentianBlue'>{t('RAL5010GentianBlue')}</Radio>
                  <Radio className="pbbRadioOption" value='RAL7035lightgrey'>{t('RAL7035lightgrey')}</Radio>
                  <Radio className="pbbRadioOption" value='Other'>{t('metaMezzanineOther')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {surface === 'Other' && <Form.Item name='otherSurface' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Input placeholder={t('metaMezzanineOther')} />
            </Form.Item>}
          </Card>
        </Col>
      </Row>


      <div className='sectionTitle'>
        <h4 className="pbb-section-title">{t('metaMezzaninePlatformDimensions')}</h4>
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='lengthA' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineLengthA')}:`}
          >
            <Input type='number' placeholder={t('metaMezzanineLengthA')} suffix='mm' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='widthB' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineLengthB')}:`}
          >
            <Input type='number' placeholder={t('metaMezzanineLengthB')} suffix='mm' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='supportGridA' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineSupportGridA')}:`}
          >
            <Input type='number' placeholder={t('metaMezzanineSupportGridA')} suffix='mm' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='supportGridB' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineSupportGridB')}:`}
          >
            <Input type='number' placeholder={t('metaMezzanineSupportGridB')} suffix='mm' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={8}>
          <Form.Item name='railings' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineRailingsApproxMeters')}`}
          >
            <Input type='number' placeholder={t('metaMezzanineRailingsApproxMeters')} suffix='m' />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name='topEdgeCovering' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineTopEdgeCovering')}`}
          >
            <Input type='number' placeholder={t('metaMezzanineTopEdgeCovering')} suffix='mm' />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name='bottomEdgeConstruction' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineBottomEdgeConstruction')}`}
          >
            <Input type='number' placeholder={t('metaMezzanineBottomEdgeConstruction')} suffix='mm' />
          </Form.Item>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className="pbb-section-title">{t('metaMezzanineStructuralInformation')}</h4>
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name='maxLoad' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineMaxLoad')}`}
          >
            <Input type='number' placeholder={t('metaMezzanineMaxLoad')} suffix='kg/㎡' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name='fieldLoad' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}
            label={`${t('metaMezzanineFieldLoadPerShelf')}`}
          >
            <Input type='number' placeholder={t('metaMezzanineFieldLoadPerShelf')} suffix='kg' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzanineCustomerInformationAboutMaxCap')}>
            <Form.Item name='floorCapacity' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group onChange={(event) => setFloorCapacity(event.target.value)}>
                <Space direction="horizontal">
                  <Radio style={{ flexDirection: "row-reverse" }} value='yes'>{t('yes')}</Radio>
                  <Radio style={{ flexDirection: "row-reverse" }} value='no'>{t('no')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {floorCapacity === 'yes' &&
              <Form.Item name='floorCapacityValue' rules={[
                {
                  required: true,
                  message: t("validationAlert")
                }
              ]}>
                <Input type='number' suffix='kN/㎡' />
              </Form.Item>
            }
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzanineXBracingPossible')}>
            <Form.Item name='xBracing' style={{ marginBottom: '15px' }} rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group onChange={(event) => setXBracing(event.target.value)}>
                <Space direction="vertical">
                  <Radio className="pbbRadioOption" value='yes'>{t('metaMezzanineYesFillInSketch')}</Radio>
                  <Radio className="pbbRadioOption" value='no'>{t('no')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {xBracing === 'yes' &&
              <UploadSketch
                data={data}
                files={files}
                setFiles={setFiles}
                name='mezzanine'
              />}
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        {/* <h4 className="pbb-section-title">{t('metaMezzaninePlatformCovering')}</h4> */}
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Card className="pbbCard" title={t('metaMezzanineAisleFlooring')}>
        <Form.Item name='mezzanineAisleFlooring' rules={[
          {
            required: true,
            message: t("validationAlert")
          }
        ]}>
          <Checkbox.Group defaultValue={aisleFlooring} onChange={e => setAisleFlooring(e)}>
            <Space direction='horizontal'>
              <Checkbox value='chipboard' className='pbbCheckbox'>{t('metaMezzanineChipboard')}</Checkbox>
              <Checkbox value='grating' className='pbbCheckbox'>{t('metaMezzanineGrating')}</Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Card>
      {aisleFlooring?.includes('chipboard') &&
        <>
          <Col xs={24} md={8}>
            <Card className="pbbCard" title={t('metaMezzanineTopSide')}>
              <Form.Item name='topSideMezzanine' rules={[
                {
                  required: true,
                  message: t("validationAlert")
                }
              ]}>
                <Radio.Group>
                  <Space direction="horizontal">
                    <Radio style={{ flexDirection: "row-reverse" }} value='raw'>{t('metaMezzanineRaw')}</Radio>
                    <Radio style={{ flexDirection: "row-reverse" }} value='anti-slip'>{t('metaMezzanineAntiSlip')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card className="pbbCard" title={t('metaMezzanineBottomSide')}>
              <Form.Item name='bottomSideMezzanine' rules={[
                {
                  required: true,
                  message: t("validationAlert")
                }
              ]}>
                <Radio.Group>
                  <Space direction="horizontal">
                    <Radio style={{ flexDirection: "row-reverse" }} value='raw'>{t('metaMezzanineRaw')}</Radio>
                    <Radio style={{ flexDirection: "row-reverse" }} value='white'>{t('metaMezzanineWhite')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} md={8}>
            <Card className="pbbCard" title={t('metaMezzanineFireProtection')}>
              <Form.Item name='fireProtectionMezzanine' rules={[
                {
                  required: true,
                  message: t("validationAlert")
                }
              ]}>
                <Radio.Group>
                  <Space direction="horizontal">
                    <Radio style={{ flexDirection: "row-reverse" }} value='yes'>{t('yes')}</Radio>
                    <Radio style={{ flexDirection: "row-reverse" }} value='no'>{t('no')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Card>
          </Col>
        </>
      }
      {aisleFlooring?.includes('grating') &&
        <Col xs={24} md={8}>
          <Card className="pbbCard" title={t('metaMezzanineMeshSize')}>
            <Form.Item name='meshSizeMezzanine' style={{ marginBottom: '15px' }} rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group onChange={(event) => setMeshSize(event.target.value)}>
                <Space direction="vertical">
                  <Radio className="pbbRadioOption" value='30/30'>30/30</Radio>
                  <Radio className="pbbRadioOption" value='30/10'>30/10</Radio>
                  <Radio className="pbbRadioOption" value='other'>{t('other')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {meshSize === 'other' &&
              <Form.Item name='otherMeshSizeMezzanine'>
                <Input placeholder={t('other')} />
              </Form.Item>}
          </Card>
        </Col>
      }

      <div className='sectionTitle'>
        {/* <h4 className="pbb-section-title">{t('metaMezzaninePalletTransfer')}</h4> */}
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzaninePalletTransfer')}>
            <Form.Item name='palletTransferMezzanine' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group onChange={(event) => setPalletTransfer(event.target.value)}>
                <Space direction="horizontal">
                  <Radio className="pbbRadioOption" value='Pallet transfer with chain'>{t('metaMezzaninePalletTransferWithChain')}</Radio>
                  <Radio className="pbbRadioOption" value='Swivel Lock'>{t('metaMezzanineSwivelLock')}</Radio>
                  <Radio className="pbbRadioOption" value='Folding Door'>{t('metaMezzanineFoldingDoor')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {palletTransfer &&
              <Form.Item style={{ marginTop: '10px' }} name='palletTransferMezzanineValue' rules={[
                {
                  required: true,
                  message: t("validationAlert")
                }
              ]}
                label={`${t('quantity')}:`}
              >
                <Input
                  type='number'
                  suffix={t('pieces')}
                  // placeholder={`${palletTransfer}:`}
                  placeholder={t('quantity')}
                />
              </Form.Item>}
          </Card>
        </Col>
      </Row>

      <div className='sectionTitle'>
        <h4 className="pbb-section-title">{t('metaMezzanineExtras')}</h4>
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Form.Item name='mezzanineExtra' rules={[
        {
          required: true,
          message: t("validationAlert")
        }
      ]}>
        <Checkbox.Group defaultValue={extra} onChange={e => setExtra(e)}>
          <Space direction='horizontal'>
            <Checkbox value='lighting' className="pbbCheckboxThree">{t('metaMezzanineLighting')}</Checkbox>
            <Checkbox value='liftTruck' className="pbbCheckboxThree">{t('metaMezzanineLiftTruck')}</Checkbox>
            <Checkbox value='sprinkler' className="pbbCheckboxThree">{t('metaMezzanineSprinkler')}</Checkbox>
          </Space>
        </Checkbox.Group>
      </Form.Item>

      <div className='sectionTitle'>
        <h4 className="pbb-section-title">{t('metaMezzanineStairs')}</h4>
        <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
      </div>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item style={{ marginBottom: '16px' }} name='staicasesOne' rules={[
            {
              required: true,
              message: t("validationAlert")
            }
          ]}>
            <Input type='number' suffix={t('pieces')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Card className="pbbCard" title={t('metaMezzanineStepWidth')}>
            <Form.Item name='stepWidthMezzanineOne' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group onChange={(event) => setStepWidthOne(event.target.value)}>
                <Space direction="vertical">
                  <Radio className="pbbRadioOption" value='800 mm'>800 mm</Radio>
                  <Radio className="pbbRadioOption" value='1,000 mm'>{i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}</Radio>
                  <Radio className="pbbRadioOption" value='other'>{t('other')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {stepWidthOne === 'other' &&
              <Form.Item name='otherStepWidthMezzanineOne' rules={[
                {
                  required: true,
                  message: t("validationAlert")
                }
              ]}>
                <Input placeholder={t('other')} />
              </Form.Item>}
          </Card>
          <Divider className="pbbReportDivider" style={{ minWidth: 'unset', marginTop: "15px" }} />
        </Col>
      </Row>
      <Form.List name='stairs'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <React.Fragment
                key={field.key}
              >
                <Col xs={24} md={6}>
                  <Form.Item style={{ marginBottom: '16px' }} name={[field.name, 'pieces']} rules={[
                    {
                      required: true,
                      message: t("validationAlert")
                    }
                  ]}>
                    <Input type='number' suffix={t('pieces')} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={6}>
                  <Card className="pbbCard" title={t('stepWidth')}>
                    <Form.Item name={[field.name, 'stepWidth']} rules={[
                      {
                        required: true,
                        message: t("validationAlert")
                      }
                    ]}>
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio className="pbbRadioOption" value='800 mm'>800 mm</Radio>
                          <Radio className="pbbRadioOption" value='1,000 mm'>{i18n.language === 'German' ? '1.000 mm' : '1,000 mm'}</Radio>
                          <Radio className="pbbRadioOption" value='other'>{t('other')}</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    {field.name === 'other' &&
                      <Form.Item name={[field.name, 'other']} rules={[
                        {
                          required: true,
                          message: t("validationAlert")
                        }
                      ]}>
                        <Input placeholder={t('other')} />
                      </Form.Item>}
                  </Card>
                  <Button onClick={() => remove(field.name)}>{t('remove')}</Button>
                </Col>
              </React.Fragment>
            ))}
            {fields.length < 9 &&
              <Col xs={24} md={3}>
                <Form.Item>
                  <Button
                    type='primary'
                    onClick={() => add()}
                  >
                    {t('addMoreBtn')}
                  </Button>
                </Form.Item>
              </Col>
            }
          </>
        )}
      </Form.List>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzaninePlatform')}>
            <Form.Item name='platformMezzanine' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group>
                <Space direction="horizontal">
                  <Radio style={{ flexDirection: "row-reverse" }} value='yes'>{t('yes')}</Radio>
                  <Radio style={{ flexDirection: "row-reverse" }} value='no'>{t('no')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="pbbCard" title={t('metaMezzanineAddEmergencyStaircase')}>
            <Form.Item name='emergencyStaircaseMezzanine' rules={[
              {
                required: true,
                message: t("validationAlert")
              }
            ]}>
              <Radio.Group>
                <Space direction="horizontal">
                  <Radio style={{ flexDirection: "row-reverse" }} value='yes'>{t('yes')}</Radio>
                  <Radio style={{ flexDirection: "row-reverse" }} value='no'>{t('no')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Card>
        </Col>
      </Row>

      <EmbedImage
        data={data}
        files={files}
        setFiles={setFiles}
        name='mezzanineImage'
      />
      <Form.Item name='specialMezzanine' label={`${t('positioning/SpecialFeatures')}:`}>
        <Input.TextArea rows={3} placeholder={t('positioning/SpecialFeatures')} />
      </Form.Item>
    </div>

  );
};

export default withNamespaces()(MezzanineForm);
