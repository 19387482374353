import React from 'react';
import { Form, Input, Button, DatePicker, notification, Select, Row, Col, Checkbox } from 'antd';
import moment from 'moment';

import { withNamespaces } from 'react-i18next';

const formInit = {
  name: undefined,
  category: undefined,
  dePrint: false,
  dePdf: false,
  enPrint: false,
  enPdf: false,
  frPrint: false,
  frPdf: false,
  nlPrint: false,
  nlPdf: false,
  dEnPrint: false,
  dEnPdf: false,
  esPrint: false,
  esPdf: false,
  plPrint: false,
  plPdf: false,
  sePrint: false,
  sePdf: false,
  dkPrint: false,
  dkPdf: false,
};

const CatalogueForm = ({ isNew, data, createHandler, updateHandler, t, categories }) => {
  const [form] = Form.useForm();
  if (data) {
    data.startDate = moment(data.startDate);
    data.endDate = moment(data.endDate);
  }
  let initialValues = { ...formInit, ...data, };

  const onFinish = async (values, isNew) => {
    try {
      if (isNew) {
        createHandler(values);
      } else {
        updateHandler(values);
      }
    } catch (error) {
      notification.error({
        message: error.message,
        placement: 'bottomRight'
      });
    }

  };


  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Form.Item name='name' label={t('catalogueName')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='catalogueCategory' label={t('category')}
            rules={[
              {
                required: true,
                message: t('validationAlert'),
              },
            ]}
          >
            <Select
              placeholder={t('category')}
              getPopupContainer={trigger => trigger.parentNode}
            // onChange={(value) => setSelectedEventId(value)}
            >
              {
                categories?.map((category) =>
                (<Select.Option key={category._id} value={category._id}>{t(category.name)}
                </Select.Option>)
                )
              }
            </Select>
          </Form.Item>
          <Row gutter={16}>
            <Col>
              <Form.Item name='dePrint' valuePropName='checked'>
                <Checkbox>DE Print</Checkbox>
              </Form.Item>
              <Form.Item name='enPrint' valuePropName='checked'>
                <Checkbox>EN Print</Checkbox>
              </Form.Item>
              <Form.Item name='frPrint' valuePropName='checked'>
                <Checkbox>FR Print</Checkbox>
              </Form.Item>
              <Form.Item name='nlPrint' valuePropName='checked'>
                <Checkbox>NL Print</Checkbox>
              </Form.Item>
              <Form.Item name='dEnPrint' valuePropName='checked'>
                <Checkbox>D/EN Print</Checkbox>
              </Form.Item>
              <Form.Item name='esPrint' valuePropName='checked'>
                <Checkbox>ES Print</Checkbox>
              </Form.Item>
              <Form.Item name='plPrint' valuePropName='checked'>
                <Checkbox>PL Print</Checkbox>
              </Form.Item>
              <Form.Item name='sePrint' valuePropName='checked'>
                <Checkbox>SE Print</Checkbox>
              </Form.Item>
              <Form.Item name='dkPrint' valuePropName='checked'>
                <Checkbox>DK Print</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name='dePdf' valuePropName='checked'>
                <Checkbox>DE PDF</Checkbox>
              </Form.Item>
              <Form.Item name='enPdf' valuePropName='checked'>
                <Checkbox>EN PDF</Checkbox>
              </Form.Item>
              <Form.Item name='frPdf' valuePropName='checked'>
                <Checkbox>FR PDF</Checkbox>
              </Form.Item>
              <Form.Item name='nlPdf' valuePropName='checked'>
                <Checkbox>NL PDF</Checkbox>
              </Form.Item>
              <Form.Item name='dEnPdf' valuePropName='checked'>
                <Checkbox>D/EN PDF</Checkbox>
              </Form.Item>
              <Form.Item name='esPdf' valuePropName='checked'>
                <Checkbox>ES PDF</Checkbox>
              </Form.Item>
              <Form.Item name='plPdf' valuePropName='checked'>
                <Checkbox>PL PDF</Checkbox>
              </Form.Item>
              <Form.Item name='sePdf' valuePropName='checked'>
                <Checkbox>SE PDF</Checkbox>
              </Form.Item>
              <Form.Item name='dkPdf' valuePropName='checked'>
                <Checkbox>DK PDF</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? t('addCatalogue') : t('save')}
            </Button>
          </div>
        </Form>

      </div>
    </div >
  );
};

export default withNamespaces()(CatalogueForm);